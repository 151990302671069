import classnames from 'classnames';
import React from 'react';
import CardSectionTitle from '../title/cardSectionTitle';
import styles from './index.module.scss';

interface Props extends React.HTMLProps<HTMLDivElement> {
  label?: string;
  children: React.ReactNode;
}

function CardSection({ className, label, children, ...rest }: Props) {
  return (
    <div {...rest} className={classnames(styles.section, className)}>
      {label && (
        <div className={styles.titleContainer}>
          <CardSectionTitle className={styles.title} label={label} />
        </div>
      )}
      {children}
    </div>
  );
}

export default CardSection;
