import moment from 'moment';
import { i18n } from 'translations';

const getFormattedStartDate = (startDate: string | null, user?: boolean) => {
  if (!startDate) {
    return user
      ? i18n.t('user.profile.preferences.startDate', {
          startDate: i18n.t('user.profile.preferences.unknow'),
        })
      : '';
  }

  if (moment().isAfter(moment(startDate))) {
    return user
      ? i18n.t('user.profile.preferences.startNow')
      : i18n.t('mission.search.startNow');
  }

  return i18n.t(
    `${user ? 'user.profile.preferences' : 'mission.search'}.startDate`,
    {
      startDate: moment(startDate).format('DD/MM/YYYY'),
    },
  );
};

export default getFormattedStartDate;
