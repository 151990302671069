import gql from 'graphql-tag';

export interface Certification {
  id: string;
  certification: string;
  userId: string;
}

export const CertificationFragment = gql`
  fragment Certification on certification {
    id
    certification
    userId
  }
`;

export interface PublicCertification {
  id: string;
  certification: string;
}

export const PublicCertificationFragment = gql`
  fragment PublicCertification on certification {
    id
    certification
  }
`;
