import gql from 'graphql-tag';

export interface Tag {
  id: string;
  name: string;
}

export const TagFragment = gql`
  fragment Tag on tag {
    id
    name
  }
`;
