import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  HourglassOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Empty, Tabs, Typography } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { TabPaneProps } from 'antd/lib/tabs';
import getFormattedStartDate from 'business/service/getFormattedDate';
import useUserData from 'business/user/provider/user';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MissionPreview } from 'technical/graphql/fragments';
import MissionCard from 'ui/custom/missionCard';
import Loader from 'ui/loader';
import config from 'config';
import { getLocation } from 'business/mission/pages/details/service';
import { MUTATION_CANCEL_APPLICATION } from '../services/query';
import styles from './index.module.scss';

const { TabPane } = Tabs;
const { Text } = Typography;

interface Mission extends MissionPreview {
  isFromStaff?: boolean | null;
}

interface Props extends TabPaneProps {
  missions?: Mission[];
  sectionName:
    | 'activeMissions'
    | 'applications'
    | 'finishedMissions'
    | 'selections';
  reloadData?: () => void;
}

export default function MissionTabSection({
  missions,
  sectionName,
  reloadData = () => {},
  ...props
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const { user } = useUserData();
  const userId = user?.id;

  const today = useMemo(() => {
    return moment().toISOString();
  }, []);

  const cancelApplicationDefaultValues = {
    variables: {
      userId,
      today,
      missionId: '',
    },
  };

  const [cancelApplication] = useMutation(MUTATION_CANCEL_APPLICATION, {
    ...cancelApplicationDefaultValues,
  });

  const cancelApplicationProps: PopconfirmProps = {
    children: t('dashboard.cancelApplication.content'),
    okText: t('dashboard.cancelApplication.okText'),
    cancelText: t('dashboard.cancelApplication.cancelText'),
    title: t('dashboard.cancelApplication.popInTitle'),
    className: styles.cancelApplication,
  };

  const goToMission = (id: string) => {
    history.push(`/mission/${id}`);
  };

  if (!missions) {
    return <Loader />;
  }

  if (!missions.length) {
    return (
      <TabPane {...props}>
        <Text className={styles.sectionSubtitle}>
          {t(`dashboard.subtitle.${sectionName}`)}
        </Text>

        <Empty
          className={styles.noMissionsContainer}
          description={t('dashboard.noMissions')}
        />
      </TabPane>
    );
  }

  return (
    <TabPane {...props} className={styles.missionCardsContainer}>
      <Text className={styles.sectionSubtitle}>
        {t(`dashboard.subtitle.${sectionName}`)}
      </Text>

      {missions.map(mission => {
        const {
          id,
          name,
          portraitPicture,
          activityArea,
          typology,
          address,
          duration,
          durationType,
          isFulltime,
          shortDescription,
          tags,
          startDate,
          isRemote,
          isFromStaff,
        } = mission;

        const formattedTags = tags.map(tagItem => ({
          label: tagItem.tag.name,
        }));

        return (
          <MissionCard
            withWatermark={Boolean(isFromStaff)}
            key={id}
            className={styles.missionCard}
            cover={
              portraitPicture ? (
                <img
                  alt={name}
                  src={`${config.gcp.bucketBaseUrl}/${portraitPicture}`}
                />
              ) : (
                undefined
              )
            }
            title={name}
            subtitle={`${typology || ''} - ${activityArea || ''}`}
            tags={formattedTags}
            metadatas={[
              {
                label: getLocation(address, isRemote),
                icon: <EnvironmentOutlined />,
              },
              {
                label: `${duration} ${t(`mission.search.${durationType}`, {
                  count: duration,
                })}`,
                icon: <ClockCircleOutlined />,
              },
              {
                label: isFulltime
                  ? t('mission.search.fullTime')
                  : t('mission.search.partTime'),
                icon: <HourglassOutlined />,
              },
              {
                label: getFormattedStartDate(startDate),
                icon: <CalendarOutlined />,
              },
            ]}
            description={shortDescription}
            buttonProps={{
              children: t('mission.search.seeOffer'),
              type: 'primary',
              onClick: () => {
                goToMission(id);
              },
            }}
            cancelButtonProps={
              sectionName === 'applications'
                ? {
                    ...cancelApplicationProps,
                    onConfirm: () =>
                      cancelApplication({
                        variables: {
                          missionId: id,
                          userId,
                          today,
                        },
                      }).then(() => {
                        reloadData();
                      }),
                  }
                : undefined
            }
          />
        );
      })}
    </TabPane>
  );
}
