enum MainRoutes {
  App = '/',
  SignUp = '/signup',
  LoginCallback = '/login/callback',
  LoginVerification = '/login/verification',

  Enrolment = '/enrolment',

  Profile = '/my-profile',
  MissionDashboard = '/dashboard',

  SearchMission = '/search',
  Mission = '/mission/:missionId',
  MissionConfirmed = '/mission-confirmed',

  SponsorStartuper = '/sponsoring',
  Blog = '/blog',

  PublicUserProfle = '/public/profile/:userId',
}

export default MainRoutes;
