import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  HourglassOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { MUTATION_CANDITATE_TO_MISSION } from 'business/mission/services/query';
import getFormattedStartDate from 'business/service/getFormattedDate';
import useUserData from 'business/user/provider/user';
import MainRoutes from 'config/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { logEvent } from 'technical/analytics';
import { MissionApplication } from 'technical/graphql/fragments/missionApplication';
import logger from 'technical/logger';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import MissionCard from 'ui/custom/missionCard';
import { Tag } from 'ui/tagRow';
import styles from './index.module.scss';
import { getLocation } from './service';
import SponsorCard from './sponsorCard';

const { Text } = Typography;

interface Props {
  id: string;
  name: string;
  address: string;
  formattedTags: Tag[];
  activityArea: string;
  typology: string;
  duration: number;
  durationType: string;
  isFulltime: boolean;
  description: string;
  missionApplications: MissionApplication[];
  startDate: string;
  isRemote: boolean;
}

export default function MissionLayout({
  id,
  name,
  address,
  formattedTags,
  activityArea,
  typology,
  duration,
  durationType,
  isFulltime,
  description,
  missionApplications,
  startDate,
  isRemote,
}: Props) {
  const { t } = useTranslation();
  const { user } = useUserData();
  const history = useHistory();

  const alreadyCandidate = !!missionApplications.length;

  const [candidateToMission, { error: candidateError }] = useMutation(
    MUTATION_CANDITATE_TO_MISSION,
  );

  const submitApplication = () => {
    candidateToMission({
      variables: {
        userId: user?.id,
        missionId: id,
      },
    })
      .then(() => {
        history.push(MainRoutes.MissionConfirmed);
      })
      .catch(err => {
        logger.error(err);
      });
  };

  if (candidateError) {
    message.error(t('mission.details.applicationError'));
  }

  const candidateBtnProps: ButtonProps = {
    children: t(
      `mission.details.${alreadyCandidate ? 'alreadyCandidate' : 'candidate'}`,
    ),
    type: 'primary',
    onClick: () => {
      submitApplication();
      logEvent({
        category: 'mission',
        action: 'application',
        label: name,
      });
    },
    disabled: alreadyCandidate,
  };

  const returnLink = alreadyCandidate
    ? MainRoutes.MissionDashboard
    : MainRoutes.SearchMission;

  const returnLabel = alreadyCandidate
    ? t('mission.details.returnToDashboard')
    : t('mission.details.returnToSearch');

  return (
    <>
      <MissionCard
        variant="secondary"
        className={styles.recapContainer}
        title={name}
        subtitle={`${typology || ''} - ${activityArea || ''}`}
        tags={formattedTags}
        metadatas={[
          {
            label: getLocation(address, isRemote),
            icon: <EnvironmentOutlined />,
          },
          {
            label: `${duration} ${t(`mission.search.${durationType}`, {
              count: duration,
            })}`,
            icon: <ClockCircleOutlined />,
          },
          {
            label: isFulltime
              ? t('mission.search.fullTime')
              : t('mission.search.partTime'),
            icon: <HourglassOutlined />,
          },
          {
            label: getFormattedStartDate(startDate),
            icon: <CalendarOutlined />,
          },
        ]}
        buttonProps={candidateBtnProps}
        returnLink={returnLink}
        returnLabel={returnLabel}
      />
      <CardSection
        className={styles.descriptionContainer}
        label={t('mission.details.description')}
      >
        <Text>{description}</Text>
      </CardSection>
      <Button className={styles.candidateBtn} {...candidateBtnProps} />
      <SponsorCard className={styles.sponsorCard} userId={user?.id} />
    </>
  );
}
