import { Typography } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import classnames from 'classnames';
import React from 'react';
import Button from 'ui/button';
import Flex from 'ui/flex';
import styles from './index.module.scss';

const { Title, Text } = Typography;

interface Props {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  image?: React.ReactNode;
  actionLinkProps?: ButtonProps;
  actionButtonProps?: ButtonProps;
  className?: string;
  children?: React.ReactNode;
  callToAction?: { link: string; children: React.ReactNode };
}

export default function DisplayCard({
  title,
  subtitle,
  actionLinkProps,
  image,
  actionButtonProps,
  className,
  children,
  callToAction,
}: Props) {
  return (
    <Flex
      column
      alignItems="center"
      justify="center"
      className={classnames(styles.container, className)}
    >
      {image && <div className={styles.image}>{image}</div>}
      {title && (
        <div className={styles.textContainer}>
          <Title level={2} className={styles.title}>
            {title}
          </Title>
          <Text className={styles.subtitle}>{subtitle}</Text>
        </div>
      )}
      {children}
      {(actionButtonProps || actionLinkProps) && (
        <Flex column className={styles.buttonContainer}>
          {actionButtonProps && (
            <Button className={styles.actionButton} {...actionButtonProps} />
          )}
          {actionLinkProps && <Button {...actionLinkProps} />}
          {callToAction && (
            <a
              href={callToAction.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="ghost">{callToAction.children}</Button>
            </a>
          )}
        </Flex>
      )}
    </Flex>
  );
}
