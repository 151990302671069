import gql from 'graphql-tag';
import { MissionPreview, MissionPreviewFragment } from './mission';

export interface MissionApplication {
  id: string;
  userId: string;
  missionId: string;
  candidateDate: string;
  selectionDate: string;
  startDate: string;
  endDate: string;
  refusalDate: string;
  cancelDate: string;
}

export interface MissionApplicationAggregate {
  aggregate: { count: number };
  nodes: [
    {
      isFromStaff: boolean | null;
      mission: MissionPreview;
    },
  ];
}

export const MissionApplicationFragment = gql`
  fragment MissionApplication on missionApplication {
    id
    cancelDate
    candidateDate
    refusalDate
    selectionDate
  }
`;

export const MissionApplicationAggregateFragment = gql`
  fragment MissionApplicationAggregate on missionApplication_aggregate {
    aggregate {
      count
    }
    nodes {
      isFromStaff
      mission {
        ...MissionPreview
      }
    }
  }
  ${MissionPreviewFragment}
`;
