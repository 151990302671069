/* eslint-disable @typescript-eslint/camelcase */
import { ApolloClient } from 'apollo-client';
import { MUTATION_CREATE_MAILJET_USER } from 'business/mailjet/query';
import {
  getAuthId,
  getAuthResult,
  getAuthEmail,
} from 'business/user/services/authentication';
import { uuidv4Validation } from 'technical/validation/uuid';
import {
  MUTATION_CREATE_MY_USER,
  QUERY_MY_USER,
  MUTATION_UPDATE_USER_AUTHID,
} from './query';

const AUTH0_USER_METADATA_KEY = 'https://hasura.io/jwt/claimsuser_metadata';

async function fetchUser(client: ApolloClient<object>) {
  const authID = getAuthId();
  const email = getAuthEmail();

  // try to get my user
  const {
    data: {
      user: [finalUser],
    },
  } = await client.query({
    query: QUERY_MY_USER,
    variables: { id: authID, email },
    fetchPolicy: 'network-only',
  });

  return finalUser;
}

async function createUser(client: ApolloClient<object>) {
  const authResult = getAuthResult();
  if (!authResult) {
    throw new Error('missing authResult to create new User');
  }
  const { email, sub } = authResult.idTokenPayload;

  const {
    name,
    family_name,
    phone_number,
    sponsor_user_id,
  } = authResult.idTokenPayload[AUTH0_USER_METADATA_KEY];

  let sponsorUserId = null;

  // If the user don't have any sponsorship, sponsor_user_id is undefined
  if (sponsor_user_id && sponsor_user_id.match(uuidv4Validation)) {
    sponsorUserId = sponsor_user_id;
  }

  // Create user in db
  const { data } = await client.mutate({
    mutation: MUTATION_CREATE_MY_USER,
    variables: {
      email,
      id: sub,
      firstName: name,
      lastName: family_name,
      phone: phone_number,
      ...(sponsorUserId && { sponsorUserId }),
    },
  });

  // Create Mailjet user
  await client.mutate({
    mutation: MUTATION_CREATE_MAILJET_USER,
    variables: {
      email,
      firstName: name,
      lastName: family_name,
      phone: phone_number,
    },
  });

  if (!data) {
    throw new Error('no data after user creation');
  }

  const {
    insert_user: {
      returning: [freshlyCreatedMyUser],
    },
  } = data;

  return freshlyCreatedMyUser;
}

// Used to update user in order to add Auth0 id for admin created user.
// An update in `_set` is required to trigger the hasura preset who update authId
async function updateUserAuthId(
  client: ApolloClient<object>,
  id: string,
  sponsorUserIdOriginal: string | null,
) {
  const authResult = getAuthResult();
  if (!authResult) {
    throw new Error('missing authResult to create new User');
  }
  const { email } = authResult.idTokenPayload;

  const {
    name,
    family_name,
    phone_number,
    sponsor_user_id,
  } = authResult.idTokenPayload[AUTH0_USER_METADATA_KEY];

  let sponsorUserId = sponsorUserIdOriginal;
  if (sponsor_user_id.match(uuidv4Validation)) {
    sponsorUserId = sponsor_user_id;
  }

  const { data } = await client.mutate({
    mutation: MUTATION_UPDATE_USER_AUTHID,
    variables: {
      id,
      firstName: name,
      lastName: family_name,
      phone: phone_number,
      ...(sponsorUserId && {
        sponsorUserId,
      }),
    },
  });

  if (!data) {
    throw new Error('no data after user creation');
  }

  // Create Mailjet user
  await client.mutate({
    mutation: MUTATION_CREATE_MAILJET_USER,
    variables: {
      email,
      firstName: name,
      lastName: family_name,
      phone: phone_number,
    },
  });

  const {
    update_user: {
      returning: [freshlyUpdatedUser],
    },
  } = data;

  return freshlyUpdatedUser;
}

export default async function getUserAndCreateIfNeeded(
  client: ApolloClient<object>,
) {
  let user = await fetchUser(client);

  if (!user) {
    user = await createUser(client);
  } else if (!user.authId) {
    user = await updateUserAuthId(client, user.id, user.sponsorUserId);
  }

  return { ...user };
}
