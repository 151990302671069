import React from 'react';
import styles from './simpleCard.module.scss';

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => (
  <div className={styles.noMatchContainer}>{children}</div>
);
