import { Input } from 'antd';
import React from 'react';

export const copyToClipboard = (ref: React.MutableRefObject<Input | null>) => {
  if (ref.current) {
    ref.current.focus();
    document.execCommand('copy');
  }
};

export const focusElement = (ref: React.MutableRefObject<Input | null>) => {
  if (ref.current) {
    ref.current.select();
  }
};

export const notNullNorUndefined = (data: any) => {
  return data !== undefined && data !== null && data !== '';
};
