import { Input } from 'antd';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { copyToClipboard, focusElement } from 'technical/utils';
import Button from 'ui/button';
import Flex from 'ui/flex';
import styles from './index.module.scss';

interface Props {
  clipboardString: string;
}

const ClipboardInput = ({ clipboardString }: Props) => {
  const { t } = useTranslation();
  const inputRef = useRef<null | Input>(null);

  return (
    <Flex className={styles.inputContainer} column alignItems="center">
      <Input
        ref={inputRef}
        className={styles.input}
        value={clipboardString}
        onFocus={() => focusElement(inputRef)}
        readOnly
      />
      <Button type="link" onClick={() => copyToClipboard(inputRef)}>
        {t('common.copyLink')}
      </Button>
    </Flex>
  );
};

export default ClipboardInput;
