import { signUp } from 'business/user/services/authentication';
import React, { useEffect } from 'react';
import Loader from 'ui/loader';

const urlParams = new URLSearchParams(window.location.search);
const sponsorUserId = urlParams.get('sponsorUserId') || undefined;

function SignUpPage() {
  useEffect(() => {
    signUp(sponsorUserId);
  }, []);

  return <Loader />;
}

export default SignUpPage;
