import gql from 'graphql-tag';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  picture: string;
  email: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  phone: string;
  description: string | null;
  linkedInLink: string | null;
  preferredStartDate: string | null;
  preferredAdr: string | null;
  preferredIsFulltime: boolean | null;
  preferredIsParttime: boolean | null;
  preferredIsRemote: boolean | null;
  preferredLocation: {
    coordinates: [number, number];
  } | null;
  preferredAddress: string | null;
  isValidated: boolean | null;
}

export const UserFragment = gql`
  fragment User on user {
    id
    firstName
    lastName
    picture
    email
    isActive
    createdAt
    updatedAt
    phone
    description
    linkedInLink
    preferredStartDate
    preferredAdr
    preferredIsFulltime
    preferredIsParttime
    preferredAddress
    preferredLocation
    preferredIsRemote
    isValidated
  }
`;
export interface PublicUser {
  id: string;
  firstName: string;
  picture: string;
  preferredStartDate: string | null;
  preferredAdr: string | null;
  preferredIsFulltime: boolean | null;
  preferredIsParttime: boolean | null;
  preferredAddress: string | null;
  preferredIsRemote: boolean | null;
  isValidated: boolean | null;
}

export const PublicUserFragment = gql`
  fragment PublicUser on user {
    id
    firstName
    picture
    preferredStartDate
    preferredAdr
    preferredIsFulltime
    preferredIsParttime
    preferredAddress
    preferredIsRemote
  }
`;
