// eslint-disable-next-line import/prefer-default-export
export const getLocation = (address: string | null, isRemote: boolean) => {
  let location = '';

  if (address && isRemote) {
    location = `${address} & Remote`;
  } else if (address && !isRemote) {
    location = address;
  } else if (!address && isRemote) {
    location = 'Remote';
  }

  return location;
};
