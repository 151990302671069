import React, { CSSProperties } from 'react';
import colors from '../../config/colors';

export enum SeparatorType {
  title = 'title',
  keyNumbers = 'keyNumbers',
}

interface Props {
  style?: CSSProperties;
  type?: SeparatorType;
}

function Separator({ style, type }: Props) {
  let separatorStyle = {};

  switch (type) {
    default:
    case SeparatorType.title:
      separatorStyle = {
        width: '115px',
        height: '4px',
        borderRadius: '4px',
        backgroundColor: colors.primaryColor,
      };
      break;
    case SeparatorType.keyNumbers:
      separatorStyle = {
        height: '130px',
        width: '1px',
        backgroundColor: colors.white,
      };
      break;
  }

  return <div style={{ ...separatorStyle, ...style }} />;
}

export default Separator;
