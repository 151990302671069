import gql from 'graphql-tag';

export interface CustomUserTag {
  id: string;
  name: string;
  level: number;
}

export const CustomUserTagFragment = gql`
  fragment CustomUserTag on customUserTag {
    id
    name
    level
  }
`;

export interface PublicCustomUserTag {
  id: string;
  name: string;
}

export const PublicCustomUserTagFragment = gql`
  fragment PublicCustomUserTag on customUserTag {
    id
    name
  }
`;
