import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Blob } from 'ui/images/blob.svg';
import styles from './index.module.scss';

interface Props {
  title?: string;
  cover?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  transparent?: boolean;
  container?: 'wide' | 'default';
}

function BaseContent({
  title,
  cover,
  children,
  className,
  transparent,
  container,
}: Props) {
  const containerStyle =
    container === 'wide'
      ? styles.wideContentContainer
      : styles.defaultContentContainer;

  return (
    <div style={{ position: 'relative' }}>
      <div className={styles.backgroundBlob}>
        <Blob />
      </div>

      {title && (
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{title}</h1>
        </div>
      )}
      {cover && <div className={styles.coverContainer}>{cover}</div>}
      <div
        className={classNames(
          { bgWhite: !transparent },
          className,
          container ? containerStyle : undefined,
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default BaseContent;
