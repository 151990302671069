import gql from 'graphql-tag';

export interface Diploma {
  id: string;
  title: string;
  place: string;
  obtentionDate: string;
  certifications: string;
  userId: string;
}

export const DiplomaFragment = gql`
  fragment Diploma on diploma {
    id
    title
    place
    obtentionDate
    certifications
    userId
  }
`;

export interface PublicDiploma {
  id: string;
  title: string;
  place: string;
  obtentionDate: string;
  certifications: string;
}

export const PublicDiplomaFragment = gql`
  fragment PublicDiploma on diploma {
    id
    title
    place
    obtentionDate
    certifications
  }
`;
