import gql from 'graphql-tag';
import { UserFragment } from 'technical/graphql/fragments';

export const QUERY_USER_PREFERENCES = gql`
  query GetUserPreference($id: uuid!) {
    user_by_pk(id: $id) {
      ...User
    }
  }
  ${UserFragment}
`;

export const MUTATION_UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences(
    $id: uuid
    $preferredStartDate: date
    $preferredAdr: Int
    $preferredIsFulltime: Boolean
    $preferredIsParttime: Boolean
    $preferredIsRemote: Boolean
    $preferredAddress: String
    $preferredLocation: geography
  ) {
    update_user(
      _set: {
        preferredStartDate: $preferredStartDate
        preferredAdr: $preferredAdr
        preferredIsFulltime: $preferredIsFulltime
        preferredIsParttime: $preferredIsParttime
        preferredIsRemote: $preferredIsRemote
        preferredAddress: $preferredAddress
        preferredLocation: $preferredLocation
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
