import { Row, Space } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { useMediaType } from 'technical/media/hooks';
import { i18n } from 'translations';
import Button from 'ui/button';
import SectionTitle from 'ui/title/cardSectionTitle';
import styles from './index.module.scss';

interface Props {
  title: string;
  buttons?: { content: string; onClick?: () => void }[];
  children: React.ReactNode;
  editProfile?: () => void;
}

const ProfileSection = ({ title, buttons, children, editProfile }: Props) => {
  const { isMobile } = useMediaType();

  return (
    <div>
      <Row justify="end">
        <Space className={styles.editSectionButtons} direction="vertical">
          {editProfile && (
            <Button
              className={classnames('uppercase', styles.button, {
                fullWidth: isMobile,
              })}
              key="editProfile"
              onClick={editProfile}
            >
              {i18n.t('user.profile.editProfile')}
            </Button>
          )}
          {buttons &&
            buttons.map(({ content, onClick }) => (
              <Button
                className={classnames('uppercase', styles.button, {
                  fullWidth: isMobile,
                })}
                key={content}
                onClick={onClick}
              >
                {content}
              </Button>
            ))}
        </Space>
      </Row>
      <SectionTitle center={false} label={title} />
      {children}
    </div>
  );
};

export default ProfileSection;
