import { useQuery } from '@apollo/react-hooks';
import { message, Tabs } from 'antd';
import BaseContent from 'business/layout/baseContent';
import ConnectedPage from 'business/layout/connectedPage';
import { useAppContext } from 'business/provider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaType } from 'technical/media/hooks';
import Loader from 'ui/loader';
import { QUERY_ALL_USER_MISSIONS } from '../services/query';
import { QueryResult } from '../services/type';
import styles from './index.module.scss';
import MissionTabSection from './tabContent';
import './tabPaneOverride.scss';

export default function MissionDashboard() {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { isMobile } = useMediaType();

  const userId = user?.id;
  const { data, loading, error, refetch } = useQuery<QueryResult>(
    QUERY_ALL_USER_MISSIONS,
    {
      variables: {
        userId,
      },
    },
  );

  if (loading || !userId) {
    return (
      <ConnectedPage>
        <BaseContent title={t('dashboard.title')} transparent>
          <Loader />
        </BaseContent>
      </ConnectedPage>
    );
  }

  if (error) {
    message.error(t('errors.query'));
  }

  return (
    <ConnectedPage>
      <BaseContent title={t('dashboard.title')}>
        <div className={styles.tabsContainer}>
          <Tabs
            size="large"
            defaultActiveKey="1"
            tabBarStyle={{
              textTransform: 'uppercase',
              letterSpacing: isMobile ? 0 : '0.25em',
              background: 'white',
            }}
            centered
          >
            <MissionTabSection
              key="1"
              tab={t('dashboard.tabs.applications', {
                count: data?.applications.aggregate.count,
              })}
              sectionName="applications"
              missions={data?.applications.nodes.map(
                ({ mission, isFromStaff }) => ({
                  ...mission,
                  isFromStaff,
                }),
              )}
              reloadData={refetch}
            />
            <MissionTabSection
              key="2"
              tab={t('dashboard.tabs.selections', {
                count: data?.selections.aggregate.count,
              })}
              sectionName="selections"
              missions={data?.selections.nodes.map(
                ({ mission, isFromStaff }) => ({
                  ...mission,
                  isFromStaff,
                }),
              )}
            />
            <MissionTabSection
              key="3"
              tab={t('dashboard.tabs.activeMissions', {
                count: data?.activeMissions.aggregate.count,
              })}
              sectionName="activeMissions"
              missions={data?.activeMissions.nodes}
            />
            <MissionTabSection
              key="4"
              tab={t('dashboard.tabs.finishedMissions', {
                count: data?.finishedMissions.aggregate.count,
              })}
              sectionName="finishedMissions"
              missions={data?.finishedMissions.nodes}
            />
          </Tabs>
        </div>
      </BaseContent>
    </ConnectedPage>
  );
}
