import config from 'config/index';
import { i18n } from 'translations';

// eslint-disable-next-line import/prefer-default-export
export const footerConfig = {
  appLogo: true,
  contacts: [
    {
      type: i18n.t('footer.email'),
      value: config.contact.email,
    },
    {
      type: i18n.t('footer.phone'),
      value: config.contact.phone,
    },
  ],
  credits: config.contact.credits,
};
