import { useAppContext } from 'business/provider';
import React from 'react';
import history from 'technical/history';
import { useMediaType } from 'technical/media/hooks';
import DesktopNavBar from './desktop';
import MobileNavBar from './mobile';

interface Props {
  isConnected?: boolean;
  displayedMenuSections?: {
    label: string;
    path: string;
  }[];
}

export default function FullNavBar(props: Props) {
  const { user } = useAppContext();
  const { isMobile } = useMediaType();

  const activeSectionPath = history.location.pathname;

  const navBarProps = {
    ...props,
    user,
    activeSectionPath,
  };

  if (isMobile) {
    return <MobileNavBar {...navBarProps} />;
  }

  return <DesktopNavBar {...navBarProps} />;
}
