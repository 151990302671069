import { InfoCircleOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  message,
  Row,
  Tooltip,
} from 'antd';
import { MUTATION_UPDATE_MAILJET_USER } from 'business/mailjet/query';
import { useAppContext } from 'business/provider';
import classnames from 'classnames';
import colors from 'config/colors';
import { TFunction } from 'i18next';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import {
  positiveNumberRule,
  requiredErrorAlert,
  requiredRule,
  dateFormat,
} from 'technical/validation/rules';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import AddressInput, { emptyGeoAddress } from 'ui/form/addressInput';
import Loader from 'ui/loader';
import commonStyles from '../index.module.scss';
import styles from './index.module.scss';
import {
  MUTATION_UPDATE_USER_PREFERENCES,
  QUERY_USER_PREFERENCES,
} from './query';
import { FormValues, QueryResults } from './type';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const layoutLeft = { offset: 3, span: 8 };
const layoutRight = { offset: 2, span: 8 };
const layoutFull = { offset: 3, span: 18 };

const fullWidth = { style: { width: '100%' } };

interface Props {
  goNextStep: () => void;
  goToPreviousStep?: () => void;
  nextButtonContent?: string | TFunction;
}

function PreferencesPage({
  goNextStep,
  goToPreviousStep,
  nextButtonContent,
}: Props) {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { isMobile } = useMediaType();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const userId = user?.id;

  const { data, loading, error: queryError } = useQuery<QueryResults>(
    QUERY_USER_PREFERENCES,
    { variables: { id: userId }, fetchPolicy: 'cache-and-network' },
  );
  const [
    updateUserPreferences,
    { error: updateUserPreferenceError },
  ] = useMutation(MUTATION_UPDATE_USER_PREFERENCES);

  const onFinish = async ({
    startDate,
    adr,
    type,
    location,
    remote,
  }: FormValues) => {
    try {
      const preferredIsFulltime = type ? type.includes('fulltime') : false;
      const preferredIsParttime = type ? type.includes('parttime') : false;

      await updateUserPreferences({
        variables: {
          preferredIsParttime,
          preferredIsFulltime,
          id: userId,
          preferredStartDate: startDate,
          preferredAdr: adr,
          preferredAddress: location?.input || null,
          preferredLocation: location
            ? {
                type: 'Point',
                coordinates: [
                  location.coordinates.latitude,
                  location.coordinates.longitude,
                ],
              }
            : null,
          preferredIsRemote: remote,
        },
      });

      let contractType = '';
      if (preferredIsFulltime) {
        contractType = 'Full time';
      }
      if (preferredIsParttime) {
        contractType = 'Part time';
      }
      if (preferredIsFulltime && preferredIsParttime) {
        contractType = 'Full time et Part time';
      }

      // Update Mailjet user
      await client.mutate({
        mutation: MUTATION_UPDATE_MAILJET_USER,
        variables: {
          contractType,
          email: user?.email,
          location: location?.input,
          adr,
          remote,
        },
      });
      goNextStep();
    } catch (err) {
      logger.error(err);
    }
  };

  const onLocationChange = () => {};

  if (loading || !userId) {
    return <Loader />;
  }

  if (queryError) {
    message.warning(t('errors.query'));
  }

  if (updateUserPreferenceError) {
    message.error(t('errors.mutation'));
  }

  const startDate = data?.user_by_pk.preferredStartDate
    ? moment(data.user_by_pk.preferredStartDate)
    : null;

  const type = [
    data?.user_by_pk.preferredIsFulltime && 'fulltime',
    data?.user_by_pk.preferredIsParttime && 'parttime',
  ].filter(Boolean);

  const location = data?.user_by_pk.preferredAddress
    ? {
        ...emptyGeoAddress,
        input: data.user_by_pk.preferredAddress,
        value: data.user_by_pk.preferredAddress,
      }
    : undefined;

  const initialValues = {
    startDate,
    adr: data?.user_by_pk.preferredAdr || null,
    type,
    location,
    remote: Boolean(data?.user_by_pk.preferredIsRemote),
  };

  return (
    <Flex className={commonStyles.container} column alignItems="center">
      <Form
        {...layout}
        className="fullWidth"
        form={form}
        onFinish={onFinish}
        onFinishFailed={requiredErrorAlert}
        initialValues={initialValues}
      >
        <Button
          type="primary"
          htmlType="submit"
          className={commonStyles.stickySubmitButton}
          style={{ marginBottom: '16px' }}
        >
          {nextButtonContent || t('enrolment.submit_button')}
        </Button>
        <Row justify="center">
          {goToPreviousStep && (
            <Button
              onClick={goToPreviousStep}
              className={commonStyles.previousButton}
              type="ghost"
              htmlType="button"
            >
              {t('enrolment.previous_button')}
            </Button>
          )}
        </Row>

        <CardSection
          className={classnames(styles.card, commonStyles.cardSection)}
          label={t('enrolment.preferences.title')}
        >
          <Row>
            <Col {...(isMobile ? fullWidth : layoutLeft)}>
              <Form.Item
                name="startDate"
                label={t('enrolment.preferences.fieldsLabel.startDate')}
                rules={[requiredRule]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder={t(
                    'enrolment.experiences.adventures.fieldsLabel.selectDate',
                  )}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col {...(isMobile ? fullWidth : layoutRight)}>
              <Form.Item
                name="adr"
                label={
                  <>
                    {t('enrolment.preferences.fieldsLabel.adr')}
                    {!isMobile && (
                      <Tooltip
                        placement="leftBottom"
                        title={
                          <Card
                            className={styles.tooltipCard}
                            title={
                              <p className={styles.tooltipTitle}>
                                {t('enrolment.preferences.tooltip.title')}
                              </p>
                            }
                          >
                            <p className={styles.tooltipContent}>
                              {
                                "À la différence des autres acteurs du marché, nous ne percevons pas de commission sur ton TJM !\nEn effet, nous considérons que l'ensemble des services réalisés pour le client (apport d'affaire, apport du talent et management de ce dernier) ne devraient pas être imputés au talent !\nL'avantage ? Toi aussi tu peux endosser un ou plusieurs de ces 3 rôles !\nÀ chaque business réalisé, nous partageons notre marge avec les membres du réseau ayant occupé l’un de ces "
                              }
                              <a
                                href={process.env.REACT_APP_F4B_ROLES_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                3 rôles.
                              </a>
                            </p>
                          </Card>
                        }
                      >
                        <InfoCircleOutlined
                          style={{
                            color: colors.primaryColor,
                            fontSize: '24px',
                            marginLeft: '10px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </>
                }
                rules={[requiredRule, positiveNumberRule]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : layoutLeft)}>
              <Form.Item
                name="type"
                label={t('enrolment.preferences.fieldsLabel.type')}
                rules={[requiredRule]}
              >
                <Checkbox.Group>
                  <Row>
                    <Checkbox value="fulltime" style={{ marginBottom: '10px' }}>
                      {t('enrolment.preferences.fulltime_checkbox_text')}
                    </Checkbox>
                  </Row>
                  <Row>
                    <Checkbox value="parttime" style={{ marginBottom: '10px' }}>
                      {t('enrolment.preferences.parttime_checkbox_text')}
                    </Checkbox>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : layoutFull)}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="location"
                      label={t('enrolment.preferences.fieldsLabel.location')}
                      style={{ marginBottom: '8px' }}
                      rules={[
                        {
                          required: !getFieldValue('remote'),
                          message: t('errors.required'),
                        },
                      ]}
                      dependencies={['remote']}
                    >
                      <AddressInput onChange={onLocationChange} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : layoutFull)}>
              <Form.Item
                name="remote"
                label={t('enrolment.preferences.fieldsLabel.remote')}
                valuePropName="checked"
              >
                <Checkbox>
                  {t('enrolment.preferences.remote_checkbox_text')}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </CardSection>
        <Row justify="center">
          <Form.Item>
            {goToPreviousStep && (
              <Button
                onClick={goToPreviousStep}
                className={commonStyles.previousButton}
                type="ghost"
                htmlType="button"
              >
                {t('enrolment.previous_button')}
              </Button>
            )}
            <Button
              className={commonStyles.submitButton}
              type="primary"
              htmlType="submit"
            >
              {nextButtonContent || t('enrolment.submit_button')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Flex>
  );
}

export default PreferencesPage;
