import { UserOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Avatar, Col, Divider, Space, Typography } from 'antd';
import { useAppContext } from 'business/provider';
import SponsorInput from 'business/sponsor/sponsorInput';
import { QUERY_GET_SPONSORED_USER } from 'business/user/services/query';
import { SponsoredUsersQueryResults } from 'business/user/services/type';
import classnames from 'classnames';
import config from 'config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'technical/analytics';
import {
  Association,
  Certification,
  CustomUserTag,
  Diploma,
  Experience,
  PublicAssociation,
  PublicCertification,
  PublicCustomUserTag,
  PublicDiploma,
  PublicExperience,
  PublicStartup,
  PublicUser,
  PublicUserLanguage,
  PublicUserTag,
  Startup,
  User,
  UserLanguage,
  UserTag,
} from 'technical/graphql/fragments';
import { useMediaType } from 'technical/media/hooks';
import { getAvatarFilePath } from 'technical/url/formatter';
import Button from 'ui/button';
import Card from 'ui/cardSection';
import Flex from 'ui/flex';
import DisplayAssociation from './displayAssociation';
import DisplayCertifications from './displayCertifications';
import DisplayCompetences from './displayCompetences';
import DisplayDiplomas from './displayDiplomas';
import DisplayExperiences from './displayExperiences';
import DisplayLanguages from './displayLanguages';
import DisplayPreferences from './displayPreferences';
import DisplayStartup from './displayStartup';
import styles from './index.module.scss';
import ProfileSection from './profileSection';
import SharingModal from './sharingModal';

const { Title } = Typography;

interface Props {
  className?: string;
  user: {
    startups: (Startup | PublicStartup)[];
    associations: (Association | PublicAssociation)[];
    diplomas: (Diploma | PublicDiploma)[];
    certifications: (Certification | PublicCertification)[];
    languages: (UserLanguage | PublicUserLanguage)[];
    tags: (UserTag | PublicUserTag)[];
    experiences: (Experience | PublicExperience)[];
    customTags: (CustomUserTag | PublicCustomUserTag)[];
  } & (User | PublicUser);
  firstName?: string;
  uploadedAvatar?: { name: string; loading: boolean };
  editProfile?: () => void;
  editPreferences?: () => void;
  editCompetences?: () => void;
  editStartup?: () => void;
  editAssociation?: () => void;
  editExperiences?: () => void;
  editDiplomasAndCertifications?: () => void;
  editLanguages?: () => void;
  isPublic?: boolean;
  displaySponsor?: boolean;
}

const fullWidth = { style: { width: '100%' } };

function ProfileContent({
  className,
  user: {
    diplomas,
    certifications,
    firstName: userFirstName,
    preferredStartDate,
    preferredAddress,
    preferredIsFulltime,
    preferredIsParttime,
    preferredIsRemote,
    tags,
    customTags,
    picture,
    startups,
    associations,
    languages,
    experiences,
  },
  firstName,
  uploadedAvatar,
  editProfile,
  editPreferences,
  editCompetences,
  editStartup,
  editAssociation,
  editExperiences,
  editDiplomasAndCertifications,
  editLanguages,
  isPublic = false,
  displaySponsor,
}: Props) {
  const { t } = useTranslation();
  const { isMobile } = useMediaType();
  const [sharingModalIsOpen, setSharingModalIsOpen] = useState(false);
  const { user } = useAppContext();

  const isValidated = Boolean(user?.isValidated);

  const { data } = useQuery<SponsoredUsersQueryResults>(
    QUERY_GET_SPONSORED_USER,
    {
      skip: !displaySponsor,
    },
  );

  const hasDiploma = diplomas.length > 0;
  const hasCertifications = certifications.length > 0;
  const canSeeDiploma = hasDiploma || !isPublic;
  const canSeeCertifications = hasCertifications || !isPublic;
  const emptySection = isPublic ? null : t('user.profile.emptySection');

  const handleShareButton = () => {
    setSharingModalIsOpen(true);
    logEvent({
      category: 'profile',
      action: 'share_profile_link',
    });
  };

  const avatarPath = `${
    config.gcp.bucketBaseUrl
  }/${getAvatarFilePath()}/${picture}`;

  return (
    <Flex className={styles.profileContainer}>
      <Card className={classnames(styles.card, className)}>
        <ProfileSection
          title={firstName || userFirstName}
          editProfile={editProfile}
          buttons={
            editPreferences && editCompetences
              ? [
                  {
                    content: t('user.profile.preferences.editButton'),
                    onClick: editPreferences,
                  },
                  {
                    content: t('user.profile.competences.editButton'),
                    onClick: editCompetences,
                  },
                ]
              : undefined
          }
        >
          <div className={styles.profileSynthesis}>
            <div className={styles.userInfo}>
              <Col {...(isMobile ? fullWidth : { span: 14 })}>
                <div className={styles.profileContent}>
                  <DisplayPreferences
                    preferredStartDate={preferredStartDate}
                    preferredAddress={preferredAddress}
                    preferredIsFulltime={preferredIsFulltime}
                    preferredIsParttime={preferredIsParttime}
                    preferredIsRemote={preferredIsRemote}
                  />
                  <DisplayCompetences
                    tags={[...tags.map(({ tag }) => tag), ...customTags]}
                  />
                </div>
              </Col>
              <Col>
                {(uploadedAvatar && !uploadedAvatar.loading) || picture ? (
                  <Avatar
                    src={avatarPath}
                    alt="avatar"
                    size={isMobile ? 100 : 300}
                    shape="square"
                  />
                ) : (
                  <Avatar
                    shape="square"
                    size={isMobile ? 100 : 300}
                    icon={<UserOutlined />}
                  />
                )}
              </Col>
            </div>
          </div>
        </ProfileSection>
        {!(isPublic && startups.length === 0) && (
          <>
            <Divider />
            <ProfileSection
              title={t('user.profile.project.title')}
              buttons={
                editStartup
                  ? [
                      {
                        content: t('user.profile.editButton'),
                        onClick: editStartup,
                      },
                    ]
                  : undefined
              }
            >
              {startups.length > 0 ? (
                <DisplayStartup startup={startups[0]} />
              ) : (
                emptySection
              )}
            </ProfileSection>
          </>
        )}
        {!(isPublic && associations.length === 0) && (
          <>
            <Divider />
            <ProfileSection
              title={t('user.profile.project.associationTitle')}
              buttons={
                editAssociation
                  ? [
                      {
                        content: t('user.profile.editButton'),
                        onClick: editAssociation,
                      },
                    ]
                  : undefined
              }
            >
              {associations[0] ? (
                <DisplayAssociation association={associations[0]} />
              ) : (
                emptySection
              )}
            </ProfileSection>
          </>
        )}
        <Divider />
        <ProfileSection
          title={t('user.profile.experiences.title')}
          buttons={
            editExperiences
              ? [
                  {
                    content: t('user.profile.editButton'),
                    onClick: editExperiences,
                  },
                ]
              : undefined
          }
        >
          <DisplayExperiences experiences={experiences} />
        </ProfileSection>
        {(canSeeDiploma || canSeeCertifications) && (
          <>
            <Divider />
            <ProfileSection
              title={t('user.profile.diplomas.title')}
              buttons={
                editDiplomasAndCertifications
                  ? [
                      {
                        content: t('user.profile.editButton'),
                        onClick: editDiplomasAndCertifications,
                      },
                    ]
                  : undefined
              }
            >
              <Space direction="vertical" size="large">
                {hasDiploma ? (
                  <DisplayDiplomas diplomas={diplomas} />
                ) : (
                  !isPublic && <p>{t('user.profile.noDiploma')}</p>
                )}
                <DisplayCertifications
                  certifications={certifications}
                  isPublic={isPublic}
                />
              </Space>
            </ProfileSection>
          </>
        )}
        {!(isPublic && languages.length === 0) && (
          <>
            <Divider />
            <ProfileSection
              title={t('user.profile.languages')}
              buttons={
                editLanguages
                  ? [
                      {
                        content: t('user.profile.editButton'),
                        onClick: editLanguages,
                      },
                    ]
                  : undefined
              }
            >
              {languages.length > 0 ? (
                <DisplayLanguages languages={languages} />
              ) : (
                emptySection
              )}
            </ProfileSection>
          </>
        )}
        {displaySponsor && (
          <>
            <Divider />
            <ProfileSection title={t('user.profile.sponsor')}>
              {t('user.profile.sponsorCount', {
                count:
                  data && data.sponsorUserCount.success
                    ? data.sponsorUserCount.count
                    : 0,
              })}
            </ProfileSection>
            <Divider />
            <Flex className={styles.sponsor} column alignItems="center">
              <Title level={4}>{t('user.profile.sponsorLink')}</Title>
              <SponsorInput />
            </Flex>
          </>
        )}
        {!isPublic && isValidated && (
          <Flex justify="center">
            <Button
              onClick={handleShareButton}
              className={styles.shareButton}
              type="primary"
            >
              {t('user.profile.sharingModal.title')}
            </Button>
            <SharingModal
              isOpen={sharingModalIsOpen}
              onCancel={() => setSharingModalIsOpen(false)}
            />
          </Flex>
        )}
      </Card>
    </Flex>
  );
}

export default ProfileContent;
