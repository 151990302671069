import { UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { User } from 'business/user/provider/user';
import { logout, login, signUp } from 'business/user/services/authentication';
import classnames from 'classnames';
import colors from 'config/colors';
import layout from 'config/layout';
import MainRoutes from 'config/routes';
import React from 'react';
import history from 'technical/history';
import AppLogo from 'ui/appLogo';
import MenuLink from 'ui/menu/link';
import Section from 'ui/navBar/components/logoSection';
import { TABLET_BREAKPOINT } from 'technical/media/config';
import styles from './index.module.scss';

const { Header } = Layout;

interface Props {
  isConnected?: boolean;
  displayedMenuSections?: {
    label: string;
    path: string;
  }[];
  activeSectionPath: string;
  user: User | undefined;
}

export default function DesktopNavBar({
  isConnected,
  displayedMenuSections,
  activeSectionPath,
  user,
}: Props) {
  return (
    <Header className={classnames(styles.header, 'elevation2')}>
      <div className={styles.headerContent}>
        <div className={styles.headerLeft}>
          <AppLogo height="60px" />

          <nav className={styles.navLinks}>
            {(displayedMenuSections || layout.header.menuSections).map(
              sectionItem => (
                <MenuLink
                  className={styles.menuLink}
                  key={sectionItem.label}
                  style={
                    activeSectionPath === sectionItem.path
                      ? { color: colors.primaryColor }
                      : undefined
                  }
                  path={sectionItem.path}
                >
                  {sectionItem.label}
                </MenuLink>
              ),
            )}
          </nav>
        </div>
        {isConnected ? (
          <Dropdown
            className={styles.headerRight}
            overlay={
              <>
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      history.push(MainRoutes.Profile);
                    }}
                  >
                    <MenuLink>Mon Profil</MenuLink>
                  </Menu.Item>
                </Menu>

                <Menu>
                  <Menu.Item onClick={logout}>
                    <MenuLink>Se déconnecter</MenuLink>
                  </Menu.Item>
                </Menu>
              </>
            }
          >
            <div className="ant-dropdown-link">
              <Section
                onClick={() => {}}
                icon={<UserOutlined style={{ fontSize: '36px' }} />}
                label={
                  user && window.innerWidth > TABLET_BREAKPOINT
                    ? `${user?.firstName}`
                    : ''
                }
                className={styles.section}
              />
            </div>
          </Dropdown>
        ) : (
          <div className={styles.headerRight}>
            <Button onClick={login}>Se connecter</Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => signUp()}
              type="primary"
            >
              S&apos;inscrire
            </Button>
          </div>
        )}
      </div>
    </Header>
  );
}
