import { CheckCircleOutlined } from '@ant-design/icons';
import ConnectedPage from 'business/layout/connectedPage';
import MainRoutes from 'config/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'technical/history';
import DisplayCard from 'ui/displayCard';
import Flex from 'ui/flex';
import styles from './index.module.scss';

export default function ApplicationConfirmationPage() {
  const { t } = useTranslation();

  const redirectTo = (route: MainRoutes) => () => {
    history.push(route);
  };

  return (
    <ConnectedPage noFooter>
      <Flex justify="center" className={styles.container}>
        <DisplayCard
          image={<CheckCircleOutlined />}
          title={t('mission.confirmation.title')}
          subtitle={t('mission.confirmation.subtitle')}
          actionButtonProps={{
            children: t('mission.confirmation.backToBrowsing'),
            type: 'primary',
            onClick: redirectTo(MainRoutes.SearchMission),
          }}
          actionLinkProps={{
            children: t('mission.confirmation.goToAccount'),
            type: 'primary',
            onClick: redirectTo(MainRoutes.MissionDashboard),
          }}
        />
      </Flex>
    </ConnectedPage>
  );
}
