import {
  CloseCircleOutlined,
  InboxOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Col, DatePicker, Form, Input, message, Row, Select } from 'antd';
import { MUTATION_UPDATE_MAILJET_USER } from 'business/mailjet/query';
import { useAppContext } from 'business/provider';
import { UserViewStatus } from 'business/user/types';
import { TFunction } from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import { capitalize } from 'technical/string/formatter';
import {
  dateFormat,
  limitedInputLength,
  limitedTextAreaLength,
  notBeforeStartDate,
  requiredErrorAlert,
  requiredRule,
  MAX_TEXT_AREA_CHAR,
} from 'technical/validation/rules';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import FileUpload, { extractNameFromUrl } from 'ui/form/fileUpload';
import Loader from 'ui/loader';
import Title from 'ui/title';
import commonStyles from '../index.module.scss';
import styles from './index.module.scss';
import {
  MUTATION_INSERT_EXPERIENCES_AND_DIPLOMAS,
  QUERY_EXPERIENCES_DIPLOMAS,
} from './query';
import { FormValues, QueryResults } from './type';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const layoutLeft = { offset: 3, span: 8 };
const layoutRight = { offset: 2, span: 8 };
const layoutFull = { offset: 3, span: 18 };
const noLabelCol = {
  wrapperCol: {
    offset: 3,
    span: 18,
  },
};

const fullWidth = { style: { width: '100%' } };

interface Props {
  goNextStep: () => void;
  nextButtonContent?: string | TFunction;
  displayUpload?: boolean;
  scrollToSection?: UserViewStatus;
}

function ExperiencesPage({
  goNextStep,
  nextButtonContent,
  displayUpload = true,
  scrollToSection,
}: Props) {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { isMobile } = useMediaType();
  const client = useApolloClient();
  const userId = user?.id;

  const { data, error: queryError, loading } = useQuery<QueryResults>(
    QUERY_EXPERIENCES_DIPLOMAS,
    {
      variables: { userId },
      fetchPolicy: 'cache-and-network',
    },
  );
  const [
    updateExperiencesDiplomasCertifications,
    { error: experiencesDiplomasMutationError },
  ] = useMutation(MUTATION_INSERT_EXPERIENCES_AND_DIPLOMAS);

  const [experiencesRef, setExperiencesRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [
    diplomasAndCertifRef,
    setDiplomasAndCertifRef,
  ] = useState<HTMLDivElement | null>(null);
  const [languagesRef, setLanguagesRef] = useState<HTMLDivElement | null>(null);
  React.useEffect(() => {
    if (scrollToSection === UserViewStatus.EXPERIENCES && experiencesRef) {
      window.scrollTo(0, experiencesRef.offsetTop);
    } else if (
      scrollToSection === UserViewStatus.DIPLOMAS_AND_CERTIF &&
      diplomasAndCertifRef
    ) {
      window.scrollTo(0, diplomasAndCertifRef.offsetTop);
    } else if (scrollToSection === UserViewStatus.LANGUAGES && languagesRef) {
      window.scrollTo(0, languagesRef.offsetTop);
    }
  }, [scrollToSection, experiencesRef, diplomasAndCertifRef, languagesRef]);

  const submit = async ({
    experiences,
    diplomas,
    certifications,
    languages,
    files,
  }: FormValues) => {
    try {
      await updateExperiencesDiplomasCertifications({
        variables: {
          experiences:
            experiences?.map(experience => ({
              ...experience,
              userId,
            })) || [],
          diplomas: diplomas?.map(diploma => ({ ...diploma, userId })) || [],
          certifications:
            certifications?.map(certification => ({
              ...certification,
              userId,
            })) || [],
          languages:
            languages?.map(language => ({ name: language, userId })) || [],
          userId,
          files:
            files?.map(({ name, size, type, url, response }) => {
              let bucketFileName: string = '';
              if (response) {
                bucketFileName = extractNameFromUrl(response.url, name);
              } else if (url) {
                bucketFileName = url;
              }
              return {
                name,
                size,
                type,
                bucketFileName,
              };
            }) || [],
        },
      });

      await client.mutate({
        mutation: MUTATION_UPDATE_MAILJET_USER,
        variables: {
          email: user?.email,
          certifications:
            certifications
              ?.map(({ certification }) => certification)
              .join(', ') || '',
          languages: languages?.join(', ') || '',
        },
      });
    } catch (err) {
      logger.error(err);
      return;
    }
    goNextStep();
  };

  if (queryError) {
    logger.warn(queryError);
    message.warning(t('errors.query'));
  }

  if (experiencesDiplomasMutationError) {
    message.error(t('errors.mutation'));
  }

  if (loading || !userId) {
    return <Loader />;
  }

  return (
    <Flex className={commonStyles.container} column alignItems="center">
      <Form
        className="fullWidth"
        onFinish={submit}
        onFinishFailed={requiredErrorAlert}
        initialValues={{
          files:
            data && data.user.files && data.user.files.length > 0
              ? data.user.files.map(
                  ({ name, size, type, bucketFileName }, id) => ({
                    uid: `${0 - id}`,
                    name,
                    type,
                    size,
                    status: 'done',
                    url: bucketFileName,
                  }),
                )
              : [],
          experiences:
            data && data.experience.length > 0
              ? data.experience.map(
                  ({ job, place, startDate, endDate, content }) => ({
                    job,
                    place,
                    startDate: moment(startDate),
                    endDate: endDate ? moment(endDate) : null,
                    content,
                  }),
                )
              : [{}],
          diplomas: data
            ? data.diploma.map(({ title, place, obtentionDate }) => ({
                title,
                place,
                obtentionDate: moment(obtentionDate),
              }))
            : [],
          certifications: data
            ? data.certification.map(({ certification }) => ({
                certification,
              }))
            : [],
          languages: data ? data.userLanguage.map(({ name }) => name) : [],
        }}
        {...layout}
      >
        <Button
          type="primary"
          htmlType="submit"
          className={commonStyles.stickySubmitButton}
        >
          {nextButtonContent || t('enrolment.submit_button')}
        </Button>
        {displayUpload && (
          <CardSection
            className={commonStyles.cardSection}
            label={t('enrolment.experiences.documents.title')}
          >
            <Form.Item {...(!isMobile && noLabelCol)} name="files">
              <FileUpload uploadFolder={userId}>
                <div className={styles.fileUpload}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    {t('enrolment.experiences.documents.uploadText')}
                  </p>
                  <p className="ant-upload-hint" />
                </div>
              </FileUpload>
            </Form.Item>
          </CardSection>
        )}
        <div ref={element => setExperiencesRef(element)}>
          <CardSection
            className={commonStyles.cardSection}
            label={t('enrolment.experiences.adventures.title')}
          >
            <div>
              <Form.List name="experiences">
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map((field, idx) => (
                      <div key={field.fieldKey}>
                        <Title className={styles.title}>
                          {t('enrolment.experiences.adventures.sectionTitle', {
                            id: idx + 1,
                          })}
                          {idx > 0 && (
                            <CloseCircleOutlined
                              className={styles.icon}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          )}
                        </Title>
                        <Row>
                          <Col {...(isMobile ? fullWidth : layoutLeft)}>
                            <Form.Item
                              name={[field.name, 'job']}
                              label={t(
                                'enrolment.experiences.adventures.fieldsLabel.job',
                              )}
                              rules={[requiredRule, limitedInputLength]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col {...(isMobile ? fullWidth : layoutRight)}>
                            <Form.Item
                              name={[field.name, 'place']}
                              label={t(
                                'enrolment.experiences.adventures.fieldsLabel.place',
                              )}
                              rules={[requiredRule, limitedInputLength]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col {...(isMobile ? fullWidth : layoutLeft)}>
                            <Form.Item
                              name={[field.name, 'startDate']}
                              label={t(
                                'enrolment.experiences.adventures.fieldsLabel.startDate',
                              )}
                              rules={[requiredRule]}
                            >
                              <DatePicker
                                className={styles.fullWidth}
                                placeholder={t(
                                  'enrolment.experiences.adventures.fieldsLabel.selectDate',
                                )}
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Col>
                          <Col {...(isMobile ? fullWidth : layoutRight)}>
                            <Form.Item
                              name={[field.name, 'endDate']}
                              label={t(
                                'enrolment.experiences.adventures.fieldsLabel.endDate',
                              )}
                              rules={[
                                notBeforeStartDate([
                                  'experiences',
                                  field.name,
                                  'startDate',
                                ]),
                              ]}
                            >
                              <DatePicker
                                className={styles.fullWidth}
                                placeholder={t(
                                  'enrolment.experiences.adventures.fieldsLabel.optionalSelect',
                                )}
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col {...(isMobile ? fullWidth : layoutFull)}>
                            <Form.Item
                              name={[field.name, 'content']}
                              label={t(
                                'enrolment.experiences.adventures.fieldsLabel.content',
                              )}
                              rules={[requiredRule, limitedTextAreaLength]}
                            >
                              <Input.TextArea
                                maxLength={MAX_TEXT_AREA_CHAR}
                                showCount
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}
                    <Row>
                      <Col {...(isMobile ? fullWidth : layoutFull)}>
                        <Form.Item>
                          <Button
                            type="primary"
                            onClick={() => {
                              add();
                            }}
                            block
                          >
                            <PlusOutlined />
                            {t(
                              'enrolment.experiences.adventures.addSectionLabel',
                            )}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
              </Form.List>
            </div>
          </CardSection>
        </div>
        <div ref={element => setDiplomasAndCertifRef(element)}>
          <CardSection
            className={commonStyles.cardSection}
            label={t('enrolment.experiences.diplomas.title')}
          >
            <Form.List name="diplomas">
              {(fields, { add, remove }) => (
                <div>
                  {fields.map((field, idx) => (
                    <div key={field.fieldKey}>
                      <div>
                        <Title style={{ textAlign: 'center' }}>
                          {t('enrolment.experiences.diplomas.sectionTitle', {
                            id: idx + 1,
                          })}
                          <CloseCircleOutlined
                            className={styles.icon}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Title>
                      </div>
                      <Row>
                        <Col {...(isMobile ? fullWidth : layoutLeft)}>
                          <Form.Item
                            name={[field.name, 'title']}
                            label={t(
                              'enrolment.experiences.diplomas.fieldsLabel.title',
                            )}
                            rules={[requiredRule, limitedInputLength]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col {...(isMobile ? fullWidth : layoutRight)}>
                          <Form.Item
                            name={[field.name, 'place']}
                            label={t(
                              'enrolment.experiences.diplomas.fieldsLabel.place',
                            )}
                            rules={[requiredRule, limitedInputLength]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col {...(isMobile ? fullWidth : layoutLeft)}>
                          <Form.Item
                            name={[field.name, 'obtentionDate']}
                            label={t(
                              'enrolment.experiences.diplomas.fieldsLabel.obtentionDate',
                            )}
                            rules={[requiredRule]}
                          >
                            <DatePicker
                              className={styles.fullWidth}
                              placeholder={t(
                                'enrolment.experiences.adventures.fieldsLabel.selectDate',
                              )}
                              format={dateFormat}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Row>
                    <Col {...(isMobile ? fullWidth : layoutFull)}>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined />
                          {t('enrolment.experiences.diplomas.addSectionLabel')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              )}
            </Form.List>
          </CardSection>
        </div>
        <CardSection
          className={commonStyles.cardSection}
          label={t('enrolment.experiences.certifications.title')}
        >
          <Form.List name="certifications">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, idx) => (
                  <div key={field.fieldKey}>
                    <div>
                      <Title style={{ textAlign: 'center' }}>
                        {t(
                          'enrolment.experiences.certifications.sectionTitle',
                          {
                            id: idx + 1,
                          },
                        )}
                        <CloseCircleOutlined
                          className={styles.icon}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </Title>
                    </div>
                    <Row>
                      <Col offset={5} span={14}>
                        <Form.Item
                          name={[field.name, 'certification']}
                          label={t(
                            'enrolment.experiences.certifications.fieldsLabel.certifications',
                          )}
                          rules={[requiredRule, limitedTextAreaLength]}
                        >
                          <Input.TextArea
                            maxLength={MAX_TEXT_AREA_CHAR}
                            showCount
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row>
                  <Col {...(isMobile ? fullWidth : layoutFull)}>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined />
                        {t(
                          'enrolment.experiences.certifications.addSectionLabel',
                        )}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Form.List>
        </CardSection>
        <div ref={element => setLanguagesRef(element)}>
          <CardSection
            className={commonStyles.cardSection}
            label={t('enrolment.experiences.languages.title')}
          >
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              {t('enrolment.experiences.languages.label')}
            </div>
            <Form.Item {...(!isMobile && noLabelCol)} name="languages">
              <Select
                mode="multiple"
                placeholder={t('enrolment.select_placeholder')}
              >
                {data
                  ? data.language.map(({ name }) => (
                      <Select.Option key={name} value={name}>
                        {capitalize(name)}
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
          </CardSection>
        </div>
        <Row justify="center">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={commonStyles.submitButton}
            >
              {nextButtonContent || t('enrolment.submit_button')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Flex>
  );
}

export default ExperiencesPage;
