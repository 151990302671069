import { CalendarOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Experience, PublicExperience } from 'technical/graphql/fragments';
import Flex from 'ui/flex';
import Title from 'ui/title';
import Block from './block';
import styles from './index.module.scss';
import TextIcon from './textIcon';

const { Text } = Typography;

interface Props {
  experiences: (Experience | PublicExperience)[];
}

const DisplayExperiences = ({ experiences }: Props) => (
  <Flex column>
    {experiences.map(
      ({ id, place, job, content, startDate, endDate }, index) => {
        return (
          <Flex className={styles.experienceBlock} alignItems="center" key={id}>
            <Block id={index + 1} />
            <Flex className={styles.description} column>
              <Title>
                {job} - <span className="primary-color">{place}</span>
              </Title>
              <TextIcon
                icon={<CalendarOutlined />}
                label={`${moment(startDate).format('YYYY')}${
                  endDate ? ` - ${moment(endDate).format('YYYY')}` : ''
                }`}
              />
              <Text className="whitespaceBreak">{content}</Text>
            </Flex>
          </Flex>
        );
      },
    )}
  </Flex>
);

export default DisplayExperiences;
