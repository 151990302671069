import gql from 'graphql-tag';
import {
  CustomUserTagFragment,
  TagFragment,
  UserTagFragment,
} from 'technical/graphql/fragments';

export const QUERY_GET_TAGS = gql`
  query getTags {
    tag {
      ...Tag
    }
    userTag {
      ...UserTag
    }
    customUserTag {
      ...CustomUserTag
    }
  }
  ${TagFragment}
  ${UserTagFragment}
  ${CustomUserTagFragment}
`;

export const MUTATION_UPDATE_USER_TAG = gql`
  mutation updateUserTag(
    $userId: uuid
    $userTags: [userTag_insert_input!]!
    $customUserTags: [customUserTag_insert_input!]!
  ) {
    delete_userTag(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    delete_customUserTag(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    insert_userTag(objects: $userTags) {
      affected_rows
    }
    insert_customUserTag(objects: $customUserTags) {
      affected_rows
    }
  }
`;
