import gql from 'graphql-tag';
import {
  AssociationFragment,
  CertificationFragment,
  CustomUserTagFragment,
  DiplomaFragment,
  ExperienceFragment,
  StartupFragment,
  UserFragment,
  UserLanguageFragment,
  UserTagFragment,
} from 'technical/graphql/fragments';

export const QUERY_FULL_USER = gql`
  query GetFullUser($userId: uuid!) {
    user_by_pk(id: $userId) {
      ...User
      experiences(order_by: { startDate: desc }) {
        ...Experience
      }
      diplomas(order_by: { obtentionDate: desc }) {
        ...Diploma
      }
      certifications {
        ...Certification
      }
      languages {
        ...UserLanguage
      }
      startups(order_by: { startDate: desc }) {
        ...Startup
      }
      associations(order_by: { creationDate: desc }) {
        ...Association
      }
      tags {
        ...UserTag
      }
      customTags {
        ...CustomUserTag
      }
    }
  }
  ${UserFragment}
  ${ExperienceFragment}
  ${DiplomaFragment}
  ${CertificationFragment}
  ${UserLanguageFragment}
  ${StartupFragment}
  ${AssociationFragment}
  ${UserTagFragment}
  ${CustomUserTagFragment}
`;

export const MUTATION_UPDATE_USER_INFO = gql`
  mutation updateUserInfo(
    $userId: uuid!
    $firstName: String
    $lastName: String
    $picture: String
    $email: String
    $phone: String
    $linkedInLink: String
    $description: String
    $enrolmentFinished: Boolean
    $isValidating: Boolean
  ) {
    update_user(
      _set: {
        firstName: $firstName
        lastName: $lastName
        picture: $picture
        email: $email
        phone: $phone
        linkedInLink: $linkedInLink
        description: $description
        isEnrolmentFinished: $enrolmentFinished
        isValidating: $isValidating
      }
      where: { id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;

export const MUTATION_UPDATE_USER_PICTURE = gql`
  mutation updateUserPicture($userId: uuid!, $picture: String) {
    update_user(_set: { picture: $picture }, where: { id: { _eq: $userId } }) {
      affected_rows
    }
  }
`;
