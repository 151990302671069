import { GeoAddress } from 'ui/form/addressInput';
import { QueryType, TagQueryResults } from '../type';

export enum MissionFrequence {
  ALL,
  FULL_TIME,
  PART_TIME,
}

export interface FormValues {
  frequence?: MissionFrequence;
  remote?: boolean;
  onlyRemote?: boolean;
  location?: GeoAddress | undefined;
  competences?: string[];
}

export const getMissionsVariables = (
  values: FormValues,
  queryType: QueryType,
  tagsList?: TagQueryResults,
) => {
  const { onlyRemote, remote, frequence, competences, location } = values;
  const tagIds = tagsList ? tagsList.tag.map(tag => tag.id) : [];
  let isRemote = remote ? [true] : [];
  const isFulltime =
    frequence === MissionFrequence.ALL
      ? [true, false]
      : [frequence === MissionFrequence.FULL_TIME];

  const selectedTagIds =
    competences && competences.length > 0 ? competences : tagIds;
  let point = location
    ? {
        type: 'Point',
        coordinates: [
          Number(location.coordinates.latitude),
          Number(location.coordinates.longitude),
        ],
      }
    : null;

  if (onlyRemote) {
    isRemote = [true];
    point = null;
  }

  return queryType === QueryType.WITH_LOCATION
    ? {
        isRemote,
        isFulltime,
        tagIds: selectedTagIds,
        point,
      }
    : { isFulltime, tagIds: selectedTagIds };
};
