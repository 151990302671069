import errorReporting from 'technical/error-reporting';
import { FormValues } from '../SearchBox/getMissionsVariables';

const localStorageSearchCriteriaKey = 'missionSearchCriteria';

export const setMissionSearchCriteriaCache = (searchFormValues: FormValues) => {
  localStorage.setItem(
    localStorageSearchCriteriaKey,
    JSON.stringify(searchFormValues),
  );
};

export const getMissionSearchCriteriaCache = () => {
  const missionSearchCriteriaCacheStringified = localStorage.getItem(
    localStorageSearchCriteriaKey,
  );
  let missionSearchCriteriaCache = null;
  try {
    missionSearchCriteriaCache = missionSearchCriteriaCacheStringified
      ? (JSON.parse(missionSearchCriteriaCacheStringified) as FormValues)
      : null;
  } catch (err) {
    errorReporting.warning(err);
  }
  return missionSearchCriteriaCache;
};
