import { useMutation } from '@apollo/react-hooks';
import { message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import {
  RcCustomRequestOptions,
  UploadFile,
  UploadChangeParam,
} from 'antd/lib/upload/interface';
import React, { useImperativeHandle } from 'react';
import {
  GET_PRIVATE_BUCKET_UPLOAD_URL,
  GET_PRIVATE_BUCKET_DELETE_URL,
} from 'technical/graphql/query/fileUpload';
import logger from 'technical/logger';

const { Dragger } = Upload;

const FILES_TOTAL_MB_MAX = 10;
const MB_SIZE = 1024 * 1024;

const headers = {
  'Content-Type': 'application/octet-stream',
};

export interface uploadMetadata {
  [id: string]: {
    bucketFilename: string;
    name: string;
    size: number;
    type: string;
  };
}

export const extractNameFromUrl = (url: string, fileName: string) => {
  return new URL(url).pathname
    .slice(1) // remove forward '/'
    .split('/')
    .slice(1) // remove the first substring
    .slice(0, -1) // remove fileName with special char (`%20` instead of space char)
    .join('/')
    .concat('/', fileName);
};

interface Props<Value = any[]> {
  onChange?: (value: Value) => void;
  value?: Value;
  children?: React.ReactNode;
  accept?: string;
  uploadFolder?: string;
}

const FileUpload = React.forwardRef<Props, any>(
  ({ onChange, value, accept, children, uploadFolder = '' }, refs) => {
    const [createUploadUrl] = useMutation(GET_PRIVATE_BUCKET_UPLOAD_URL);
    const [createDeleteUrl] = useMutation(GET_PRIVATE_BUCKET_DELETE_URL);
    useImperativeHandle(refs, () => ({ value, onChange }));

    function onChangeCustom(info: UploadChangeParam) {
      if (onChange) {
        // removing file without status - i e which were refused during beforeUpload
        const finalFiles = info.fileList.filter((file: any) => !!file.status);
        onChange(finalFiles);
      }
      logger.info('info', info);
      if (info.file.status !== 'uploading') {
        logger.info(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} a bien été ajouté.`);
      } else if (info.file.status === 'error') {
        message.error(`L'ajout de ${info.file.name} a échoué.`);
      }
    }

    const customRequest = async ({
      file,
      action,
      onSuccess,
      onError,
    }: RcCustomRequestOptions) => {
      // @todo: use axios and show progress indicator
      return fetch(action, {
        method: 'PUT',
        body: file,
        headers,
      })
        .then((res: any) => onSuccess(res, file))
        .catch((err: Error) => onError(err));
    };

    const beforeUpload = (file: RcFile, files: RcFile[]) => {
      if (
        value &&
        value.some(({ name }: { name: string }) => name === file.name)
      ) {
        message.warn('Un fichier avec ce nom existe, supprime le avant');
        return false;
      }
      const totalSize = (value || [])
        .concat(files)
        .reduce(
          (acc: number, currentFile: RcFile) => acc + currentFile.size,
          0,
        );
      // Convert Bytes to MBytes and validate lower than max
      const isLowSizeEnough = totalSize / MB_SIZE < FILES_TOTAL_MB_MAX;
      if (!isLowSizeEnough) {
        message.warn(
          `Les fichiers doivent faire moins de ${FILES_TOTAL_MB_MAX}mb au total`,
        );
        return false;
      }

      return true;
    };

    const action = (file: RcFile) => {
      return createUploadUrl({
        variables: { fileName: file.name, folderName: uploadFolder },
      }).then(result => {
        return result.data.createFileUploadTempUrl.url;
      });
    };

    const onRemove = (file: UploadFile<any>) =>
      new Promise<boolean | void>((resolve, reject) => {
        const url = `${uploadFolder}/${file.name}`;
        createDeleteUrl({
          variables: { fileName: url },
        })
          .then(result => {
            fetch(result.data.createFileDeleteTempUrl.url, {
              method: 'DELETE',
              headers,
            })
              .then(() => {
                resolve(true);
              })
              .catch((err: Error) => {
                logger.error(err);
                reject();
              });
          })
          .catch((err: Error) => {
            logger.error(err);
            reject();
          });
      });

    return (
      <Dragger
        accept={accept || 'image/*,.pdf'}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        method="put"
        name="file"
        multiple
        onChange={onChangeCustom}
        showUploadList={{
          showDownloadIcon: false,
          showRemoveIcon: true,
        }}
        fileList={value || []}
        action={action}
        onRemove={onRemove}
      >
        {children}
      </Dragger>
    );
  },
);

export default FileUpload;
