import { QueryLazyOptions, useQuery } from '@apollo/react-hooks';
import { Checkbox, Divider, Form, Radio, Select, Typography } from 'antd';
import { QUERY_GET_TAGS } from 'business/enrolment/competencesPage/query';
import useUserData from 'business/user/provider/user';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { requiredRule } from 'technical/validation/rules';
import Card from 'ui/cardSection';
import SubmitButton from 'ui/custom/submitButton';
import Flex from 'ui/flex';
import AddressInput from 'ui/form/addressInput';
import {
  getMissionSearchCriteriaCache,
  setMissionSearchCriteriaCache,
} from '../services/missionSearchCriteria';
import { QueryType, TagQueryResults } from '../type';
import {
  FormValues,
  getMissionsVariables,
  MissionFrequence,
} from './getMissionsVariables';
import styles from './index.module.scss';

const { Text } = Typography;
const { Option } = Select;

const dividerStyle = {
  height: 'auto',
};

interface Props {
  queryType: QueryType;
  getMissions: (
    options?: QueryLazyOptions<Record<string, any>> | undefined,
  ) => void;
  setQueryType: React.Dispatch<React.SetStateAction<QueryType>>;
  setTagIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const initialValues: FormValues = {
  frequence: MissionFrequence.ALL,
  remote: true,
  onlyRemote: false,
  location: undefined,
  competences: [],
};

export default function SearchBox({
  queryType,
  getMissions,
  setQueryType,
  setTagIds,
}: Props) {
  const { t } = useTranslation();
  const { user } = useUserData();
  const { data } = useQuery<TagQueryResults>(QUERY_GET_TAGS);
  const missionSearchCriteriaCache = getMissionSearchCriteriaCache();
  const [searchFormValues, setSearchFormValues] = useState(
    missionSearchCriteriaCache || initialValues,
  );
  const [addressDisabled, setAddressDisabled] = useState(
    searchFormValues.onlyRemote,
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (missionSearchCriteriaCache && user && data) {
      form.submit();
    }
    // eslint-disable-next-line
  }, [user, data]);

  useEffect(() => {
    setMissionSearchCriteriaCache(searchFormValues);
  }, [searchFormValues]);

  function handleGetMissions(values: FormValues) {
    setSearchFormValues(values);
    setTagIds(values.competences || []);
    getMissions({
      variables: {
        ...getMissionsVariables(values, queryType, data),
        userId: user?.id,
      },
    });
  }

  const handleOnValuesChange = (_changedValues: any, values: any) => {
    if (values.onlyRemote) {
      setAddressDisabled(true);
      setQueryType(QueryType.ONLY_REMOTE);
    } else {
      setAddressDisabled(false);
      setQueryType(QueryType.WITH_LOCATION);
    }
  };

  return (
    <Card label={t('mission.search.searchBox.title')} className={styles.card}>
      <Form
        form={form}
        name="searchBox"
        onFinish={handleGetMissions}
        initialValues={searchFormValues}
        onValuesChange={handleOnValuesChange}
      >
        <Flex alignItems="center" column>
          <Text>{t('mission.search.searchBox.description')}</Text>
          <div className={styles.cardInnerContainer}>
            {/* Type of employment */}
            <Flex alignItems="center" column>
              <>
                <Text className={styles.subSectionTitle}>
                  {t('mission.search.searchBox.type.label')}
                </Text>
                <Form.Item style={{ margin: 0 }} name="frequence">
                  <Radio.Group>
                    <Radio
                      className={styles.radioButton}
                      value={MissionFrequence.ALL}
                    >
                      {t('enrolment.preferences.all_checkbox_text')}
                    </Radio>
                    <Radio
                      className={styles.radioButton}
                      value={MissionFrequence.FULL_TIME}
                    >
                      {t('enrolment.preferences.fulltime_checkbox_text')}
                    </Radio>
                    <Radio
                      className={styles.radioButton}
                      value={MissionFrequence.PART_TIME}
                    >
                      {t('enrolment.preferences.parttime_checkbox_text')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            </Flex>
            <Divider style={dividerStyle} type="vertical" />
            {/* Place */}
            <Flex alignItems="center" column>
              <Text className={styles.subSectionTitle}>
                {t('mission.search.searchBox.place.label')}
              </Text>
              <Form.Item
                name="location"
                style={{ marginBottom: '8px' }}
                rules={addressDisabled ? undefined : [requiredRule]}
              >
                <AddressInput disabled={addressDisabled} onChange={() => {}} />
              </Form.Item>
              <Form.Item
                style={{ marginTop: '10px' }}
                name="remote"
                valuePropName="checked"
              >
                <Checkbox disabled={addressDisabled}>
                  {t('mission.search.searchBox.place.remote')}
                </Checkbox>
              </Form.Item>
              {t('common.or')}
              <Form.Item
                style={{ marginTop: '10px' }}
                name="onlyRemote"
                valuePropName="checked"
              >
                <Checkbox className={styles.checkbox}>
                  {t('mission.search.searchBox.place.onlyRemote')}
                </Checkbox>
              </Form.Item>
            </Flex>
            <Divider style={dividerStyle} type="vertical" />
            {/* Competences */}
            <Flex alignItems="center" column>
              <Text className={styles.subSectionTitle}>
                {t('mission.search.searchBox.competences.label')}
              </Text>
              <Form.Item className="fullWidth" name="competences">
                <Select mode="multiple" showSearch optionFilterProp="children">
                  {data?.tag.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Flex>
          </div>
          <SubmitButton htmlType="submit">
            {t('mission.search.searchBox.submitButton')}
          </SubmitButton>
        </Flex>
      </Form>
    </Card>
  );
}
