import classnames from 'classnames';
import React from 'react';
import Button from 'ui/menu/link';
import styles from './index.module.scss';

interface Props {
  onClick: () => void;
  icon: React.ReactNode;
  label?: string;
  className?: string;
}

export default function LogoSection({
  onClick,
  icon,
  label,
  className,
}: Props) {
  return (
    <Button
      className={classnames(styles.logoSection, className)}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </Button>
  );
}
