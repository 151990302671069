import logger from 'technical/logger';
import history from 'technical/history';
import config from 'config';

declare global {
  // declaring a new object to Window because
  // ga function has been added to global in index.html
  interface Window {
    ga: any;
  }
}
// Mocking window.ga for test purposes
if (!window.ga) {
  logger.warn("'window.ga' is not set");
  window.ga = () => {};
}
// Initilize Google Analytics
window.ga('create', config.analytics.ga, 'auto');

// https://support.google.com/analytics/answer/1033068?hl=en
// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export const logEvent = ({
  category,
  action,
  label,
  value,
  ...fieldsObject
}: {
  category: string;
  action: string;
  label?: string;
  value?: number;
  [field: string]: string | number | undefined;
}) => {
  if (config.analytics.enabled) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      ...fieldsObject,
    });
  }
};

export const setUserId = (userId: string | undefined) => {
  if (config.analytics.enabled) {
    window.ga('set', '&uid', userId);
  }
};

const logPageViewed = (pathname: string) => {
  if (config.analytics.enabled) {
    window.ga('send', {
      hitType: 'pageview',
      page: pathname,
    });
  }
};

const autoLogPageViewed = () => {
  // Log the first page viewed
  logPageViewed(String(history.location.pathname));
  // Then, attach a listener that log the pathname
  // of the page everytime it changes
  history.listen(({ pathname }) => {
    logPageViewed(pathname);
  });
};
autoLogPageViewed();
