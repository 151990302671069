import { useLazyQuery } from '@apollo/react-hooks';
import { Empty, message } from 'antd';
import BaseContent from 'business/layout/baseContent';
import ConnectedPage from 'business/layout/connectedPage';
import { QUERY_MISSION_BY_ID } from 'business/mission/services/query';
import useUserData from 'business/user/provider/user';
import config from 'config';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Flex from 'ui/flex';
import Loader from 'ui/loader';
import { QueryResult } from '../../services/type';
import MissionLayout from './layout';
import defaultCover from './defaultMissionCover.svg';

export default function MissionDetailsPage() {
  const { t } = useTranslation();
  const { missionId } = useParams();
  const { user } = useUserData();
  const userId = user?.id;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [getMissionById, { data, error, loading }] = useLazyQuery<QueryResult>(
    QUERY_MISSION_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (missionId && userId && !data) {
      getMissionById({ variables: { id: missionId, userId } });
    }
  }, [missionId, userId, data, getMissionById]);

  useEffect(() => {
    if (error) {
      message.error(t('errors.query'));
    }
  }, [error, t]);

  if (loading) {
    return <Loader />;
  }

  return (
    <ConnectedPage>
      {(!data || !data.mission) && error && (
        <BaseContent container="default">
          <Empty description={t('errors.query')} />
        </BaseContent>
      )}
      {data && (
        <BaseContent
          container="default"
          cover={
            <img
              alt={`${data.mission.name}-cover`}
              src={
                data.mission.landscapePicture
                  ? `${config.gcp.bucketBaseUrl}/${data.mission.landscapePicture}`
                  : defaultCover
              }
            />
          }
        >
          <Flex column>
            <MissionLayout
              {...data.mission}
              formattedTags={data.mission.tags.map(tag => ({
                label: tag.tag.name,
              }))}
            />
          </Flex>
        </BaseContent>
      )}
    </ConnectedPage>
  );
}
