import { CloseOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import colors from 'config/colors';
import noScroll from 'no-scroll';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import history from 'technical/history';
import Flex from 'ui/flex';
import MenuLink from 'ui/menu/link';
import styles from './index.module.scss';
import './reactModalOverride.scss';

export interface NavSection {
  path: string;
  label: string;
}

interface Props {
  modalIsOpen: boolean;
  closeModal: () => void;
  navSections: NavSection[];
}

const MODAL_CLOSE_TIMEOUT_MS = 500;

function MenuModal({ modalIsOpen, closeModal, navSections }: Props) {
  useEffect(() => {
    if (modalIsOpen) {
      noScroll.on();
    } else {
      noScroll.off();
    }

    return () => {
      noScroll.off();
    };
  }, [modalIsOpen]);

  const activeSectionPath = history.location.pathname;

  return (
    <Modal
      isOpen={modalIsOpen}
      closeTimeoutMS={MODAL_CLOSE_TIMEOUT_MS}
      onRequestClose={closeModal}
      className="menuModalContainer"
      overlayClassName="menuModalOverlay"
    >
      <Flex className={styles.container} justify="flex-start">
        <CloseOutlined
          onClick={closeModal}
          className={styles.closeIcon}
          style={{ color: colors.grey8 }}
        />
        <nav className={styles.navLinks}>
          {navSections.map(section => (
            <Flex
              key={section.label}
              alignItems="center"
              className={styles.menuLinkContainer}
            >
              <MenuLink
                key={section.label}
                className={classnames(styles.menuLink, {
                  [styles.active]: section.path === activeSectionPath,
                })}
                onClick={() => {
                  history.push(section.path);
                  closeModal();
                }}
              >
                {section.label}
              </MenuLink>
            </Flex>
          ))}
        </nav>
      </Flex>
    </Modal>
  );
}

export default MenuModal;
