import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { Col, Divider, Form, Input, message, Rate, Row, Select } from 'antd';
import { MUTATION_UPDATE_MAILJET_USER } from 'business/mailjet/query';
import { useAppContext } from 'business/provider';
import { TFunction } from 'i18next';
import uniqBy from 'lodash.uniqby';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomUserTag, UserTag } from 'technical/graphql/fragments';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import { requiredErrorAlert, requiredRule } from 'technical/validation/rules';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import Loader from 'ui/loader';
import Title from 'ui/title';
import Description from '../description';
import commonStyles from '../index.module.scss';
import styles from './index.module.scss';
import { MUTATION_UPDATE_USER_TAG, QUERY_GET_TAGS } from './query';
import { FormValues, QueryResults } from './type';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const layoutLeft = { offset: 3, span: 8 };
const layoutRight = { offset: 2, span: 8 };
const layoutFull = { offset: 3, span: 18 };

const fullWidth = { style: { width: '100%' } };

interface Props {
  goNextStep: () => void;
  goToPreviousStep?: () => void;
  nextButtonContent?: string | TFunction;
}

const isInTagList = (tagList: string[]) => ({ tagId }: { tagId: string }) =>
  tagList.includes(tagId);

const isNotInTagList = (isCustom: (tag: { tagId: string }) => boolean) => ({
  tagId,
}: {
  tagId: string;
}) => {
  return !isCustom({ tagId });
};

const getAllInitTags = (userTag: UserTag[], customUserTag: CustomUserTag[]) => [
  ...userTag.map(({ tag: { id }, level }) => ({
    id,
    level,
  })),
  ...customUserTag.map(({ name, level }) => ({
    id: name,
    level,
  })),
];

function CompetencesPage({
  goNextStep,
  goToPreviousStep,
  nextButtonContent,
}: Props) {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { isMobile } = useMediaType();
  const [customTag, setCustomTag] = useState<string>('');
  const [customTagList, setCustomTagList] = useState<
    { id: string; name: string }[]
  >([]);
  const [form] = Form.useForm();
  const client = useApolloClient();

  const userId = user?.id;

  const { data, error, loading } = useQuery<QueryResults>(QUERY_GET_TAGS, {
    fetchPolicy: 'network-only',
  });
  const [updateUserTag] = useMutation(MUTATION_UPDATE_USER_TAG);

  const submit = async ({ competences }: FormValues) => {
    const isNormalTag = isInTagList(data?.tag.map(({ id }) => id) || []);
    const isCustom = isNotInTagList(isNormalTag);

    const filteredCompetences = uniqBy(competences, 'tagId');
    const userTags = filteredCompetences?.filter(isNormalTag) || [];
    const customUserTags = filteredCompetences?.filter(isCustom) || [];

    const tagNames = [
      ...userTags.map(
        tagItem => data?.tag.find(tag => tag.id === tagItem.tagId)?.name || '',
      ),
      ...customUserTags.map(({ tagId }) => tagId),
    ].join(', ');

    try {
      await updateUserTag({
        variables: {
          userId,
          userTags: userTags.map(tag => ({ ...tag, userId })),
          customUserTags: customUserTags.map(({ tagId, level }) => ({
            name: tagId,
            level,
            userId,
          })),
        },
      });

      // Update Mailjet user
      await client.mutate({
        mutation: MUTATION_UPDATE_MAILJET_USER,
        variables: {
          email: user?.email,
          skills: tagNames,
        },
      });
    } catch (err) {
      logger.error(err);
      return;
    }

    goNextStep();
  };

  const addItem = () => {
    if (customTag) {
      setCustomTagList([...customTagList, { id: customTag, name: customTag }]);
      setCustomTag('');
    }
  };

  if (error) {
    message.warning(t('errors.query'));
  }

  if (loading || !userId) {
    return <Loader />;
  }

  return (
    <Flex className={commonStyles.container} column alignItems="center">
      <Form
        {...layout}
        form={form}
        scrollToFirstError
        className="fullWidth"
        onFinish={submit}
        onFinishFailed={requiredErrorAlert}
        initialValues={{
          competences:
            data && (data.userTag.length > 0 || data.customUserTag.length > 0)
              ? getAllInitTags(
                  data?.userTag || [],
                  data?.customUserTag || [],
                ).map(({ id, level }) => ({ tagId: id, level }))
              : [{}],
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          className={commonStyles.stickySubmitButton}
          style={{ marginBottom: '16px' }}
        >
          {nextButtonContent || t('enrolment.submit_button')}
        </Button>
        <Row justify="center">
          {goToPreviousStep && (
            <Button
              className={commonStyles.previousButton}
              type="ghost"
              onClick={goToPreviousStep}
            >
              {t('enrolment.previous_button')}
            </Button>
          )}
        </Row>
        <CardSection
          className={commonStyles.cardSection}
          label={t('enrolment.competences.stepTitle')}
        >
          <Description description={t('enrolment.competences.description')} />

          <Form.List name="competences">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => (
                  <div key={field.fieldKey}>
                    <Title className={styles.title}>
                      {t('enrolment.competences.sectionTitle', {
                        id: index + 1,
                      })}
                      {index > 0 && (
                        <CloseCircleOutlined
                          className={styles.icon}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      )}
                    </Title>

                    <Row>
                      <Col {...(isMobile ? fullWidth : layoutLeft)}>
                        <Form.Item
                          name={[field.name, 'tagId']}
                          rules={[requiredRule]}
                        >
                          <Select
                            showSearch
                            placeholder="Sélectionnez une compétence"
                            optionFilterProp="children"
                            dropdownRender={menu => (
                              <>
                                {menu}
                                <Divider className={styles.divider} />
                                <div className={styles.addTagContainer}>
                                  <Input
                                    className={styles.customInput}
                                    placeholder={t(
                                      'enrolment.competences.customTagInputplaceholder',
                                    )}
                                    value={customTag}
                                    onChange={e => {
                                      e.preventDefault();
                                      setCustomTag(e.target.value);
                                    }}
                                  />
                                  <Button
                                    type="link"
                                    onClick={addItem}
                                    disabled={!(customTag.length > 0)}
                                  >
                                    <PlusOutlined className={styles.icon} />
                                    {t('enrolment.competences.addSectionLabel')}
                                  </Button>
                                </div>
                              </>
                            )}
                          >
                            {data &&
                              [
                                ...customTagList,
                                ...data.tag,
                                ...data.customUserTag,
                              ].map(({ id, name }) => (
                                <Option key={id} value={id}>
                                  {name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col {...(isMobile ? fullWidth : layoutRight)}>
                        <div style={{ width: '100%', margin: '0 auto' }}>
                          <Form.Item
                            name={[field.name, 'level']}
                            rules={[requiredRule]}
                          >
                            <Rate className={styles.rate} />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))}

                <Row>
                  <Col {...(isMobile ? fullWidth : layoutFull)}>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => {
                          add();
                        }}
                        block
                      >
                        <PlusOutlined />
                        {t('enrolment.competences.addSectionLabel')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}
          </Form.List>
        </CardSection>

        <Row justify="center">
          <Form.Item>
            {goToPreviousStep && (
              <Button
                className={commonStyles.previousButton}
                type="ghost"
                onClick={goToPreviousStep}
              >
                {t('enrolment.previous_button')}
              </Button>
            )}
            <Button
              type="primary"
              htmlType="submit"
              className={commonStyles.submitButton}
            >
              {nextButtonContent || t('enrolment.submit_button')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Flex>
  );
}

export default CompetencesPage;
