import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface Props extends React.HTMLProps<HTMLParagraphElement> {
  description: string;
  className?: string;
}

export default function Description({ description, className }: Props) {
  return (
    <p className={classnames(styles.description, className)}>{description}</p>
  );
}
