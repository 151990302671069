import {
  CalendarOutlined,
  EnvironmentOutlined,
  HourglassOutlined,
} from '@ant-design/icons';
import getFormattedStartDate from 'business/service/getFormattedDate';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import TextIcon from './textIcon';

const DisplayPreferences = ({
  preferredStartDate,
  preferredAddress,
  preferredIsFulltime,
  preferredIsParttime,
  preferredIsRemote,
}: {
  preferredStartDate: string | null;
  preferredAddress: string | null;
  preferredIsFulltime: boolean | null;
  preferredIsParttime: boolean | null;
  preferredIsRemote: boolean | null;
}) => {
  const { t } = useTranslation();
  let type = '';
  let location = '';

  if (preferredAddress && preferredIsRemote) {
    location = t('user.profile.preferences.address_remote', {
      address: preferredAddress,
    });
  } else if (preferredAddress) {
    location = preferredAddress;
  } else if (preferredIsRemote) {
    location = t('user.profile.preferences.remote');
  }

  if (preferredIsFulltime && preferredIsParttime) {
    type = t('user.profile.preferences.fulltime_parttime');
  } else if (preferredIsFulltime) {
    type = t('user.profile.preferences.fulltime');
  } else if (preferredIsParttime) {
    type = t('user.profile.preferences.parttime');
  }

  return (
    <div className={styles.preferences}>
      <TextIcon
        icon={<CalendarOutlined />}
        label={getFormattedStartDate(preferredStartDate, true)}
      />
      <TextIcon icon={<EnvironmentOutlined />} label={location} />
      <TextIcon
        icon={<HourglassOutlined />}
        label={t('user.profile.preferences.type', { type })}
      />
    </div>
  );
};

export default DisplayPreferences;
