import {
  GiftOutlined,
  GlobalOutlined,
  LaptopOutlined,
  RocketOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { Space, Tag, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { PublicStartup, Startup } from 'technical/graphql/fragments';
import { normalizeUrl } from 'technical/string/formatter';
import { notNullNorUndefined } from 'technical/utils';
import { i18n } from 'translations';
import Title from 'ui/title';
import styles from './index.module.scss';
import TextIcon from './textIcon';

const { Text } = Typography;

interface Props {
  startup: Startup | PublicStartup;
}

const DisplayStartup = ({
  startup: {
    description,
    name,
    url,
    startDate,
    targetClient,
    role,
    staff,
    sales,
    clientCount,
    fundraising,
    investmentFund,
    businessIncubator,
  },
}: Props) => (
  <Space direction="vertical" size="middle">
    {description && (
      <Text className="whitespaceBreak">{`“${description}“`}</Text>
    )}
    <div>
      <TextIcon
        icon={<RocketOutlined className={styles.icon} />}
        label={name}
      />
      {url && (
        <TextIcon
          icon={<LaptopOutlined className={styles.icon} />}
          label={
            <a
              className={styles.anchor}
              href={normalizeUrl(url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {normalizeUrl(url)}
            </a>
          }
        />
      )}
      {startDate && (
        <TextIcon
          icon={<GiftOutlined className={styles.icon} />}
          label={i18n.t('user.profile.project.creationDate', {
            date: moment(startDate).format('DD/MM/YYYY'),
          })}
        />
      )}
      {targetClient && (
        <TextIcon
          icon={<GlobalOutlined className={styles.icon} />}
          label={i18n.t('user.profile.project.targetClient', {
            targetClient,
          })}
        />
      )}
      {role && (
        <TextIcon
          icon={<ShoppingOutlined className={styles.icon} />}
          label={role}
        />
      )}
    </div>
    <div>
      {(notNullNorUndefined(staff) ||
        notNullNorUndefined(sales) ||
        notNullNorUndefined(clientCount) ||
        notNullNorUndefined(fundraising) ||
        notNullNorUndefined(investmentFund) ||
        notNullNorUndefined(businessIncubator)) && (
        <Title>{i18n.t('user.profile.project.achivements')}</Title>
      )}
      {notNullNorUndefined(staff) && (
        <Tag>
          {i18n.t('user.profile.project.achivementLabel.staff', { staff })}
        </Tag>
      )}
      {notNullNorUndefined(sales) && (
        <Tag>
          {i18n.t('user.profile.project.achivementLabel.sales', { sales })}
        </Tag>
      )}
      {notNullNorUndefined(clientCount) && (
        <Tag>
          {i18n.t('user.profile.project.achivementLabel.clientCount', {
            clientCount,
          })}
        </Tag>
      )}
      {notNullNorUndefined(fundraising) && (
        <Tag>
          {i18n.t('user.profile.project.achivementLabel.fundraising', {
            fundraising,
          })}
        </Tag>
      )}
      {notNullNorUndefined(investmentFund) && (
        <Tag>
          {i18n.t('user.profile.project.achivementLabel.investmentFund', {
            investmentFund,
          })}
        </Tag>
      )}
      {notNullNorUndefined(businessIncubator) && (
        <Tag>
          {i18n.t('user.profile.project.achivementLabel.businessIncubator', {
            businessIncubator,
          })}
        </Tag>
      )}
    </div>
  </Space>
);

export default DisplayStartup;
