import MainRoutes from 'config/routes';
import * as React from 'react';
import { Redirect, Route, Router as ReactDomRouter } from 'react-router-dom';
import history from 'technical/history';
import SwitchNotFoundHandler from 'technical/router/switch';
import MissionDashboard from './dashboard/pages';
import EnrolmentPage from './enrolment';
import { MissionDetailsPage, MissionSearchPage } from './mission/pages';
import ApplicationConfirmationPage from './mission/pages/confirmation/application';
import { useAppContext } from './provider';
import SponsorLinkPage from './sponsor/pages/sponsorLink';
import LoginPage from './user/pages/login';
import LoginProviderCallbackScreen from './user/pages/loginProviderCallback';
import LoginVerificationScreen from './user/pages/loginVerification';
import UserProfilePage from './user/pages/profile';
import PublicProfilePage from './user/pages/publicProfile';
import RefusedUserPage from './user/pages/refused';
import SignUpPage from './user/pages/signUp';
import ValidatingUserPage from './user/pages/validating';
import { User } from './user/provider/user';

const disconnectedRoutes = [
  {
    route: MainRoutes.App,
    component: LoginPage,
    exact: true,
  },
  {
    route: MainRoutes.SignUp,
    component: SignUpPage,
    exact: true,
  },
  {
    route: MainRoutes.LoginCallback,
    component: LoginProviderCallbackScreen,
    exact: true,
  },
  {
    route: MainRoutes.LoginVerification,
    component: LoginVerificationScreen,
    exact: true,
  },
  {
    route: '*',
    component: () => <Redirect to={MainRoutes.SignUp} />,
    exact: false,
  },
];
const validatedRoutes = [
  {
    route: MainRoutes.Profile,
    component: UserProfilePage,
    exact: true,
  },
  {
    route: MainRoutes.Mission,
    component: MissionDetailsPage,
    exact: false,
  },
  {
    route: MainRoutes.SearchMission,
    component: MissionSearchPage,
    exact: false,
  },
  {
    route: MainRoutes.MissionConfirmed,
    component: ApplicationConfirmationPage,
    exact: true,
  },
  {
    route: MainRoutes.MissionDashboard,
    component: MissionDashboard,
    exact: true,
  },
  {
    route: MainRoutes.SponsorStartuper,
    component: SponsorLinkPage,
    exact: true,
  },
  {
    route: MainRoutes.App,
    component: MissionDashboard,
    exact: true,
  },
  {
    route: '*',
    component: () => <Redirect to={MainRoutes.MissionDashboard} />,
    exact: false,
  },
];
const enrolmentRoutes = [
  {
    route: MainRoutes.Enrolment,
    component: EnrolmentPage,
    exact: true,
  },
  {
    route: '*',
    component: () => <Redirect to={MainRoutes.Enrolment} />,
    exact: false,
  },
];
const validatingRoutes = [
  {
    route: MainRoutes.App,
    component: ValidatingUserPage,
    exact: true,
  },
  {
    route: MainRoutes.Profile,
    component: UserProfilePage,
    exact: true,
  },
  {
    route: '*',
    component: () => <Redirect to={MainRoutes.App} />,
    exact: false,
  },
];
const refusedRoutes = [
  {
    route: MainRoutes.App,
    component: RefusedUserPage,
    exact: true,
  },
  {
    route: '*',
    component: () => <Redirect to={MainRoutes.App} />,
    exact: false,
  },
];

const routesRenderer = (isConnected: boolean, user: User | undefined) => {
  // Disconnected routes
  if (!user || !isConnected) {
    return disconnectedRoutes;
  }

  // Connected routes
  const { isValidated, isValidating, isEnrolmentFinished } = user;

  if (!isEnrolmentFinished) {
    return enrolmentRoutes;
  }

  if (isValidated) {
    return validatedRoutes;
  }

  if (isValidating) {
    return validatingRoutes;
  }

  return refusedRoutes;
};

const Router = () => {
  const { isConnected, user } = useAppContext();

  const routes = routesRenderer(isConnected, user);

  return (
    <ReactDomRouter history={history}>
      <SwitchNotFoundHandler>
        {/* All access Routes */}
        <Route
          path={MainRoutes.PublicUserProfle}
          exact
          component={PublicProfilePage}
        />
        {/* Context dependent Routes */}
        {routes.map(({ route, exact, component }) => (
          <Route key={route} path={route} exact={exact} component={component} />
        ))}
      </SwitchNotFoundHandler>
    </ReactDomRouter>
  );
};

export default Router;
