import gql from 'graphql-tag';

export interface Association {
  id: string;
  name: string;
  job: string;
  url: string;
  description: string;
  userId: string;
}

export const AssociationFragment = gql`
  fragment Association on association {
    id
    name
    job
    url
    description
    userId
  }
`;

export interface PublicAssociation {
  id: string;
  name: string;
  job: string;
  url: string;
  description: string;
}

export const PublicAssociationFragment = gql`
  fragment PublicAssociation on association {
    id
    name
    job
    url
    description
  }
`;
