if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error('Set REACT_APP_AUTH0_DOMAIN');
}

if (!process.env.REACT_APP_AUTH0_CLIENT_ID) {
  throw new Error('Set REACT_APP_AUTH0_CLIENT_ID');
}

if (!process.env.REACT_APP_AUTH0_REDIRECT_URI) {
  throw new Error('Set REACT_APP_AUTH0_REDIRECT_URI');
}

if (!process.env.REACT_APP_GRAPHQL_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_URI');
}

if (!process.env.REACT_APP_GRAPHQL_WS_URI) {
  throw new Error('Set REACT_APP_GRAPHQL_WS_URI');
}

if (!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  throw new Error('Set REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID');
}

if (!process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  throw new Error('Set REACT_APP_GOOGLE_TAG_MANAGER_ID');
}

if (!process.env.REACT_APP_CALENDLY_URL) {
  throw new Error('Set REACT_APP_CALENDLY_URL');
}

const config = {
  env: process.env.REACT_APP_ENVIRONMENT,
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
    logoUrl: process.env.REACT_APP_LOGO_URL,
  },
  graphqlUri: process.env.REACT_APP_GRAPHQL_URI,
  graphqlWsUri: process.env.REACT_APP_GRAPHQL_WS_URI,
  analytics: {
    enabled:
      process.env.REACT_APP_ANALYTICS_ENABLED === 'true' ||
      process.env.REACT_APP_ENVIRONMENT === 'production' ||
      process.env.REACT_APP_ENVIRONMENT === 'test',

    ga: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  },
  gcp: {
    bucketBaseUrl: process.env.REACT_APP_BUCKET_BASE_URL,
  },
  contact: {
    email: 'contact@fair4b.com',
    phone: '06 08 70 02 17',
    credits: '© 2020 Fair4b - tous droits réservés',
    calendlyUrl: process.env.REACT_APP_CALENDLY_URL,
  },
  search: {
    // unit: meters
    searchRadius: 30000,
  },
};

export default config;
