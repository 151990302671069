import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import {
  RcCustomRequestOptions,
  RcFile,
  UploadFile,
} from 'antd/lib/upload/interface';
import React, { useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GET_PUBLIC_BUCKET_DELETE_URL,
  GET_PUBLIC_BUCKET_UPLOAD_URL,
} from 'technical/graphql/query/fileUpload';
import logger from 'technical/logger';
import { getFileName } from 'technical/url/formatter';

const FILES_TOTAL_MB_MAX = 2;
const MB_SIZE = 1024 * 1024;

const headers = {
  'Content-Type': 'application/octet-stream',
};

interface Props<Value = any[]> {
  onChange?: (value: Value) => void;
  value?: Value;
  avatar?: { name: string; loading: boolean };
  setAvatar?: (avatar: { name: string; loading: boolean } | undefined) => void;
  userId: string;
}

const AvatarUpload = React.forwardRef<Props, any>(
  ({ onChange, value, avatar, setAvatar, userId }: Props, refs) => {
    useImperativeHandle(refs, () => ({ onChange, value, userId }));

    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [createUploadUrl] = useMutation(GET_PUBLIC_BUCKET_UPLOAD_URL);
    const [createDeleteUrl] = useMutation(GET_PUBLIC_BUCKET_DELETE_URL);

    function onChangeCustom(info: any) {
      if (onChange) {
        // removing file without status - i e which were refused during beforeUpload
        const finalFiles = info.fileList.filter((file: any) => !!file.status);
        onChange(finalFiles);
      }
      if (info.file.status !== 'uploading') {
        logger.info(info.file, info.fileList);
      }

      switch (info.file.status) {
        case 'uploading':
          setLoading(true);
          break;
        case 'done':
          setLoading(false);
          if (setAvatar) {
            setAvatar({
              name: getFileName(info.file.response.url) || '',
              loading: false,
            });
          }
          message.success(`${info.file.name} a bien été ajouté.`);
          break;
        case 'error':
          setLoading(false);
          message.error(`L'ajout de ${info.file.name} a échoué.`);
          break;
        case 'removed':
          setLoading(false);
          break;
        default:
          break;
      }
    }

    const customRequest = async ({
      file,
      action,
      onSuccess,
      onError,
    }: RcCustomRequestOptions) => {
      // TODO: use axios and show progress indicator
      return fetch(action, {
        method: 'PUT',
        body: file,
        headers,
      })
        .then((res: any) => onSuccess(res, file))
        .catch((err: Error) => onError(err));
    };

    const beforeUpload = (_file: RcFile, files: RcFile[]) => {
      const totalSize = (value || [])
        .concat(files)
        .reduce((acc: number, file: any) => acc + file.size, 0);
      // Convert Bytes to MBytes and validate lower than max
      const isLowSizeEnough = totalSize < FILES_TOTAL_MB_MAX * MB_SIZE;
      if (!isLowSizeEnough) {
        message.warn(`L'avatar doit faire moins de ${FILES_TOTAL_MB_MAX}mb`);
        return false;
      }
      return true;
    };

    const action = (file: RcFile) =>
      createUploadUrl({
        variables: {
          fileName: `${userId}-${file.name}`,
          folderName: 'avatar',
        },
      }).then(result => result.data.createPublicFileUploadTempUrl.url);

    const onRemove = (file: UploadFile) =>
      new Promise<boolean | void>((resolve, reject) => {
        const bucketFileName = avatar?.name || file.name;

        createDeleteUrl({
          variables: { fileName: bucketFileName, isAvatar: true },
        })
          .then(result => {
            fetch(result.data.createPublicFileDeleteTempUrl.url, {
              method: 'DELETE',
              headers,
            })
              .then(() => {
                if (setAvatar) {
                  setAvatar(undefined);
                }
                resolve(true);
              })
              .catch((err: Error) => {
                logger.error(err);
                reject();
              });
          })
          .catch(err => {
            logger.error(err);
            reject();
          });
      });

    return (
      <ImgCrop modalTitle="Editer l'image" modalCancel="Annuler">
        <Upload
          accept="image/jpeg,image/png"
          customRequest={customRequest}
          beforeUpload={beforeUpload}
          method="put"
          name="avatar"
          listType="picture-card"
          onChange={onChangeCustom}
          showUploadList={{
            showDownloadIcon: false,
            showRemoveIcon: true,
          }}
          fileList={value || []}
          action={action}
          onRemove={onRemove}
        >
          {value && value.length < 1 && (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div className="ant-upload-text">
                {t('user.profile.profilePicture')}
              </div>
            </div>
          )}
        </Upload>
      </ImgCrop>
    );
  },
);

export default AvatarUpload;
