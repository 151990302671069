import React from 'react';
import classnames from 'classnames';
import styles from './cardSectionTitle.module.scss';

interface Props extends React.HTMLProps<HTMLHeadingElement> {
  label: string;
  center?: boolean;
}

function SectionTitle({ center = true, label, className, ...rest }: Props) {
  return (
    <div className={styles.titleContainer}>
      <h1 {...rest} className={classnames(className, styles.title)}>
        {label}
      </h1>
      <div className={center ? styles.lineCenter : styles.lineLeft} />
    </div>
  );
}

export default SectionTitle;
