import { ApolloProvider } from '@apollo/react-hooks';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import frFR from 'antd/lib/locale/fr_FR';
import 'moment/locale/fr';
import React from 'react';
import ErrorBoundary from 'technical/error-boundary';
import apolloClient from 'technical/graphql/client';
import './App.scss';
import AppBootstrapper from './AppBootstrapper';
import { AppProvider } from './provider';
import Router from './Router';

const App = () => (
  <ErrorBoundary>
    <ApolloProvider client={apolloClient}>
      <ConfigProvider locale={frFR}>
        <AppProvider>
          <AppBootstrapper>
            <Router />
          </AppBootstrapper>
        </AppProvider>
      </ConfigProvider>
    </ApolloProvider>
  </ErrorBoundary>
);

export default App;
