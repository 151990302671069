import gql from 'graphql-tag';

export interface Startup {
  id: string;
  name: string;
  role: string;
  url: string;
  startDate: string;
  endDate: string | null;
  staff: number;
  sales: number;
  clientCount: number;
  fundraising: number;
  investmentFund: string;
  businessIncubator: string;
  description: string;
  technologies: string[];
  targetClient: string;
  userId: string;
}

export const StartupFragment = gql`
  fragment Startup on startup {
    id
    name
    role
    url
    startDate
    endDate
    staff
    sales
    clientCount
    fundraising
    investmentFund
    businessIncubator
    description
    technologies
    targetClient
    userId
  }
`;

export interface PublicStartup {
  id: string;
  name: string;
  role: string;
  url: string;
  startDate: string;
  staff: number;
  sales: number;
  clientCount: number;
  fundraising: number;
  investmentFund: string;
  businessIncubator: string;
  description: string;
  targetClient: string[];
}

export const PublicStartupFragment = gql`
  fragment PublicStartup on startup {
    id
    name
    role
    url
    startDate
    staff
    sales
    clientCount
    fundraising
    investmentFund
    businessIncubator
    description
    targetClient
  }
`;
