import { Modal, Typography } from 'antd';
import { useAppContext } from 'business/provider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ClipboardInput from 'ui/clipboardInput';
import Flex from 'ui/flex';
import Button from 'ui/button';

const { Title } = Typography;

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

const SharingModal = ({ isOpen, onCancel }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const userId = user?.id;

  const sponsorLink = `${process.env.REACT_APP_FRONT_URL}/public/profile/${userId}`;

  return (
    <Modal
      title={t('user.profile.sharingModal.title')}
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
    >
      <Flex column justify="center" alignItems="center">
        <Title level={4}>{t('user.profile.sharingModal.subtitle')}</Title>
        <ClipboardInput clipboardString={sponsorLink} />
        <Button
          type="link"
          href={sponsorLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('user.profile.sharingModal.secondaryLink')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default SharingModal;
