import { MailOutlined } from '@ant-design/icons';
import { Auth0ParseHashError } from 'auth0-js';
import DisconnectedPage from 'business/layout/disconnectedPage';
import { useAppContext } from 'business/provider';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'technical/history';
import logger from 'technical/logger';
import DisplayCard from 'ui/displayCard';
import Flex from 'ui/flex';
import Loader from 'ui/loader';
import { requestLoginCallback } from '../services/authentication';
import styles from './index.module.scss';

function LoginProviderCallbackScreen() {
  const [error, setError] = React.useState<Auth0ParseHashError | undefined>(
    undefined,
  );
  const { t } = useTranslation();
  const { requestRebootstrap } = useAppContext();

  React.useEffect(() => {
    requestLoginCallback()
      .then(() => {
        requestRebootstrap();
      })
      .catch(err => {
        logger.error(err);
        setError(err);
      });
  }, [requestRebootstrap]);

  const login = () => {
    history.push('/');
  };

  if (error) {
    let errorKey = 'default';

    switch (error.errorDescription) {
      case 'invalid-email-domain':
      case 'email-not-verified':
        errorKey = error.errorDescription;
        break;
      default:
        break;
    }

    return (
      <DisconnectedPage>
        <Flex
          style={{ flex: 1, padding: '20px' }}
          justify="center"
          alignItems="center"
        >
          <DisplayCard
            className={styles.displayCard}
            image={<MailOutlined />}
            title={t(`notConnected.login.callback.error.${errorKey}.title`)}
            subtitle={t(
              `notConnected.login.callback.error.${errorKey}.subTitle`,
            )}
            actionButtonProps={{
              children: t(
                `notConnected.login.callback.error.${errorKey}.button`,
              ),
              type: 'primary',
              onClick: login,
            }}
          />
        </Flex>
      </DisconnectedPage>
    );
  }
  return (
    // Shortly displaying loading indicator, before either displaying error or redirecting to home page
    <DisconnectedPage>
      <Loader />
    </DisconnectedPage>
  );
}

export default LoginProviderCallbackScreen;
