import appLogo from 'config/appLogo.svg';
import appLogoWhite from 'config/appLogoWhite.svg';
import React from 'react';
import MenuLink from 'ui/menu/link';
import styles from './index.module.scss';

interface AppLogo {
  height?: string;
  path?: string;
  white?: boolean;
}

export default function AppLogo({ height, white }: AppLogo) {
  return (
    <MenuLink className={styles.menuLink} path="/">
      <img
        className={styles.appLogo}
        src={white ? appLogoWhite : appLogo}
        alt="App logo"
        height={height}
      />
    </MenuLink>
  );
}
