import { Result } from 'antd';
import React from 'react';
import errorReporting from 'technical/error-reporting';
import history from 'technical/history';
import logger from 'technical/logger';
import Button from 'ui/button';
import { i18n } from 'translations';
import SimpleCard from 'ui/displayCard/simpleCard';

interface Props {}
interface State {
  hasError: false;
  error?: Error;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error) {
    logger.error(error);
    errorReporting.warning(error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <SimpleCard>
          <Result
            status="warning"
            title={i18n.t('errors.error_boundary_component')}
            extra={
              <Button type="primary" onClick={() => history.push('/')}>
                {i18n.t('errors.to_main_page')}
              </Button>
            }
          />
        </SimpleCard>
      );
    }

    return children;
  }
}

const ErrorBoundary = ErrorBoundaryComponent;

export default ErrorBoundary;
