import { Button, Input, Typography } from 'antd';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'technical/analytics';
import { copyToClipboard, focusElement } from 'technical/utils';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import styles from './index.module.scss';

const { Text } = Typography;

interface Props {
  userId: string | undefined;
  className?: string;
}

export default function SponsorCard({ userId, className }: Props) {
  const { t } = useTranslation();
  const inputRef = useRef<null | Input>(null);
  const sponsorLink = `${process.env.REACT_APP_FRONT_URL}/signup?sponsorUserId=${userId}`;
  const [showSponsorLink, toggleSponsorLink] = useState(false);

  const handleSponsorButton = () => {
    toggleSponsorLink(!showSponsorLink);
    logEvent({
      category: 'sponsor',
      action: 'share_sponsor_link',
    });
  };

  const cardContent = showSponsorLink ? (
    <>
      <Input
        ref={inputRef}
        className={styles.sponsorLinkInput}
        value={sponsorLink}
        onFocus={() => focusElement(inputRef)}
        readOnly
      />
      <Button type="link" onClick={() => copyToClipboard(inputRef)}>
        {t('common.copyLink')}
      </Button>
    </>
  ) : (
    <Button type="primary" onClick={handleSponsorButton}>
      {t('mission.details.sponsorLinkBtn')}
    </Button>
  );

  return (
    <CardSection className={classnames(styles.sponsorContainer, className)}>
      <Flex column alignItems="center">
        <Text className={styles.sponsorText}>
          {t('mission.details.sponsorSomeone')}
        </Text>
        {cardContent}
      </Flex>
    </CardSection>
  );
}
