import { login } from 'business/user/services/authentication';
import React, { useEffect } from 'react';
import Loader from 'ui/loader';

function LoginPage() {
  useEffect(() => {
    login();
  }, []);

  return <Loader />;
}

export default LoginPage;
