// eslint-disable-next-line import/prefer-default-export
export const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const normalizeUrl = (url: string | undefined) => {
  if (!url) {
    return url;
  }
  if (
    url.startsWith('http://') ||
    url.startsWith('https://') ||
    url.startsWith('://')
  ) {
    return url;
  }
  return `http://${url}`;
};
