import gql from 'graphql-tag';

export interface UserTag {
  id: string;
  tag: {
    id: string;
    name: string;
  };
  level: number;
}

export const UserTagFragment = gql`
  fragment UserTag on userTag {
    id
    tag {
      id
      name
    }
    level
  }
`;

export interface PublicUserTag {
  id: string;
  tag: {
    id: string;
    name: string;
  };
}

export const PublicUserTagFragment = gql`
  fragment PublicUserTag on userTag {
    id
    tag {
      id
      name
    }
  }
`;
