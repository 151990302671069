import { AlignLeftOutlined, UserOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Dropdown, Layout, Menu } from 'antd';
import { useAppContext } from 'business/provider';
import { logout } from 'business/user/services/authentication';
import classnames from 'classnames';
import layout from 'config/layout';
import React, { useState } from 'react';
import history from 'technical/history';
import AppLogo from 'ui/appLogo';
import MenuLink from 'ui/menu/link';
import LogoSection from 'ui/navBar/components/logoSection';
import MenuModal from 'ui/navBar/components/menuModal';
import styles from './index.module.scss';

const { Header } = Layout;

export interface Section {
  onClick: () => void;
  // Type of an antd V4 icon
  icon: React.ForwardRefExoticComponent<
    AntdIconProps & React.RefAttributes<HTMLSpanElement>
  >;
  label: string;
}

interface Props {
  isConnected?: boolean;
  logoSections?: Section[];
}

export default function StandardNavBar({
  isConnected,
  logoSections = [],
}: Props) {
  const { user } = useAppContext();
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);

  function openMenuModal() {
    setMenuModalIsOpen(true);
  }

  function closeMenuModal() {
    setMenuModalIsOpen(false);
  }

  function onLoginClick() {
    if (isConnected) {
      logout();
    } else {
      history.push('/');
    }
  }

  const iconStyle = { marginRight: '15px', fontSize: '50px' };

  return (
    <Header className={classnames(styles.header, 'elevation2')}>
      <MenuModal
        modalIsOpen={menuModalIsOpen}
        closeModal={closeMenuModal}
        navSections={layout.header.menuSections}
      />
      <div className={styles.headerContent}>
        <AppLogo />
        <nav style={{ display: 'flex', alignItems: 'center' }}>
          <LogoSection
            className={styles.section}
            onClick={openMenuModal}
            icon={<AlignLeftOutlined style={iconStyle} />}
            label="Menu"
          />
          {logoSections.map(section => (
            <LogoSection
              key={section.label}
              className={styles.section}
              onClick={section.onClick}
              icon={React.createElement(section.icon, {
                style: iconStyle,
              })}
              label={section.label}
            />
          ))}
          {isConnected ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={logout}>
                    <MenuLink>Se déconnecter</MenuLink>
                  </Menu.Item>
                </Menu>
              }
            >
              <MenuLink className="ant-dropdown-link">
                <LogoSection
                  className={styles.section}
                  onClick={() => {}}
                  icon={<UserOutlined style={iconStyle} />}
                  label={isConnected && user ? user.email : 'Connection'}
                />
              </MenuLink>
            </Dropdown>
          ) : (
            <LogoSection
              className={styles.section}
              onClick={onLoginClick}
              icon={<UserOutlined style={iconStyle} />}
              label={user ? user.email : 'Connection'}
            />
          )}
        </nav>
      </div>
    </Header>
  );
}
