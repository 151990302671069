import { Result } from 'antd';
import ConnectedPage from 'business/layout/connectedPage';
import DisconnectedPage from 'business/layout/disconnectedPage';
import { footerConfig } from 'business/layout/footerDefaultConfig';
import { useAppContext } from 'business/provider';
import React, { useEffect } from 'react';
import { Route } from 'react-router';
import errorReporting from 'technical/error-reporting';
import history from 'technical/history';
import Button from 'ui/button';
import Footer from 'ui/footer';
import { i18n } from 'translations';
import SimpleCard from 'ui/displayCard/simpleCard';

function ReportingNoMatch() {
  const { isConnected } = useAppContext();

  useEffect(() => {
    errorReporting.info(new Error('404'), { location: history.location });
  }, []);

  const Page = isConnected ? ConnectedPage : DisconnectedPage;

  return (
    <Route
      component={() => (
        <Page footer={<Footer {...footerConfig} />}>
          <SimpleCard>
            <Result
              status="404"
              title="404"
              subTitle={i18n.t('errors.error_not_found_page')}
              extra={
                <Button type="primary" onClick={() => history.goBack()}>
                  {i18n.t('common.back')}
                </Button>
              }
            />
          </SimpleCard>
        </Page>
      )}
    />
  );
}

export default ReportingNoMatch;
