import { Space, Typography } from 'antd';
import React from 'react';
import {
  Certification,
  PublicCertification,
} from 'technical/graphql/fragments';
import { i18n } from 'translations';
import Title from 'ui/title';

const { Text } = Typography;

interface Props {
  certifications: (Certification | PublicCertification)[];
  isPublic?: boolean;
}

const DisplayCertifications = ({ certifications, isPublic }: Props) => {
  if (isPublic && certifications.length === 0) {
    return null;
  }

  return (
    <>
      <Title>{i18n.t('certifications')}</Title>
      {certifications.length > 0 ? (
        <Space direction="vertical" size="middle">
          {certifications.map(({ id, certification }) => {
            return (
              <Text className="whitespaceBreak" key={id}>
                {certification}
              </Text>
            );
          })}
        </Space>
      ) : (
        i18n.t('user.profile.emptySection')
      )}
    </>
  );
};

export default DisplayCertifications;
