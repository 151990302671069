import React from 'react';
import CompetencesPage from '../competencesPage';
import ExperiencesPage from '../experiencesPage';
import PreferencesPage from '../preferencesPage';
import ProjectPage from '../projectPage';
import SummaryPage from '../summaryPage';

export enum EnrolmentSteps {
  EXPERIENCES,
  PROJECT,
  COMPETENCES,
  PREFERENCES,
  SUMMARY,
  ASSOCIATION,
}

interface Props {
  step: EnrolmentSteps;
  goNextStep: () => void;
  goToPreviousStep: () => void;
  goToStep: {
    goToExperiences: () => void;
    goToProject: () => void;
    goToAssociation: () => void;
    goToCompetences: () => void;
    goToPreferences: () => void;
  };
  scrollToAssociation?: boolean;
}

export default function StepPage({
  step,
  goToStep,
  goNextStep,
  goToPreviousStep,
  scrollToAssociation,
}: Props) {
  switch (step) {
    case EnrolmentSteps.EXPERIENCES:
      return <ExperiencesPage goNextStep={goNextStep} />;
    case EnrolmentSteps.PROJECT:
      return (
        <ProjectPage
          goNextStep={goNextStep}
          goToPreviousStep={goToPreviousStep}
          scrollToAssociation={scrollToAssociation}
        />
      );
    case EnrolmentSteps.COMPETENCES:
      return (
        <CompetencesPage
          goNextStep={goNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      );
    case EnrolmentSteps.PREFERENCES:
      return (
        <PreferencesPage
          goNextStep={goNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      );
    case EnrolmentSteps.SUMMARY:
      return (
        <SummaryPage
          goNextStep={goNextStep}
          goToStep={goToStep}
          goToPreviousStep={goToPreviousStep}
        />
      );
    default:
      return null;
  }
}
