import gql from 'graphql-tag';
import {
  CertificationFragment,
  DiplomaFragment,
  ExperienceFragment,
  LanguageFragment,
  UserLanguageFragment,
} from 'technical/graphql/fragments';

export const QUERY_EXPERIENCES_DIPLOMAS = gql`
  query GetExperiencesDiplomas($userId: uuid!) {
    user: user_by_pk(id: $userId) {
      id
      files
    }
    experience(where: { userId: { _eq: $userId } }) {
      ...Experience
    }
    diploma(where: { userId: { _eq: $userId } }) {
      ...Diploma
    }
    certification(where: { userId: { _eq: $userId } }) {
      ...Certification
    }
    language {
      ...Language
    }
    userLanguage(where: { userId: { _eq: $userId } }) {
      ...UserLanguage
    }
  }
  ${ExperienceFragment}
  ${DiplomaFragment}
  ${CertificationFragment}
  ${LanguageFragment}
  ${UserLanguageFragment}
`;

export const MUTATION_INSERT_EXPERIENCES_AND_DIPLOMAS = gql`
  mutation AddExperiencesAndDiplomas(
    $experiences: [experience_insert_input!]!
    $diplomas: [diploma_insert_input!]!
    $certifications: [certification_insert_input!]!
    $languages: [userLanguage_insert_input!]!
    $files: jsonb
    $userId: uuid
  ) {
    delete_experience(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    delete_diploma(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    delete_certification(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    delete_userLanguage(where: { userId: { _eq: $userId } }) {
      affected_rows
    }
    insert_experience(objects: $experiences) {
      affected_rows
    }
    insert_diploma(objects: $diplomas) {
      affected_rows
    }
    insert_certification(objects: $certifications) {
      affected_rows
    }
    insert_userLanguage(objects: $languages) {
      affected_rows
    }
    update_user(where: { id: { _eq: $userId } }, _set: { files: $files }) {
      affected_rows
    }
  }
`;
