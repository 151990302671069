import React from 'react';
import Footer from 'ui/footer';
import { NavSection } from 'ui/navBar/components/menuModal';
import { footerConfig } from './footerDefaultConfig';
import Page from './page';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
}

function DisconnectedPage(props: Props) {
  const disconnectedMenuItems: NavSection[] = [
    // {
    //   label: t('header.blog'),
    //   path: MainRoutes.Blog,
    // },
  ];

  return (
    <Page
      {...props}
      displayedMenuSections={disconnectedMenuItems}
      isConnected={false}
      footer={<Footer {...footerConfig} />}
    />
  );
}

export default DisconnectedPage;
