import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';

interface Props extends React.HTMLProps<HTMLHeadingElement> {
  children: React.ReactNode;
}

function Title({ children, className, ...rest }: Props) {
  return (
    <h2 {...rest} className={classnames(styles.title, className)}>
      {children}
    </h2>
  );
}

export default Title;
