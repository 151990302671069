import { BackTop, Layout } from 'antd';
import React from 'react';
import NavBar from 'ui/navBar';
import { NavSection } from 'ui/navBar/components/menuModal';
import styles from './index.module.scss';

const { Content, Footer } = Layout;

interface Props {
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  isConnected: boolean;
  isValidated?: boolean;
  title?: string;
  displayedMenuSections?: NavSection[];
}

// const nakerBackOptions = {
//   particle: {
//     life: 5,
//     power: 0.01,
//     texture:
//       'https://res.cloudinary.com/naker-io/image/upload/v1566560053/circle_05.png',
//     number: 407,
//     colorStart: [255, 255, 255, 1],
//     colorEnd: [141, 141, 141, 0.52],
//     sizeStart: 0.23,
//     sizeEnd: 0.46,
//     direction1: { x: 0, y: 100, z: 100 },
//     direction2: { x: 0, y: 0, z: 0 },
//   },
//   environment: {
//     gradient: 'horizontal',
//     sensitivity: 0.8,
//     colorStart: [135, 213, 198, 1],
//     colorEnd: [128, 240, 212, 1],
//   },
// };

function Page({
  children = null,
  footer = null,
  img,
  isConnected,
  displayedMenuSections,
}: Props) {
  // useEffect(() => {
  //   const naker = window.nakerback.render({
  //     ...nakerBackOptions,
  //     container: document.getElementById('naker-container'),
  //   });

  //   return () => {
  //     if (naker && naker.system) {
  //       naker.system.stopRender();
  //     }
  //   };
  // }, []);

  return (
    <Layout className={styles.layout}>
      <NavBar
        isConnected={isConnected}
        displayedMenuSections={displayedMenuSections}
      />
      {img && <img src={img.src} alt={img.alt} />}
      <Content
        className={styles.content}
        // Set the id in order to use Naker background
        // id="naker-container"
        // Set padding to 0 to hide watermark
        style={{ padding: 0 }}
      >
        {children}
        <BackTop />
      </Content>
      {footer && <Footer className={styles.footer}>{footer}</Footer>}
    </Layout>
  );
}

export default Page;
