import { User } from 'business/user/provider/user';
import MainRoutes from 'config/routes';
import { i18n } from 'translations';

if (!process.env.REACT_APP_NEW_MISSION_TYPEFORM_URL) {
  throw new Error('Set REACT_APP_NEW_MISSION_TYPEFORM_URL');
}

if (!process.env.REACT_APP_F4B_BLOG_URL) {
  throw new Error('Set REACT_APP_F4B_BLOG_URL');
}

const getMenuSections = (user: User | undefined) => {
  if (!user) {
    return [];
  }

  const { isValidating, isValidated, isEnrolmentFinished } = user;

  if (!isEnrolmentFinished) {
    return [];
  }

  if (isValidated) {
    // @todo inject user data for hidden fields
    const typeformUrl = `${process.env.REACT_APP_NEW_MISSION_TYPEFORM_URL}`;

    return [
      {
        label: i18n.t('header.searchMission'),
        path: MainRoutes.SearchMission,
      },
      {
        label: i18n.t('header.myMissions'),
        path: MainRoutes.MissionDashboard,
      },
      {
        label: i18n.t('header.createMission'),
        path: typeformUrl,
      },
      {
        label: i18n.t('header.sponsorStartuper'),
        path: MainRoutes.SponsorStartuper,
      },
      {
        label: i18n.t('header.blog'),
        path: process.env.REACT_APP_F4B_BLOG_URL!,
      },
    ];
  }

  if (isValidating) {
    return [
      {
        label: i18n.t('header.blog'),
        path: process.env.REACT_APP_F4B_BLOG_URL!,
      },
    ];
  }

  return [];
};

export default getMenuSections;
