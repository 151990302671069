export const getAvatarFilePath = () =>
  `${
    process.env.ENV === 'local' ? 'user-documents-local' : 'user-documents'
  }/avatar`;

export const getFileName = (fileUrl: string) => {
  if (!fileUrl) {
    return null;
  }
  // using pathname in order to remove all query parameters
  const { pathname } = new URL(fileUrl);

  return pathname.substring(pathname.lastIndexOf('/') + 1);
};
