import logger from 'technical/logger';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import config from 'config/index';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import {
  renewToken,
  getAccessToken,
} from 'business/user/services/authentication';
import { setContext } from 'apollo-link-context';

const authLink = setContext((_, { headers: originalHeaders }: any) => {
  // get the authentication token from local storage if it exists
  const token = getAccessToken();

  // Determine isAnonymous if authorization is explicitly null in query custom headers
  const { authorization, ...headers } = originalHeaders || {};
  const isAnonymous = authorization === null;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...(token && !isAnonymous && { authorization: `Bearer ${token}` }),
    },
  };
});

const httpLink = new HttpLink({
  uri: `${config.graphqlUri}/v1/graphql`,
  // Custom fetch to handle reconnection on jwt expired
  fetch: (input, init) => {
    return fetch(input, init).then(async response => {
      const json = await response.json();
      if (json.errors && json.errors[0]?.extensions?.code === 'invalid-jwt') {
        logger.info('Renewing token');
        return renewToken().then(authResult => {
          const newToken = authResult.idToken;
          logger.info('Token renewed!');
          // Updating headers with new token
          return fetch(input, {
            ...init,
            headers: {
              ...init?.headers,
              authorization: `Bearer ${newToken}`,
            },
          });
        });
      }

      // Recreating json ad text method that ca be called only one beefore forwardig
      return {
        ...response,
        json: () => Promise.resolve(json),
        text: () => Promise.resolve(JSON.stringify(json)),
      };
    });
  },
});

const client = new SubscriptionClient(`${config.graphqlWsUri}/v1/graphql`, {
  reconnect: true,
  timeout: 30000,
  connectionParams: () => {
    const token = getAccessToken();

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  },
});

const wsLink = new WebSocketLink(client);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    // @ts-ignore https://hasura.io/blog/moving-from-apollo-boost-to-graphql-subscriptions-with-apollo-client-cc0373e0adb0/
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);
const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default apolloClient;
