import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { Col, Form, Input, message, Row } from 'antd';
import { MUTATION_UPDATE_MAILJET_USER } from 'business/mailjet/query';
import { useAppContext } from 'business/provider';
import ProfileContent from 'business/user/pages/profileContent';
import config from 'config';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'technical/analytics';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import { getAvatarFilePath } from 'technical/url/formatter';
import {
  MAX_TEXT_AREA_CHAR,
  requiredErrorAlert,
  requiredRule,
} from 'technical/validation/rules';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import AvatarUpload from 'ui/form/avatarUpload';
import Loader from 'ui/loader';
import Description from '../description';
import commonStyles from '../index.module.scss';
import {
  MUTATION_UPDATE_USER_INFO,
  MUTATION_UPDATE_USER_PICTURE,
  QUERY_FULL_USER,
} from './query';
import { FormValues, QueryResults } from './type';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const fullWidth = { style: { width: '100%' } };

interface Props {
  goToPreviousStep: () => void;
  goNextStep: () => void;
  goToStep: {
    goToExperiences: () => void;
    goToProject: () => void;
    goToAssociation: () => void;
    goToCompetences: () => void;
    goToPreferences: () => void;
  };
}

function SummaryPage({ goToStep, goToPreviousStep, goNextStep }: Props) {
  const { t } = useTranslation();
  const { user, requestRebootstrap } = useAppContext();
  const { isMobile } = useMediaType();
  const [displayFirstName, setDisplayFirstName] = useState('');
  const userId = user?.id;
  const [avatar, setAvatar] = useState<{ name: string; loading: boolean }>();
  const client = useApolloClient();
  const [form] = Form.useForm();

  const { data, loading, error: queryError } = useQuery<QueryResults>(
    QUERY_FULL_USER,
    {
      variables: { userId },
      fetchPolicy: 'cache-and-network',
    },
  );

  const profileFunctions = {
    editPreferences: goToStep.goToPreferences,
    editCompetences: goToStep.goToCompetences,
    editStartup: goToStep.goToProject,
    editAssociation: goToStep.goToAssociation,
    editExperiences: goToStep.goToExperiences,
    editDiplomasAndCertifications: goToStep.goToExperiences,
    editLanguages: goToStep.goToExperiences,
  };

  const [updateUserInfo, { error: mutationUserError }] = useMutation(
    MUTATION_UPDATE_USER_INFO,
  );
  const [updateUserPicture, { error: mutationPictureError }] = useMutation(
    MUTATION_UPDATE_USER_PICTURE,
  );

  const updateAvatar = (
    newAvatar:
      | {
          name: string;
          loading: boolean;
        }
      | undefined,
  ) => {
    setAvatar(newAvatar);
    if (newAvatar && !newAvatar.loading) {
      // Async but not waited
      updateUserPicture({
        variables: {
          picture: newAvatar.name,
          userId,
        },
      }).catch(err => {
        logger.error(err);
      });
    }
  };

  const onFinish = async (
    {
      firstName,
      lastName,
      email,
      phone,
      linkedInLink,
      description,
    }: FormValues,
    changePage: Boolean = true,
  ) => {
    try {
      await updateUserInfo({
        variables: {
          userId,
          firstName,
          lastName,
          picture: avatar?.name || '',
          email,
          phone,
          linkedInLink: linkedInLink || '',
          description,
          enrolmentFinished: changePage,
          isValidating: changePage,
        },
      });

      // Update Mailjet user
      await client.mutate({
        mutation: MUTATION_UPDATE_MAILJET_USER,
        variables: {
          email: user?.email,
          linkedin: linkedInLink,
          enrolmentFinished: changePage,
        },
      });
      if (user?.firstName !== firstName) {
        // Refetch User is firstName change to change the header
        requestRebootstrap();
      }
    } catch (err) {
      logger.error(err);
      return;
    }
    if (changePage) {
      goNextStep();
      logEvent({
        category: 'enrolment',
        action: 'finished',
      });
    } else {
      message.success(t('enrolment.saveSuccess'));
    }
  };

  const saveWithoutSubmit = async (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    event.preventDefault();
    try {
      await form.validateFields();
    } catch {
      return;
    }
    onFinish(form.getFieldsValue(), false);
  };

  const getFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    setDisplayFirstName(event.target.value);
  };

  if (mutationUserError || mutationPictureError) {
    message.error(t('errors.mutation'));
  }

  if (loading || !userId) {
    return <Loader />;
  }

  if (queryError) {
    message.warning(t('errors.query'));
  }

  return (
    <>
      <Description
        description={t('enrolment.summary.description')}
        className={commonStyles.descriptionPadding}
      />
      <Form
        {...layout}
        form={form}
        className="fullWidth"
        onFinish={onFinish}
        onFinishFailed={requiredErrorAlert}
        initialValues={{
          avatar: data?.user_by_pk.picture
            ? [
                {
                  uid: '-1',
                  name: data?.user_by_pk.picture,
                  status: 'done',
                  url: `${config.gcp.bucketBaseUrl}/${getAvatarFilePath()}/${
                    data?.user_by_pk.picture
                  }`,
                },
              ]
            : [],
          firstName: data?.user_by_pk.firstName || '',
          lastName: data?.user_by_pk.lastName || '',
          email: data?.user_by_pk.email || '',
          phone: data?.user_by_pk.phone || '',
          linkedInLink: data?.user_by_pk.linkedInLink || '',
          description: data?.user_by_pk.description || '',
        }}
      >
        <Button
          className={commonStyles.stickySubmitButton}
          type="primary"
          htmlType="submit"
          style={{ marginBottom: '16px' }}
        >
          {t('enrolment.submit_button')}
        </Button>
        <Row justify="center">
          <Button
            onClick={goToPreviousStep}
            className={commonStyles.previousButton}
            type="ghost"
          >
            {t('enrolment.previous_button')}
          </Button>
        </Row>
        <div className={commonStyles.container}>
          <CardSection
            className={commonStyles.cardSection}
            label={t('enrolment.summary.stepTitle')}
          >
            <Flex justify="center">
              <Form.Item name="avatar">
                <AvatarUpload
                  userId={userId}
                  avatar={
                    avatar || {
                      name: data?.user_by_pk.picture || '',
                      loading: false,
                    }
                  }
                  setAvatar={updateAvatar}
                />
              </Form.Item>
            </Flex>
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
                <Form.Item
                  name="firstName"
                  label={t('enrolment.summary.fieldsLabel.firstName')}
                  rules={[requiredRule]}
                >
                  <Input onChange={getFirstName} />
                </Form.Item>
              </Col>
              <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
                <Form.Item
                  name="lastName"
                  label={t('enrolment.summary.fieldsLabel.lastName')}
                  rules={[requiredRule]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
                <Form.Item
                  name="email"
                  label={t('enrolment.summary.fieldsLabel.email')}
                  rules={[requiredRule]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
                <Form.Item
                  name="phone"
                  label={t('enrolment.summary.fieldsLabel.phone')}
                  rules={[requiredRule]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
                <Form.Item
                  name="linkedInLink"
                  label={t('enrolment.summary.fieldsLabel.linkedInLink')}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 14 })}>
                <Form.Item
                  name="description"
                  label={t('enrolment.summary.fieldsLabel.description')}
                  rules={[requiredRule]}
                >
                  <Input.TextArea maxLength={MAX_TEXT_AREA_CHAR} showCount />
                </Form.Item>
              </Col>
            </Row>
            <Flex justify="center">
              <Form.Item>
                <Button
                  onClick={saveWithoutSubmit}
                  className={commonStyles.previousButton}
                  type="primary"
                >
                  {t('enrolment.save_button')}
                </Button>
              </Form.Item>
            </Flex>
          </CardSection>
        </div>

        {data && (
          <ProfileContent
            {...profileFunctions}
            className={commonStyles.cardSection}
            user={{
              ...data.user_by_pk,
            }}
            firstName={displayFirstName}
            uploadedAvatar={avatar}
          />
        )}
        <Row justify="center">
          <Form.Item>
            <Button
              onClick={goToPreviousStep}
              className={commonStyles.previousButton}
              type="ghost"
            >
              {t('enrolment.previous_button')}
            </Button>
            <Button
              className={commonStyles.submitButton}
              type="primary"
              htmlType="submit"
            >
              {t('enrolment.submit_button')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
}

export default SummaryPage;
