import React, { createContext, useContext } from 'react';
import logger from 'technical/logger';
import useUserData, { User } from './user/provider/user';

interface AppContext {
  user: User | undefined;
  isConnected: boolean;
  appBootstraped: boolean;
  refreshUser: () => Promise<void>;
  requestRebootstrap: () => Promise<void>;
}

const AppContext = createContext<AppContext>({
  user: undefined,
  isConnected: false,
  appBootstraped: false,
  refreshUser: () => Promise.resolve(),
  requestRebootstrap: () => Promise.resolve(),
});

interface Props {
  children: React.ReactNode;
}
export function AppProvider({ children }: Props) {
  const { isBootstraped, ...userData } = useUserData();

  logger.info('AppProvider', userData);

  return (
    <AppContext.Provider
      value={{
        ...userData,
        appBootstraped: isBootstraped,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useAppContext = () => useContext(AppContext);
