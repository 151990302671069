import { MissionPreview } from 'technical/graphql/fragments/mission';
import { Tag } from 'technical/graphql/fragments/tag';

export enum QueryType {
  ONLY_REMOTE,
  WITH_LOCATION,
}

export interface TagQueryResults {
  tag: Tag[];
}

export interface MissionQueryResults {
  mission: MissionPreview[];
}
