import { Tag } from 'antd';
import React from 'react';
import { i18n } from 'translations';
import Title from 'ui/title';
import styles from './index.module.scss';

const DisplayCompetences = ({
  tags = [],
}: {
  tags: { id: string; name: string }[];
}) => (
  <div className={styles.competences}>
    <Title>{i18n.t('user.profile.competences.label')}</Title>
    {tags.map(({ id, name }) => (
      <Tag key={id}>{name}</Tag>
    ))}
  </div>
);

export default DisplayCompetences;
