import DisconnectedPage from 'business/layout/disconnectedPage';
import * as React from 'react';
import Loader from 'ui/loader';
import { webAuth } from '../services/authentication';

function LoginVerificationScreen() {
  console.log('(LoginVerificationScreen');
  React.useEffect(() => {
    // crossOriginVerification
    // auth0
    webAuth.crossOriginVerification();
  });

  return (
    // Shortly displaying loading indicator, before either displaying error or redirecting to home page
    <DisconnectedPage>
      <Loader />
    </DisconnectedPage>
  );
}

export default LoginVerificationScreen;
