import { i18n } from 'translations';
import { NamePath } from 'antd/lib/form/interface';
import { RuleObject } from 'antd/lib/form';
import { Moment } from 'moment';
import { message } from 'antd';

export const MAX_TEXT_AREA_CHAR = 1000;

// eslint-disable-next-line import/prefer-default-export
export const requiredRule = {
  required: true,
  message: i18n.t('errors.required'),
};

export const dateFormat = 'DD/MM/YYYY';

interface CustomRule {
  type?:
    | 'string'
    | 'number'
    | 'boolean'
    | 'method'
    | 'regexp'
    | 'integer'
    | 'float'
    | 'object'
    | 'enum'
    | 'date'
    | 'url'
    | 'hex'
    | 'email';
  // Any here because i18n does not return a string
  [key: string]: any;
}

export const positiveNumberRule: CustomRule = {
  type: 'number',
  min: 0,
  message: i18n.t('errors.too_small'),
};

export const limitedInputLength: CustomRule = {
  type: 'string',
  max: 50,
  message: i18n.t('errors.too_long'),
};

export const limitedTextAreaLength: CustomRule = {
  type: 'string',
  max: MAX_TEXT_AREA_CHAR,
  message: i18n.t('errors.too_long'),
};

export const urlValidation: CustomRule = {
  pattern: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  message: i18n.t('errors.wrong_url_format'),
};

export const notBeforeStartDate = (name: NamePath) => ({
  getFieldValue,
}: {
  getFieldValue: (name: NamePath) => any;
}) => ({
  validator(_rule: RuleObject, value: Moment) {
    const startDate: Moment = getFieldValue(name);
    if (!value) {
      return Promise.resolve();
    }
    if (value.isBefore(startDate)) {
      return Promise.reject(i18n.t('errors.before_start'));
    }
    return Promise.resolve();
  },
});

export const requiredErrorAlert = () => {
  message.error(i18n.t('errors.missing_required_fields'));
};
