import React from 'react';
import layout from 'config/layout';
import FullNavBar from './components/fullNavBar';
import StandardNavBar, { Section } from './components/standardNavBar';

interface Props {
  isConnected?: boolean;
  logoSections?: Section[];
  displayedMenuSections?: {
    label: string;
    path: string;
  }[];
}

export default function NavBar({
  isConnected,
  displayedMenuSections,
  logoSections,
}: Props) {
  switch (layout.header.type) {
    case 'full':
      return (
        <FullNavBar
          isConnected={isConnected}
          displayedMenuSections={displayedMenuSections}
        />
      );
    case 'standard':
    default:
      return (
        <StandardNavBar isConnected={isConnected} logoSections={logoSections} />
      );
  }
}
