import { useAppContext } from 'business/provider';
import React from 'react';
import ClipboardInput from 'ui/clipboardInput';

const SponsorInput = () => {
  const { user } = useAppContext();

  const userId = user ? user.id : null;
  const sponsorLink = `${process.env.REACT_APP_FRONT_URL}/signup?sponsorUserId=${userId}`;

  return <ClipboardInput clipboardString={sponsorLink} />;
};

export default SponsorInput;
