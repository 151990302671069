import gql from 'graphql-tag';

export interface CustomStartupTag {
  id: string;
  name: string;
  level: number;
}

export const CustomStartupTagFragment = gql`
  fragment CustomStartupTag on customStartupTag {
    id
    name
  }
`;
