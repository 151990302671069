import classnames from 'classnames';
import React from 'react';
import Flex from 'ui/flex';
import styles from './index.module.scss';

interface Metadata {
  icon?: React.ReactNode;
  label: React.ReactNode;
}

interface Props {
  metadatas: Metadata[];
  className?: string;
}

export default function MetadataRow({ metadatas, className }: Props) {
  return (
    <div className={classnames(styles.metadatas, className)}>
      {metadatas.map(data => (
        <Flex
          className={styles.metadataItem}
          alignItems="center"
          key={`${data.label}`}
        >
          <span className={styles.icon}>{data.icon}</span>
          <span className={styles.label}>{data.label}</span>
        </Flex>
      ))}
    </div>
  );
}
