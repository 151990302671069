import { Space } from 'antd';
import React from 'react';
import { PublicUserLanguage, UserLanguage } from 'technical/graphql/fragments';
import Title from 'ui/title';

interface Props {
  languages: (UserLanguage | PublicUserLanguage)[];
}

const DisplayLanguages = ({ languages }: Props) => (
  <Space direction="vertical" size="large">
    {languages.map(({ name }) => (
      <Title key={name}>{name}</Title>
    ))}
  </Space>
);

export default DisplayLanguages;
