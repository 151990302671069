import gql from 'graphql-tag';

export interface Language {
  id: string;
  name: string;
}

export const LanguageFragment = gql`
  fragment Language on language {
    id
    name
  }
`;
