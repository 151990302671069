import colors from 'config/colors';
import React, { useEffect, useState } from 'react';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import './index.css';

const placesRequiredFields = ['address_components', 'geometry.location'];

export interface GeoAddress {
  name: string;
  input: string;
  city: string;
  zipCode: string;
  country: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  value: string;
}

interface Props {
  onChange: (val: GeoAddress) => void;
  searchType?:
    | 'city'
    | 'country'
    | 'address'
    | 'busStop'
    | 'townhall'
    | 'trainStation'
    | 'airport'
    | undefined;
  value?: GeoAddress;
  error?: string;
  disabled?: boolean;
}

export const emptyGeoAddress: GeoAddress = {
  name: '',
  input: '',
  city: '',
  zipCode: '',
  country: '',
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
  value: '',
};

const antdInputStyle = {
  lineHeight: '32px',
  height: '32px',
  borderRadius: '2px',
  border: `1px solid ${colors.normalColor}`,
};

const antdErrorInputStyle = {
  ...antdInputStyle,
  borderColor: colors.errorColor,
};

function concatAddress(addressItems: string[]) {
  return addressItems.filter(e => e.length > 0).join(', ');
}

export default function AddressInput({
  value = emptyGeoAddress,
  searchType,
  onChange,
  error,
  disabled,
}: Props) {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef: HTMLInputElement | null = document.querySelector(
    '#address-input',
  );

  useEffect(() => {
    if (value.input === '' && searchQuery === '') {
      setSearchQuery('');
    }
  }, [value, searchQuery]);

  const inputStyle = error ? antdErrorInputStyle : antdInputStyle;

  useEffect(() => {
    if (inputRef) {
      let autocomplete: google.maps.places.Autocomplete;
      try {
        autocomplete = new google.maps.places.Autocomplete(inputRef, {
          componentRestrictions: { country: ['fr'] },
          fields: placesRequiredFields,
          types: searchType
            ? [searchType === 'city' ? 'locality' : searchType]
            : [],
        });
      } catch (err) {
        logger.error('Cannot use Google Maps API:', err);
        return () => {};
      }

      if (value.input !== '') {
        setSearchQuery(value.input);
      }

      const fillInAddress = () => {
        const place = autocomplete.getPlace();
        const { address_components: addressComponent, geometry } = place;

        // Fields here should match the array `placesRequiredFields`
        if (!addressComponent || !geometry || !geometry.location) {
          errorReporting.error(
            new Error(
              `Google places fields, missing field for ${inputRef.value}`,
            ),
          );
          return;
        }

        const placeGeoAddress = { ...emptyGeoAddress };

        addressComponent.forEach(placeElement => {
          const { types, long_name: longName } = placeElement;

          if (types.includes('street_number')) {
            placeGeoAddress.name = `${longName}`;
          }

          if (types.includes('route')) {
            placeGeoAddress.name = `${placeGeoAddress.name} ${longName}`;
          }

          if (types.includes('locality')) {
            placeGeoAddress.city = longName;
          }

          if (types.includes('postal_code')) {
            placeGeoAddress.zipCode = longName;
          }

          if (types.includes('country')) {
            placeGeoAddress.country = longName;
          }
        });

        placeGeoAddress.coordinates = {
          latitude: geometry.location.lat(),
          longitude: geometry.location.lng(),
        };

        if (searchType === 'country') {
          placeGeoAddress.input = placeGeoAddress.country;
        } else if (searchType === 'city') {
          placeGeoAddress.input = concatAddress([
            placeGeoAddress.city,
            placeGeoAddress.country,
          ]);
        } else {
          placeGeoAddress.input = concatAddress([
            placeGeoAddress.name,
            placeGeoAddress.city,
            placeGeoAddress.country,
          ]);
        }

        setSearchQuery(placeGeoAddress.input);
        onChange(placeGeoAddress);
      };
      autocomplete.setFields(['address_component', 'geometry']);
      autocomplete.addListener('place_changed', fillInAddress);
      return () => {
        autocomplete.unbindAll();
      };
    }
    return () => {};
  }, [searchType, value, inputRef, onChange]);

  return (
    <input
      id="address-input"
      value={searchQuery}
      style={inputStyle}
      onChange={event => {
        setSearchQuery(event.target.value);
      }}
      disabled={disabled}
    />
  );
}
