import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  HourglassOutlined,
} from '@ant-design/icons';
import { Empty, message, Typography } from 'antd';
import { ApolloError } from 'apollo-client';
import getFormattedStartDate from 'business/service/getFormattedDate';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CtaCard from 'ui/custom/ctaCard';
import MissionCard from 'ui/custom/missionCard';
import Flex from 'ui/flex';
import CardSectionTitle from 'ui/title/cardSectionTitle';
import config from 'config';
import Loader from 'ui/loader';
import styles from './index.module.scss';
import { MissionQueryResults } from './type';
import { getLocation } from '../details/service';

const { Text } = Typography;

interface Props {
  loading: boolean;
  error: ApolloError | undefined;
  data: MissionQueryResults | undefined;
}

export default function Results({ loading, error, data }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  if (error) {
    message.error(t('errors.query'));
  }

  if (loading) {
    return <Loader />;
  }

  if (!data || !data.mission || !data.mission.length) {
    return (
      <Empty
        style={{ marginBottom: '20px' }}
        className={classnames('overGraphicBg', styles.empty)}
        description={t('mission.search.noMission')}
      />
    );
  }

  const { mission } = data;

  return (
    <Flex
      column
      alignItems="center"
      className={classnames(styles.missionsContainer, 'overGraphicBg')}
    >
      <CardSectionTitle label={t('mission.search.myResults')} />
      <Text className={styles.missionCount}>
        {t('mission.search.count', { count: mission.length })}
      </Text>

      {mission.map(
        ({
          id,
          name,
          portraitPicture,
          activityArea,
          typology,
          address,
          duration,
          durationType,
          isFulltime,
          startDate,
          shortDescription,
          tags,
          isRemote,
        }) => {
          const formattedTags = tags.map(tagItem => ({
            label: tagItem.tag.name,
          }));

          return (
            <div key={id} className={styles.missionCard}>
              <MissionCard
                className={styles.missionCard}
                cover={
                  portraitPicture ? (
                    <img
                      alt={name}
                      src={`${config.gcp.bucketBaseUrl}/${portraitPicture}`}
                    />
                  ) : (
                    undefined
                  )
                }
                title={name}
                subtitle={`${typology || ''} - ${activityArea || ''}`}
                tags={formattedTags}
                metadatas={[
                  {
                    label: getLocation(address, isRemote),
                    icon: <EnvironmentOutlined />,
                  },
                  {
                    label: `${duration} ${t(`mission.search.${durationType}`, {
                      count: duration,
                    })}`,
                    icon: <ClockCircleOutlined />,
                  },
                  {
                    label: isFulltime
                      ? t('mission.search.fullTime')
                      : t('mission.search.partTime'),
                    icon: <HourglassOutlined />,
                  },
                  {
                    label: getFormattedStartDate(startDate),
                    icon: <CalendarOutlined />,
                  },
                ]}
                description={shortDescription}
                buttonProps={{
                  children: t('mission.search.seeOffer'),
                  type: 'primary',
                  onClick: () => {
                    history.push(`/mission/${id}`);
                  },
                }}
              />
            </div>
          );
        },
      )}

      <CtaCard
        className={styles.ctaCard}
        title={t('mission.search.suggestMission')}
        ctaBtnProps={{
          type: 'primary',
          children: (
            <a
              href={`${process.env.REACT_APP_NEW_MISSION_TYPEFORM_URL}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('mission.search.suggestMissionBtn')}
            </a>
          ),
        }}
      />
    </Flex>
  );
}
