import gql from 'graphql-tag';

export interface Experience {
  id: string;
  job: string;
  place: string;
  startDate: string;
  endDate: string | null;
  content: string;
  userId: string;
}

export const ExperienceFragment = gql`
  fragment Experience on experience {
    id
    job
    place
    startDate
    endDate
    content
    userId
  }
`;

export interface PublicExperience {
  id: string;
  job: string;
  place: string;
  startDate: string;
  endDate: string | null;
  content: string;
}

export const PublicExperienceFragment = gql`
  fragment PublicExperience on experience {
    id
    job
    place
    startDate
    endDate
    content
  }
`;
