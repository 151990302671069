import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import { Diploma, PublicDiploma } from 'technical/graphql/fragments';
import Flex from 'ui/flex';
import Title from 'ui/title';
import Block from './block';
import styles from './index.module.scss';
import TextIcon from './textIcon';

interface Props {
  diplomas: (Diploma | PublicDiploma)[];
}

const DisplayDiplomas = ({ diplomas }: Props) => (
  <>
    {diplomas.map(({ id, place, title, obtentionDate }, index) => {
      return (
        <Flex className={styles.experienceBlock} alignItems="center" key={id}>
          <Block id={index + 1} />
          <Flex className={styles.description} column>
            <Title>
              {title} -<span className="primary-color">{place}</span>
            </Title>
            <TextIcon
              icon={<CalendarOutlined />}
              label={moment(obtentionDate).format('YYYY')}
            />
          </Flex>
        </Flex>
      );
    })}
  </>
);

export default DisplayDiplomas;
