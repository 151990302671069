import gql from 'graphql-tag';
import {
  AssociationFragment,
  PublicAssociationFragment,
  CertificationFragment,
  PublicCertificationFragment,
  CustomUserTagFragment,
  PublicCustomUserTagFragment,
  DiplomaFragment,
  PublicDiplomaFragment,
  ExperienceFragment,
  PublicExperienceFragment,
  StartupFragment,
  PublicStartupFragment,
  UserFragment,
  PublicUserFragment,
  UserLanguageFragment,
  PublicUserLanguageFragment,
  UserTagFragment,
  PublicUserTagFragment,
} from 'technical/graphql/fragments';

export const QUERY_FULL_USER = gql`
  query GetFullUser($userId: uuid!) {
    user_by_pk(id: $userId) {
      ...User
      experiences(order_by: { startDate: desc }) {
        ...Experience
      }
      diplomas(order_by: { obtentionDate: desc }) {
        ...Diploma
      }
      certifications {
        ...Certification
      }
      languages {
        ...UserLanguage
      }
      startups(order_by: { startDate: desc }) {
        ...Startup
      }
      associations(order_by: { creationDate: desc }) {
        ...Association
      }
      tags {
        ...UserTag
      }
      customTags {
        ...CustomUserTag
      }
    }
  }
  ${UserFragment}
  ${ExperienceFragment}
  ${DiplomaFragment}
  ${CertificationFragment}
  ${UserLanguageFragment}
  ${StartupFragment}
  ${AssociationFragment}
  ${UserTagFragment}
  ${CustomUserTagFragment}
`;

export const QUERY_PUBLIC_USER = gql`
  query getPublicUser($userId: uuid!) {
    user_by_pk(id: $userId) {
      ...PublicUser
      experiences(order_by: { startDate: desc }) {
        ...PublicExperience
      }
      diplomas(order_by: { obtentionDate: desc }) {
        ...PublicDiploma
      }
      certifications {
        ...PublicCertification
      }
      languages {
        ...PublicUserLanguage
      }
      startups(order_by: { startDate: desc }) {
        ...PublicStartup
      }
      associations(order_by: { creationDate: desc }) {
        ...PublicAssociation
      }
      tags {
        ...PublicUserTag
      }
      customTags {
        ...PublicCustomUserTag
      }
    }
  }
  ${PublicUserFragment}
  ${PublicExperienceFragment}
  ${PublicDiplomaFragment}
  ${PublicCertificationFragment}
  ${PublicUserLanguageFragment}
  ${PublicStartupFragment}
  ${PublicAssociationFragment}
  ${PublicUserTagFragment}
  ${PublicCustomUserTagFragment}
`;

export const MUTATION_UPDATE_USER_INFO = gql`
  mutation updateUserInfo(
    $userId: uuid!
    $firstName: String
    $lastName: String
    $email: String
    $picture: String
    $phone: String
    $linkedInLink: String
    $description: String
  ) {
    update_user(
      _set: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        picture: $picture
        phone: $phone
        linkedInLink: $linkedInLink
        description: $description
      }
      where: { id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;

const CommonUserFieldsFragment = gql`
  fragment CommonUserFields on user {
    id
    authId
    lastName
    firstName
    email
    isActive
    createdAt
    updatedAt
    isEnrolmentFinished
    isValidated
    isValidating
    sponsorUserId
    phone
  }
`;

export const QUERY_MY_USER = gql`
  query QueryMyUser($id: String, $email: String) {
    user(
      where: {
        _or: [
          { authId: { _eq: $id } }
          { _and: [{ email: { _eq: $email } }, { authId: { _is_null: true } }] }
        ]
      }
    ) {
      ...CommonUserFields
    }
  }
  ${CommonUserFieldsFragment}
`;

export const MUTATION_CREATE_MY_USER = gql`
  mutation CreateMyUser(
    $id: String
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $sponsorUserId: uuid
  ) {
    insert_user(
      objects: [
        {
          authId: $id
          email: $email
          firstName: $firstName
          lastName: $lastName
          phone: $phone
          sponsorUserId: $sponsorUserId
        }
      ]
    ) {
      returning {
        id
        firstName
        lastName
        email
        phone
        sponsorUserId
      }
    }
  }
`;

export const MUTATION_UPDATE_USER_AUTHID = gql`
  mutation UpdateUserAuthId(
    $firstName: String
    $lastName: String
    $phone: String
    $sponsorUserId: uuid
    $id: uuid
  ) {
    update_user(
      _set: {
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        sponsorUserId: $sponsorUserId
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        firstName
        lastName
        email
        phone
        sponsorUserId
      }
      affected_rows
    }
  }
`;

export const QUERY_GET_SPONSORED_USER = gql`
  query getSponsoredUser {
    sponsorUserCount {
      success
      count
    }
  }
`;
