import config from 'config';
import gql from 'graphql-tag';
import {
  MissionFragment,
  MissionPreviewFragment,
} from 'technical/graphql/fragments/mission';
import { MissionApplicationFragment } from 'technical/graphql/fragments/missionApplication';

export const QUERY_MISSION_BY_ID = gql`
  query GetMissionById($id: uuid!, $userId: uuid) {
    mission: mission_by_pk(id: $id) {
      ...Mission
      missionApplications(
        where: { userId: { _eq: $userId }, _and: { missionId: { _eq: $id } } }
      ) {
        ...MissionApplication
      }
    }
  }
  ${MissionFragment}
  ${MissionApplicationFragment}
`;

// This query is related to "QUERY_REMOTE_MISSIONS_SEARCH" the ones on the back (with the same name)
// If a modification happens in one, you may want to change all of them
export const QUERY_MISSIONS_SEARCH = gql`
  query getMissionsByLocation(
    $isFulltime: [Boolean]
    $isRemote: [Boolean]
    $tagIds: [uuid!]
    $point: geography!
    $userId: uuid
  ) {
    mission(
      where: {
        _and: [
          {
            _or: [
              { location: { _st_d_within: { distance: ${config.search.searchRadius}, from: $point } } }
              { isRemote: { _in: $isRemote } }
            ]
          }
          {
            _or: [
              { _not: { tags: {} } }
              { tags: { tag: { id: { _in: $tagIds } } } }
            ]
          }
          { isFulltime: { _in: $isFulltime } }
          { status: { _eq: "application" } }
          { _not: { missionApplications: { userId: { _eq: $userId } } } }
        ]
      }
    ) {
      ...MissionPreview
    }
  }
  ${MissionPreviewFragment}
`;

// This query is related to "QUERY_MISSIONS_SEARCH" the ones on the back (with the same name)
// If a modification happens in one, you may want to change all of them
export const QUERY_REMOTE_MISSIONS_SEARCH = gql`
  query getMissionsOnlyRemote(
    $isFulltime: [Boolean]
    $tagIds: [uuid!]
    $userId: uuid
  ) {
    mission(
      where: {
        _or: [
          { _not: { tags: {} } }
          { tags: { tag: { id: { _in: $tagIds } } } }
        ]
        isFulltime: { _in: $isFulltime }
        isRemote: { _eq: true }
        status: { _eq: "application" }
        _not: { missionApplications: { userId: { _eq: $userId } } }
      }
    ) {
      ...MissionPreview
    }
  }
  ${MissionPreviewFragment}
`;

export const MUTATION_CANDITATE_TO_MISSION = gql`
  mutation CandidateToMission($missionId: uuid, $userId: uuid) {
    insert_missionApplication(
      objects: { missionId: $missionId, userId: $userId }
    ) {
      affected_rows
    }
  }
`;
