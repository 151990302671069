// eslint-disable-next-line import/prefer-default-export
export enum UserViewStatus {
  PROFILE,
  EXPERIENCES,
  DIPLOMAS_AND_CERTIF, // = experiences
  LANGUAGES, // = experiences
  PROJECT,
  ASSOCIATION, // = project
  COMPETENCES,
  PREFERENCES,
  EDIT_PROFILE,
}
