import gql from 'graphql-tag';
import {
  AssociationFragment,
  StartupFragment,
  TagFragment,
} from 'technical/graphql/fragments';
import { CustomStartupTagFragment } from 'technical/graphql/fragments/customStartupTag';

export const QUERY_STARTUP_AND_ASSOCIATION = gql`
  query GetStartupAssociation($userId: uuid) {
    startup(
      where: { userId: { _eq: $userId } }
      order_by: { startDate: desc }
    ) {
      ...Startup
    }
    association(
      where: { userId: { _eq: $userId } }
      order_by: { creationDate: desc }
    ) {
      ...Association
    }
    tag {
      ...Tag
    }
    customStartupTag {
      ...CustomStartupTag
    }
  }
  ${StartupFragment}
  ${AssociationFragment}
  ${TagFragment}
  ${CustomStartupTagFragment}
`;

export const MUTATION_INSERT_STARTUP = gql`
  mutation AddStartup($startup: startup_insert_input!) {
    insert_startup(objects: [$startup]) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const MUTATION_UPDATE_STARTUP = gql`
  mutation UpdateStartup($startup: startup_set_input, $id: uuid) {
    update_startup(_set: $startup, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const MUTATION_DELETE_STARTUP = gql`
  mutation DeleteStartup($id: uuid) {
    delete_startup(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const MUTATION_INSERT_ASSOCIATION = gql`
  mutation AddAssociation($association: association_insert_input!) {
    insert_association(objects: [$association]) {
      affected_rows
    }
  }
`;

export const MUTATION_UPDATE_ASSOCIATION = gql`
  mutation UpdateAssociation($association: association_set_input, $id: uuid) {
    update_association(_set: $association, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const MUTATION_DELETE_ASSOCIATION = gql`
  mutation DeleteAssociation($id: uuid) {
    delete_association(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const MUTATION_UPDATE_STARTUP_TAG = gql`
  mutation updateStartupTag(
    $startupId: uuid
    $customStartupTags: [customStartupTag_insert_input!]!
  ) {
    delete_customStartupTag(where: { startupId: { _eq: $startupId } }) {
      affected_rows
    }
    insert_customStartupTag(objects: $customStartupTags) {
      affected_rows
    }
  }
`;
