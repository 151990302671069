import { Popconfirm, Typography } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from 'ui/button';
import CardLeftImage from 'ui/cardSection/cardLeftImage';
import Flex from 'ui/flex';
import MissionWatermark from 'ui/icons/missionWatermark';
import MetadataRow from 'ui/metadataRow';
import TagRow, { Tag } from 'ui/tagRow';
import styles from './index.module.scss';
import defaultMissionImage from './defaultMissionImage.svg';

const { Title, Text } = Typography;

interface MissionMetadata {
  icon?: React.ReactNode;
  label: React.ReactNode;
}

interface Props {
  className?: string;
  cover?: React.ReactNode;
  title?: string;
  subtitle?: string;
  metadatas: MissionMetadata[];
  tags?: Tag[];
  description?: string;
  buttonProps?: ButtonProps;
  variant?: 'primary' | 'secondary';
  cancelButtonProps?: PopconfirmProps;
  withWatermark?: boolean;
  returnLink?: string;
  returnLabel?: string;
}

export default function MissionCard({
  className,
  cover,
  title,
  subtitle,
  metadatas,
  tags,
  description,
  buttonProps,
  variant = 'primary',
  cancelButtonProps,
  withWatermark,
  returnLink,
  returnLabel,
}: Props) {
  if (variant === 'primary') {
    return (
      <CardLeftImage
        cover={cover || <img alt={title} src={defaultMissionImage} />}
        className={className}
        watermark={withWatermark ? <MissionWatermark /> : undefined}
      >
        {cancelButtonProps ? (
          <div className={styles.titleConfirmContainer}>
            <Title level={2}>{title}</Title>
            <Popconfirm {...cancelButtonProps} />
          </div>
        ) : (
          <Title level={2}>{title}</Title>
        )}

        <Text className={styles.subtitle}>{subtitle}</Text>
        <MetadataRow metadatas={metadatas} />
        <TagRow tags={tags} />
        <Text className={styles.description}>{description}</Text>
        <Button className={styles.buttonPrimary} {...buttonProps} />
      </CardLeftImage>
    );
  }

  return (
    <Flex
      column
      alignItems="center"
      justify="space-between"
      className={className}
    >
      {returnLink && returnLabel && (
        <Button type="default" className={styles.returnButton}>
          <Link to={returnLink}>{returnLabel}</Link>
        </Button>
      )}
      <Title className={styles.title} level={2}>
        {title}
      </Title>
      <Text className={styles.subtitle}>{subtitle}</Text>
      <MetadataRow className={styles.metadataWider} metadatas={metadatas} />
      <TagRow tags={tags} />
      <Button className={styles.buttonSecondary} {...buttonProps} />
    </Flex>
  );
}
