import React from 'react';
import styles from './index.module.scss';

const TextIcon = ({ icon, label }: any) => (
  <div>
    <span className={styles.icon}>{icon}</span>
    {label}
  </div>
);

export default TextIcon;
