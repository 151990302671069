import { useLazyQuery } from '@apollo/react-hooks';
import BaseContent from 'business/layout/baseContent';
import ConnectedPage from 'business/layout/connectedPage';
import {
  QUERY_MISSIONS_SEARCH,
  QUERY_REMOTE_MISSIONS_SEARCH,
} from 'business/mission/services/query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from 'ui/flex';
import styles from './index.module.scss';
import Results from './results';
import SearchBox from './SearchBox';
import { getMissionSearchCriteriaCache } from './services/missionSearchCriteria';
import { sortMissions } from './services/sortMissions';
import { MissionQueryResults, QueryType } from './type';

function SearchMissionPage() {
  const { t } = useTranslation();

  const missionSearchCriteriaCache = getMissionSearchCriteriaCache();
  const [queryType, setQueryType] = useState<QueryType>(
    missionSearchCriteriaCache && missionSearchCriteriaCache.onlyRemote
      ? QueryType.ONLY_REMOTE
      : QueryType.WITH_LOCATION,
  );
  const [tagIds, setTagIds] = useState<string[]>([]);

  const [
    getMissionsWithLocation,
    {
      error: missionError,
      loading: missionLoading,
      data: missionData,
      called: missionQueryCalled,
    },
  ] = useLazyQuery<MissionQueryResults>(QUERY_MISSIONS_SEARCH);

  const [
    getRemoteMissions,
    {
      error: remoteMissionError,
      loading: remoteMissionLoading,
      data: remoteMissionData,
      called: remoteQueryCalled,
    },
  ] = useLazyQuery<MissionQueryResults>(QUERY_REMOTE_MISSIONS_SEARCH);

  let getMissions = getMissionsWithLocation;

  let resultsProps = {
    error: missionError,
    loading: missionLoading,
    data: missionData,
    called: missionQueryCalled,
  };

  switch (queryType) {
    default:
    case QueryType.WITH_LOCATION:
      resultsProps = {
        error: missionError,
        loading: missionLoading,
        data: missionData,
        called: missionQueryCalled,
      };
      break;
    case QueryType.ONLY_REMOTE:
      resultsProps = {
        error: remoteMissionError,
        loading: remoteMissionLoading,
        data: remoteMissionData,
        called: remoteQueryCalled,
      };
      getMissions = getRemoteMissions;
      break;
  }

  // Sort missions by number of corresponding tag desc
  resultsProps.data = {
    mission: sortMissions(resultsProps.data?.mission || [], tagIds),
  };

  return (
    <ConnectedPage>
      <BaseContent title={t('mission.search.title')} container="wide">
        <Flex className={styles.container} column>
          <SearchBox
            queryType={queryType}
            getMissions={getMissions}
            setQueryType={setQueryType}
            setTagIds={setTagIds}
          />
          {resultsProps.called && <Results {...resultsProps} />}
        </Flex>
      </BaseContent>
    </ConnectedPage>
  );
}

export default SearchMissionPage;
