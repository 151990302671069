import { Steps as StepsAntd } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Description from '../description';
import { EnrolmentSteps } from '../stepPage';
import styles from './index.module.scss';

const { Step } = StepsAntd;

interface Props {
  step: EnrolmentSteps;
}

export default function Steps({ step }: Props) {
  const { t } = useTranslation();

  let description = '';
  switch (step) {
    case EnrolmentSteps.EXPERIENCES:
      description = t('enrolment.description');
      break;
    default:
      break;
  }

  return (
    <div>
      {step !== EnrolmentSteps.SUMMARY && (
        <div className={styles.steps}>
          {description && (
            <Description
              className={styles.description}
              description={description}
            />
          )}

          <StepsAntd current={step}>
            <Step title={t('enrolment.experiences.stepTitle')} />
            <Step title={t('enrolment.project.stepTitle')} />
            <Step title={t('enrolment.competences.stepTitle')} />
            <Step title={t('enrolment.preferences.stepTitle')} />
          </StepsAntd>
        </div>
      )}
    </div>
  );
}
