import { Tag as AntTag } from 'antd';
import React from 'react';
import styles from './index.module.scss';

export interface Tag {
  label: string;
  onClick?: () => void;
}

interface Props {
  tags?: Tag[];
}

export default function TagRow({ tags }: Props) {
  return (
    <div>
      {tags?.map((tag: Tag) => (
        <AntTag key={tag.label} className={styles.tag} onClick={tag.onClick}>
          {tag.label}
        </AntTag>
      ))}
    </div>
  );
}
