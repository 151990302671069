import gql from 'graphql-tag';
import { Tag } from './tag';

interface Tags {
  tag: Tag;
}

export interface MissionPreview {
  id: string;
  name: string;
  activityArea: string;
  typology: string;
  duration: number;
  durationType: string;
  address: string;
  isFulltime: boolean;
  shortDescription: string;
  portraitPicture: string;
  startDate: string | null;
  tags: Tags[];
  isRemote: boolean;
}

export interface Mission {
  id: string;
  name: string;
  address: string;
  activityArea: string;
  typology: string;
  duration: number;
  durationType: string;
  isFulltime: boolean;
  description: string;
  landscapePicture: string;
  startDate: string;
  isRemote: boolean;
  tags: Tags[];
}

export interface MissionAggregate {
  aggregate: { count: number };
  nodes: MissionPreview[];
}

export const MissionPreviewFragment = gql`
  fragment MissionPreview on mission {
    id
    name
    activityArea
    typology
    address
    duration
    durationType
    startDate
    isFulltime
    shortDescription
    portraitPicture
    isRemote
    tags {
      tag {
        id
        name
      }
    }
  }
`;

export const MissionFragment = gql`
  fragment Mission on mission {
    id
    name
    address
    activityArea
    typology
    address
    duration
    durationType
    isFulltime
    description
    landscapePicture
    startDate
    isRemote
    tags {
      tag {
        id
        name
      }
    }
  }
`;

export const MissionAggregateFragment = gql`
  fragment MissionAggregate on mission_aggregate {
    aggregate {
      count
    }
    nodes {
      ...MissionPreview
    }
  }
  ${MissionPreviewFragment}
`;
