import { useMutation } from '@apollo/react-hooks';
import { Col, Empty, Form, Input, message, Row } from 'antd';
import { useAppContext } from 'business/provider';
import { MUTATION_UPDATE_USER_INFO } from 'business/user/services/query';
import classNames from 'classnames';
import config from 'config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from 'technical/graphql/fragments';
import logger from 'technical/logger';
import { getAvatarFilePath } from 'technical/url/formatter';
import { MAX_TEXT_AREA_CHAR, requiredRule } from 'technical/validation/rules';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import AvatarUpload from 'ui/form/avatarUpload';
import styles from './index.module.scss';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

interface Props {
  user: User;
  goNextStep: () => void;
}

interface FormValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  picture?: string;
  linkedInLink?: string;
  description?: string;
}

const EditProfile = ({ user, goNextStep }: Props) => {
  const { t } = useTranslation();
  const { requestRebootstrap } = useAppContext();
  const [avatar, setAvatar] = useState<{ name: string; loading: boolean }>({
    name: user.picture,
    loading: false,
  });
  const [updateUserInfo, { error: mutationError }] = useMutation(
    MUTATION_UPDATE_USER_INFO,
  );

  const onFinish = async ({
    firstName,
    lastName,
    email,
    phone,
    linkedInLink,
    description,
  }: FormValues) => {
    try {
      await updateUserInfo({
        variables: {
          firstName,
          lastName,
          picture: avatar?.name || '',
          userId: user.id,
          email,
          phone,
          linkedInLink: linkedInLink || '',
          description,
        },
      });
      if (user.firstName !== firstName) {
        // Refetch User is firstName change to change the header
        requestRebootstrap();
      }
    } catch (err) {
      logger.error(err);
      return;
    }
    goNextStep();
  };

  if (mutationError) {
    message.error(t('errors.mutation'));
  }

  if (!user) {
    message.error(t('errors.query'));
    return <Empty />;
  }

  return (
    <Form
      {...layout}
      onFinish={onFinish}
      initialValues={{
        avatar: user.picture
          ? [
              {
                uid: '-1',
                name: user.picture,
                status: 'done',
                url: `${config.gcp.bucketBaseUrl}/${getAvatarFilePath()}/${
                  user.picture
                }`,
              },
            ]
          : [],
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
        phone: user.phone || '',
        linkedInLink: user.linkedInLink || '',
        description: user.description || '',
      }}
      className={classNames(styles.editPage, 'fullWidth')}
    >
      <Row justify="center">
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('user.profile.editValidate')}
          </Button>
        </Form.Item>
      </Row>
      <CardSection
        label={t('enrolment.summary.stepTitle')}
        className={styles.editCardSection}
      >
        <Row>
          <Col offset={11} span={2}>
            <Form.Item name="avatar">
              <AvatarUpload
                userId={user.id}
                avatar={avatar}
                setAvatar={setAvatar}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={5} span={6}>
            <Form.Item
              name="firstName"
              label={t('enrolment.summary.fieldsLabel.firstName')}
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={2} span={6}>
            <Form.Item
              name="lastName"
              label={t('enrolment.summary.fieldsLabel.lastName')}
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={5} span={6}>
            <Form.Item
              name="email"
              label={t('enrolment.summary.fieldsLabel.email')}
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col offset={2} span={6}>
            <Form.Item
              name="phone"
              label={t('enrolment.summary.fieldsLabel.phone')}
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={5} span={6}>
            <Form.Item
              name="linkeInLink"
              label={t('enrolment.summary.fieldsLabel.linkedInLink')}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={5} span={14}>
            <Form.Item
              name="description"
              label={t('enrolment.summary.fieldsLabel.description')}
              rules={[requiredRule]}
            >
              <Input.TextArea maxLength={MAX_TEXT_AREA_CHAR} showCount />
            </Form.Item>
          </Col>
        </Row>
      </CardSection>
      <Row justify="center">
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('user.profile.editValidate')}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default EditProfile;
