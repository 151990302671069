const layout = {
  header: {
    type: 'full', // "standard", "full"
    menuSections: [
      {
        label: 'Accueil',
        path: '/home',
      },
    ],
  },
};

export default layout;
