import { CheckCircleOutlined } from '@ant-design/icons';
import ConnectedPage from 'business/layout/connectedPage';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayCard from 'ui/displayCard';
import Flex from 'ui/flex';
import config from 'config';
import { useAppContext } from 'business/provider';
import commonStyles from './index.module.scss';
import { User } from '../provider/user';

if (!process.env.REACT_APP_ENROLMENT_FINISH_ACTION) {
  throw new Error('Set REACT_APP_ENROLMENT_FINISH_ACTION');
}

declare global {
  // Calendly function has been added to global in index.html
  interface Calendly {
    initPopupWidget: (meta: any) => void;
  }
}

function computePrefilledCalendlyUrl(user?: User) {
  if (!user) {
    return config.contact.calendlyUrl;
  }

  const targetPhone = user.phone[0] === '+' ? user.phone : `+33${user.phone}`;

  return `${config.contact.calendlyUrl}?email=${user.email}&full_name=${user.firstName}&location=${targetPhone}`;
}

const ValidatingUserPage = () => {
  const { t } = useTranslation();
  const { user } = useAppContext();

  return (
    <ConnectedPage>
      <Flex
        className={commonStyles.container}
        justify="center"
        alignItems="center"
      >
        <DisplayCard
          className={commonStyles.displayCard}
          image={<CheckCircleOutlined />}
          title={t('user.validating.title')}
          subtitle={t('user.validating.subtitle')}
          actionButtonProps={{
            onClick: e => {
              e.preventDefault();
              // On a pas eu le temps de faire l'integration de la variable
              // globale Calendly mais le script fonctionne
              // @ts-ignore
              Calendly.initPopupWidget({
                url: computePrefilledCalendlyUrl(user),
              });
            },
            type: 'primary',
            children: t('user.validating.calendly'),
          }}
          callToAction={{
            link: process.env.REACT_APP_ENROLMENT_FINISH_ACTION!,
            children: t('user.validating.callToAction'),
          }}
        />
      </Flex>
    </ConnectedPage>
  );
};

export default ValidatingUserPage;
