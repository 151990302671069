import gql from 'graphql-tag';

export const GET_PRIVATE_BUCKET_UPLOAD_URL = gql`
  mutation createUploadUrl($fileName: String!, $folderName: String) {
    createFileUploadTempUrl(fileName: $fileName, folderName: $folderName) {
      fileName
      url
    }
  }
`;
export const GET_PRIVATE_BUCKET_DELETE_URL = gql`
  mutation createDeleteUrl($fileName: String!) {
    createFileDeleteTempUrl(fileName: $fileName) {
      url
    }
  }
`;

export const GET_PUBLIC_BUCKET_UPLOAD_URL = gql`
  mutation createPublicUploadUrl($fileName: String!, $folderName: String) {
    createPublicFileUploadTempUrl(
      fileName: $fileName
      folderName: $folderName
    ) {
      fileName
      url
    }
  }
`;
export const GET_PUBLIC_BUCKET_DELETE_URL = gql`
  mutation createPublicDeleteUrl($fileName: String!, $isAvatar: Boolean) {
    createPublicFileDeleteTempUrl(fileName: $fileName, isAvatar: $isAvatar) {
      url
    }
  }
`;
