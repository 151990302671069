import { useAppContext } from 'business/provider';
import React from 'react';
import Footer from 'ui/footer';
import { footerConfig } from './footerDefaultConfig';
import Page from './page';
import getMenuSections from './services/getMenuSections';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  img?: { src: string; alt: string };
  footer?: React.ReactNode;
  noFooter?: boolean;
}

function ConnectedPage({ noFooter, ...rest }: Props) {
  const { isConnected, user } = useAppContext();
  const displayedMenuSections = getMenuSections(user);

  return (
    <Page
      {...rest}
      isConnected={isConnected}
      displayedMenuSections={displayedMenuSections}
      footer={!noFooter && <Footer {...footerConfig} />}
    />
  );
}

export default ConnectedPage;
