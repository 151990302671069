import { SmileOutlined } from '@ant-design/icons';
import ConnectedPage from 'business/layout/connectedPage';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DisplayCard from 'ui/displayCard';
import Flex from 'ui/flex';
import { logout } from '../services/authentication';
import commonStyles from './index.module.scss';

const RefusedUserPage = () => {
  const { t } = useTranslation();

  return (
    <ConnectedPage>
      <Flex
        className={commonStyles.container}
        justify="center"
        alignItems="center"
      >
        <DisplayCard
          className={commonStyles.displayCard}
          image={<SmileOutlined />}
          title={t('Votre candidature à été mise en attente')}
          subtitle={t(
            'Ça ne sera pas pour cette fois, mais continue de monter en compétence et reviens vers nous !',
          )}
          actionButtonProps={{
            onClick: logout,
            children: 'Me déconnecter et retenter ma chance plus tard',
          }}
        />
      </Flex>
    </ConnectedPage>
  );
};

export default RefusedUserPage;
