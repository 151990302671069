import gql from 'graphql-tag';

export const MUTATION_CREATE_MAILJET_USER = gql`
  mutation CreateMailjetUser(
    $email: String!
    $firstName: String
    $lastName: String
    $phone: String
  ) {
    createMailjetUser(
      object: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
      }
    ) {
      responseCode
      success
    }
  }
`;

export const MUTATION_UPDATE_MAILJET_USER = gql`
  mutation UpdateMailjetUser(
    $email: String!
    $firstName: String
    $lastName: String
    $phone: String
    $location: String
    $linkedin: String
    $contacted: Boolean
    $skills: String
    $sponsorLink: String
    $contractType: String
    $adr: Int
    $remote: Boolean
    $startup: String
    $association: String
    $startupEndDate: String
    $enrolmentFinished: Boolean
    $certifications: String
    $startupRole: String
    $languages: String
  ) {
    updateMailjetUser(
      object: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        location: $location
        linkedin: $linkedin
        contacted: $contacted
        skills: $skills
        sponsorLink: $sponsorLink
        contractType: $contractType
        adr: $adr
        remote: $remote
        startup: $startup
        association: $association
        startupEndDate: $startupEndDate
        enrolmentFinished: $enrolmentFinished
        startupRole: $startupRole
        certifications: $certifications
        languages: $languages
      }
    ) {
      responseCode
      success
    }
  }
`;
