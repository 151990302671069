import { useApolloClient } from '@apollo/react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { setUserId } from 'technical/analytics';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { isAuthenticated, isAuthInitialized } from '../services/authentication';
import getUserAndCreateIfNeeded from '../services/user';

export interface User {
  id: string;
  authId: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  isEnrolmentFinished: boolean;
  isValidated: boolean | null;
  isValidating: boolean;
  isUserLoading: boolean;
  phone: string;
}

function useUserData() {
  const [isBootstraped, setIsBootstraped] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const client = useApolloClient();

  // @todo use subscription for user data when below error is handled (cf this commit to "revert" code)
  // BEWARE: on invalid JWT, subscriptions are automatically disconnected and not re-subscribed
  // Error: cannot start as connection_init failed with : Malformed Authorization header

  const fetchAndStoreUser = useCallback(
    async function refetchUser() {
      const targetUser = await getUserAndCreateIfNeeded(client);

      setUser(targetUser);
    },
    [client],
  );

  /**
   * Fonction to be called if needed typcially when authentication status changes (ie - was not connected and now am connected)
   */
  const requestRebootstrap = useCallback(
    function requestRebootstrap() {
      return isAuthInitialized
        .then(async () => {
          if (!isAuthenticated()) {
            return;
          }
          // try to get my user
          await fetchAndStoreUser();
        })
        .catch(err => {
          logger.error(err);
          errorReporting.warning(err);
          throw err; // @todo @robin to throw or not to throw
        })
        .finally(() => {
          return setIsBootstraped(true);
        });
    },
    [fetchAndStoreUser],
  );

  useEffect(() => {
    requestRebootstrap();
  }, [client, requestRebootstrap]);

  useEffect(() => {
    setUserId(user?.id || undefined);
    if (user) {
      errorReporting.setUser({
        id: user.id,
        email: user.email,
      });
    } else {
      errorReporting.removeUser();
    }
  }, [user]);

  return {
    user,
    isConnected: !!user,
    isBootstraped,
    requestRebootstrap,
    refreshUser: fetchAndStoreUser,
  };
}

export default useUserData;
