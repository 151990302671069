import {
  AlignLeftOutlined,
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import { User } from 'business/user/provider/user';
import { login, logout, signUp } from 'business/user/services/authentication';
import classnames from 'classnames';
import colors from 'config/colors';
import MainRoutes from 'config/routes';
import React, { useState } from 'react';
import history from 'technical/history';
import AppLogo from 'ui/appLogo';
import MenuLink from 'ui/menu/link';
import LogoSection from 'ui/navBar/components/logoSection';
import MenuModal from '../menuModal';
import styles from './index.module.scss';

const { Header } = Layout;

interface Props {
  isConnected?: boolean;
  displayedMenuSections?: {
    label: string;
    path: string;
  }[];
  activeSectionPath: string;
  user: User | undefined;
}

const iconStyle = { fontSize: '32px', lineHeight: 0 };

export default function MobileNavBar({
  isConnected,
  displayedMenuSections,
}: Props) {
  const [menuModalIsOpen, setMenuModalIsOpen] = useState(false);

  function renderToggleMenuModal(isOpen?: boolean) {
    return () => {
      setMenuModalIsOpen(
        typeof isOpen === 'undefined' ? !menuModalIsOpen : Boolean(isOpen),
      );
    };
  }

  const displayMenuSections =
    displayedMenuSections && displayedMenuSections.length > 0;

  return (
    <Header className={classnames(styles.header, 'elevation2')}>
      <div className={styles.headerContent}>
        {displayMenuSections &&
          (displayedMenuSections && displayedMenuSections.length === 1 ? (
            <a href={displayedMenuSections[0].path}>
              <HomeOutlined style={{ fontSize: 32, color: colors.black }} />
            </a>
          ) : (
            <div className={styles.headerLeft}>
              <MenuModal
                modalIsOpen={menuModalIsOpen}
                closeModal={renderToggleMenuModal(false)}
                navSections={displayedMenuSections || []}
              />
              <LogoSection
                onClick={renderToggleMenuModal()}
                icon={<AlignLeftOutlined style={iconStyle} />}
              />
            </div>
          ))}
        <AppLogo height="60px" />

        {isConnected ? (
          <Dropdown
            className={styles.headerRight}
            trigger={['click']}
            overlay={
              <>
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      history.push(MainRoutes.Profile);
                    }}
                  >
                    <MenuLink>Mon Profil</MenuLink>
                  </Menu.Item>
                </Menu>
                <Menu>
                  <Menu.Item onClick={logout}>
                    <MenuLink>Se déconnecter</MenuLink>
                  </Menu.Item>
                </Menu>
              </>
            }
          >
            <div className="ant-dropdown-link">
              <LogoSection
                onClick={() => {}}
                icon={<UserOutlined style={iconStyle} />}
              />
            </div>
          </Dropdown>
        ) : (
          <div className={styles.headerRight}>
            <Button onClick={login}>Se connecter</Button>
            <Button
              className={styles.primaryButton}
              onClick={() => signUp()}
              type="primary"
            >
              S&apos;inscrire
            </Button>
          </div>
        )}
      </div>
    </Header>
  );
}
