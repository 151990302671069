import gql from 'graphql-tag';

export interface UserLanguage {
  id: string;
  userId: string;
  name: string;
}

export const UserLanguageFragment = gql`
  fragment UserLanguage on userLanguage {
    id
    userId
    name
  }
`;

export interface PublicUserLanguage {
  id: string;
  name: string;
}

export const PublicUserLanguageFragment = gql`
  fragment PublicUserLanguage on userLanguage {
    id
    name
  }
`;
