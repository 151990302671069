import { useQuery } from '@apollo/react-hooks';
import { Empty, message } from 'antd';
import BaseContent from 'business/layout/baseContent';
import ConnectedPage from 'business/layout/connectedPage';
import { PublicQueryResults } from 'business/user/services/type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Loader from 'ui/loader';
import { Helmet } from 'react-helmet';
import { QUERY_PUBLIC_USER } from '../services/query';
import ProfileContent from './profileContent';
import styles from './index.module.scss';

function PublicProfilePage() {
  const { t } = useTranslation();
  const { userId } = useParams();

  const { data, loading, error: queryError } = useQuery<PublicQueryResults>(
    QUERY_PUBLIC_USER,
    {
      variables: { userId },
      context: {
        headers: {
          authorization: null,
          'x-hasura-target-user': userId,
        },
      },
    },
  );

  let title = t('publicUser.genericTitle');
  let content = <Loader />;

  if (loading) {
    content = <Loader />;
  }

  if (queryError || !userId) {
    message.error(t('errors.query'));
    content = <Empty description={t('publicUser.errors.userNotFound')} />;
  }

  if (data && data.user_by_pk) {
    content = <ProfileContent user={data.user_by_pk} isPublic />;
    title = t('publicUser.title', {
      username: `${data.user_by_pk.firstName}`,
    });
  } else {
    content = <Empty description={t('publicUser.errors.invalidUser')} />;
  }

  return (
    <ConnectedPage>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BaseContent
        title={title}
        container="wide"
        className={styles.profileContainer}
      >
        {content}
      </BaseContent>
    </ConnectedPage>
  );
}

export default PublicProfilePage;
