import gql from 'graphql-tag';
import { MissionAggregateFragment } from 'technical/graphql/fragments';
import { MissionApplicationAggregateFragment } from 'technical/graphql/fragments/missionApplication';

export const QUERY_ALL_USER_MISSIONS = gql`
  query GetAllUserMissions($userId: uuid!) {
    applications: missionApplication_aggregate(
      where: {
        userId: { _eq: $userId }
        candidateDate: { _is_null: false }
        selectionDate: { _is_null: true }
        cancelDate: { _is_null: true }
        refusalDate: { _is_null: true }
        mission: { status: { _eq: "application" } }
      }
    ) {
      ...MissionApplicationAggregate
    }

    selections: missionApplication_aggregate(
      where: {
        userId: { _eq: $userId }
        candidateDate: { _is_null: false }
        selectionDate: { _is_null: false }
        cancelDate: { _is_null: true }
        refusalDate: { _is_null: true }
        mission: { status: { _in: ["application", "selection"] } }
      }
    ) {
      ...MissionApplicationAggregate
    }
    activeMissions: mission_aggregate(
      where: { userId: { _eq: $userId }, status: { _eq: "active" } }
    ) {
      ...MissionAggregate
    }

    finishedMissions: mission_aggregate(
      where: { userId: { _eq: $userId }, status: { _eq: "finished" } }
    ) {
      ...MissionAggregate
    }
  }
  ${MissionApplicationAggregateFragment}
  ${MissionAggregateFragment}
`;

export const MUTATION_CANCEL_APPLICATION = gql`
  mutation CancelApplication(
    $userId: uuid!
    $missionId: uuid!
    $today: timestamptz
  ) {
    update_missionApplication(
      where: { missionId: { _eq: $missionId }, userId: { _eq: $userId } }
      _set: { cancelDate: $today }
    ) {
      affected_rows
    }
  }
`;
