import { Typography } from 'antd';
import React from 'react';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import Button, { ButtonProps } from 'ui/button';
import classnames from 'classnames';
import styles from './index.module.scss';

const { Text } = Typography;

interface Props {
  className?: string;
  title: string;
  ctaBtnProps: ButtonProps;
}

export default function CtaCard({ className, title, ctaBtnProps }: Props) {
  return (
    <CardSection className={classnames(styles.container, className)}>
      <Flex column alignItems="center">
        <Text className={styles.text}>{title}</Text>
        <Button {...ctaBtnProps} />
      </Flex>
    </CardSection>
  );
}
