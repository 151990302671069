import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { MUTATION_UPDATE_MAILJET_USER } from 'business/mailjet/query';
import { useAppContext } from 'business/provider';
import { TFunction } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Association } from 'technical/graphql/fragments/association';
import { Startup } from 'technical/graphql/fragments/startup';
import logger from 'technical/logger';
import { useMediaType } from 'technical/media/hooks';
import { normalizeUrl } from 'technical/string/formatter';
import {
  dateFormat,
  limitedInputLength,
  limitedTextAreaLength,
  MAX_TEXT_AREA_CHAR,
  notBeforeStartDate,
  positiveNumberRule,
  requiredErrorAlert,
  requiredRule,
  urlValidation,
} from 'technical/validation/rules';
import Button from 'ui/button';
import CardSection from 'ui/cardSection';
import Flex from 'ui/flex';
import Loader from 'ui/loader';
import commonStyles from '../index.module.scss';
import styles from './index.module.scss';
import {
  MUTATION_DELETE_ASSOCIATION,
  MUTATION_DELETE_STARTUP,
  MUTATION_INSERT_ASSOCIATION,
  MUTATION_INSERT_STARTUP,
  MUTATION_UPDATE_ASSOCIATION,
  MUTATION_UPDATE_STARTUP,
  MUTATION_UPDATE_STARTUP_TAG,
  QUERY_STARTUP_AND_ASSOCIATION,
} from './query';
import { FormValues, QueryResult } from './type';

const { Text } = Typography;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const fullWidth = { style: { width: '100%' } };

interface Props {
  goNextStep: () => void;
  goToPreviousStep?: () => void;
  nextButtonContent?: string | TFunction;
  scrollToAssociation?: boolean;
}

const isSectionFilled = (values?: Startup | Association) => {
  if (!values) {
    return false;
  }
  return Object.entries(values).some(([key, value]) => {
    if (['id', 'userId', '__typename'].includes(key)) {
      return false;
    }
    if (value) {
      if (Array.isArray(value) && value.length < 1) {
        return false;
      }
      return true;
    }
    return false;
  });
};

const formatInitialStartup = ({
  startDate,
  endDate,
  technologies,
  targetClient,
  ...startup
}: Startup) => ({
  ...startup,
  startDate: startDate ? moment(startDate) : null,
  endDate: endDate ? moment(endDate) : null,
  technologies: technologies || [],
  targetClient: targetClient || '',
});

function ProjectPage({
  goNextStep,
  goToPreviousStep,
  nextButtonContent,
  scrollToAssociation,
}: Props) {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const { isMobile } = useMediaType();
  const client = useApolloClient();
  const [form] = Form.useForm();
  const [customTagList, setCustomTagList] = useState<string[]>([]);
  const [updateStartupTags] = useMutation(MUTATION_UPDATE_STARTUP_TAG);
  // We don't use ref here because we want to trigger an action when the component is mounted
  // https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
  const [associationRef, setAssociationRef] = useState<HTMLDivElement | null>(
    null,
  );

  const userId = user?.id;

  const { data, error: queryError, loading } = useQuery<QueryResult>(
    QUERY_STARTUP_AND_ASSOCIATION,
    { variables: { userId } },
  );
  const [createStartup, { error: createStartupError }] = useMutation<{
    insert_startup: { affected_rows: number; returning: Startup[] };
  }>(MUTATION_INSERT_STARTUP);
  const [updateStartup, { error: updateStartupError }] = useMutation(
    MUTATION_UPDATE_STARTUP,
  );
  const [deleteStartup, { error: deleteStartupError }] = useMutation(
    MUTATION_DELETE_STARTUP,
  );
  const [createAssociation, { error: createAssociationError }] = useMutation(
    MUTATION_INSERT_ASSOCIATION,
  );
  const [updateAssociation, { error: updateAssociationError }] = useMutation(
    MUTATION_UPDATE_ASSOCIATION,
  );
  const [deleteAssociation, { error: deleteAssociationError }] = useMutation(
    MUTATION_DELETE_ASSOCIATION,
  );

  useEffect(() => {
    if (scrollToAssociation && associationRef) {
      window.scrollTo(0, associationRef.offsetTop);
    } else {
      window.scrollTo(0, 0);
    }
  }, [scrollToAssociation, associationRef]);

  const onFinish = async ({ startup, association }: FormValues) => {
    try {
      if (isSectionFilled(startup)) {
        let startupId: string | undefined;

        if (data && data.startup.length > 0) {
          await updateStartup({
            variables: {
              startup: { ...startup, url: normalizeUrl(startup?.url) },
              id: data.startup[0].id,
            },
          });

          startupId = data.startup[0].id;
        } else {
          const result = await createStartup({
            variables: {
              startup: { ...startup, url: normalizeUrl(startup?.url), userId },
            },
          });

          startupId = result.data?.insert_startup.returning[0].id;
        }

        await updateStartupTags({
          variables: {
            startupId,
            customStartupTags: customTagList.map(customStartupTag => ({
              startupId,
              userId,
              name: customStartupTag,
            })),
          },
        });

        // Update Mailjet user
        await client.mutate({
          mutation: MUTATION_UPDATE_MAILJET_USER,
          variables: {
            email: user?.email,
            startup: startup?.name,
            startupEndDate: startup?.endDate,
            startupRole: startup?.role,
          },
        });
      } else if (data && data.startup.length > 0) {
        await deleteStartup({ variables: { id: data.startup[0].id } });
        // Update Mailjet user
        await client.mutate({
          mutation: MUTATION_UPDATE_MAILJET_USER,
          variables: {
            email: user?.email,
            startup: '',
            startupEndDate: '',
            startupRole: '',
          },
        });
      }

      if (isSectionFilled(association)) {
        if (data && data.association.length > 0) {
          await updateAssociation({
            variables: {
              association: {
                ...association,
                url: normalizeUrl(association?.url),
              },
              id: data.association[0].id,
            },
          });
        } else {
          await createAssociation({
            variables: {
              association: {
                ...association,
                url: normalizeUrl(association?.url),
                userId,
              },
            },
          });
        }
        // Update Mailjet user
        await client.mutate({
          mutation: MUTATION_UPDATE_MAILJET_USER,
          variables: {
            email: user?.email,
            association: association?.name,
          },
        });
      } else if (data && data.association.length > 0) {
        await deleteAssociation({ variables: { id: data.association[0].id } });
        // Update Mailjet user
        await client.mutate({
          mutation: MUTATION_UPDATE_MAILJET_USER,
          variables: {
            email: user?.email,
            association: '',
          },
        });
      }
    } catch (err) {
      logger.error(err);
      return;
    }
    goNextStep();
  };

  function handleChange(value: any) {
    if (data && value) {
      const selectedCustomTags = value
        // Remove data.tag elements in order to only keep custom tags
        .filter(
          (tagName: string) =>
            !data.tag.map(tagElement => tagElement.name).includes(tagName),
        );
      setCustomTagList(selectedCustomTags);
    }
  }

  if (loading || !userId) {
    return <Loader />;
  }

  if (queryError) {
    message.warning(t('errors.query'));
  }

  if (
    createStartupError ||
    createAssociationError ||
    updateStartupError ||
    updateAssociationError ||
    deleteStartupError ||
    deleteAssociationError
  ) {
    message.error(t('errors.mutation'));
  }

  return (
    <Flex className={commonStyles.container} column alignItems="center">
      <Form
        {...layout}
        className="fullWidth"
        form={form}
        onFinish={onFinish}
        onFinishFailed={requiredErrorAlert}
        initialValues={{
          startup:
            data && data.startup.length > 0
              ? formatInitialStartup(data.startup[0])
              : {},
          association:
            data && data.association.length > 0 ? data.association[0] : {},
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          className={commonStyles.stickySubmitButton}
          style={{ marginBottom: '16px' }}
        >
          {nextButtonContent || t('enrolment.submit_button')}
        </Button>
        <Row justify="center">
          {goToPreviousStep && (
            <Text className={styles.infoText}>
              {t('enrolment.project.info')}
            </Text>
          )}
        </Row>
        <Row justify="center">
          {goToPreviousStep && (
            <Flex column>
              <Button
                onClick={goToPreviousStep}
                className={commonStyles.previousButton}
                type="ghost"
              >
                {t('enrolment.previous_button')}
              </Button>
              <Button onClick={goNextStep} type="link">
                {t('enrolment.skip_button')}
              </Button>
            </Flex>
          )}
        </Row>
        <CardSection
          className={commonStyles.cardSection}
          label={t('enrolment.project.startup.title')}
        >
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      {...layout}
                      name={['startup', 'name']}
                      label={t('enrolment.project.startup.fieldsLabel.name')}
                      rules={
                        isSectionFilled(getFieldValue('startup'))
                          ? [requiredRule, limitedInputLength]
                          : [limitedInputLength]
                      }
                    >
                      <Input />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name={['startup', 'role']}
                      label={t('enrolment.project.startup.fieldsLabel.role')}
                      rules={
                        isSectionFilled(getFieldValue('startup'))
                          ? [requiredRule, limitedInputLength]
                          : [limitedInputLength]
                      }
                    >
                      <Input />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
              <Form.Item
                name={['startup', 'url']}
                label={t('enrolment.project.startup.fieldsLabel.url')}
                rules={[urlValidation, limitedInputLength]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name={['startup', 'startDate']}
                      label={t(
                        'enrolment.project.startup.fieldsLabel.startDate',
                      )}
                      rules={
                        isSectionFilled(getFieldValue('startup'))
                          ? [requiredRule]
                          : undefined
                      }
                    >
                      <DatePicker
                        className={styles.fullWidth}
                        placeholder={t(
                          'enrolment.project.startup.fieldsLabel.selectDate',
                        )}
                        format={dateFormat}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
              <Form.Item
                name={['startup', 'endDate']}
                label={t('enrolment.project.startup.fieldsLabel.endDate')}
                rules={[notBeforeStartDate(['startup', 'startDate'])]}
              >
                <DatePicker
                  className={styles.fullWidth}
                  placeholder={t(
                    'enrolment.project.startup.fieldsLabel.optionalSelect',
                  )}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
              <Form.Item
                name={['startup', 'staff']}
                label={t('enrolment.project.startup.fieldsLabel.staff')}
                rules={[positiveNumberRule]}
              >
                <InputNumber {...fullWidth} />
              </Form.Item>
            </Col>
            <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
              <Form.Item
                name={['startup', 'sales']}
                label={t('enrolment.project.startup.fieldsLabel.sales')}
                rules={[positiveNumberRule]}
              >
                <InputNumber {...fullWidth} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
              <Form.Item
                name={['startup', 'clientCount']}
                label={t('enrolment.project.startup.fieldsLabel.clientCount')}
                rules={[positiveNumberRule]}
              >
                <InputNumber {...fullWidth} />
              </Form.Item>
            </Col>
            <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
              <Form.Item
                name={['startup', 'fundraising']}
                label={t('enrolment.project.startup.fieldsLabel.fundraising')}
                rules={[positiveNumberRule]}
              >
                <InputNumber {...fullWidth} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
              <Form.Item
                name={['startup', 'investmentFund']}
                label={t(
                  'enrolment.project.startup.fieldsLabel.investmentFund',
                )}
                rules={[limitedInputLength]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
              <Form.Item
                name={['startup', 'businessIncubator']}
                label={t(
                  'enrolment.project.startup.fieldsLabel.businessIncubator',
                )}
                rules={[limitedInputLength]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 14 })}>
              <Form.Item
                name={['startup', 'description']}
                label={t('enrolment.project.startup.fieldsLabel.description')}
                rules={[limitedTextAreaLength]}
              >
                <Input.TextArea maxLength={MAX_TEXT_AREA_CHAR} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 14 })}>
              <Form.Item
                name={['startup', 'technologies']}
                label={t('enrolment.project.startup.fieldsLabel.technologies')}
              >
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={t('enrolment.select_placeholder')}
                  onChange={handleChange}
                >
                  {data &&
                    [...data.tag, ...data.customStartupTag].map(
                      ({ id, name }) => (
                        <Select.Option key={id} value={name}>
                          {name}
                        </Select.Option>
                      ),
                    )}
                  {/* </Select> */}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...(isMobile ? fullWidth : { offset: 5, span: 14 })}>
              <Form.Item
                name={['startup', 'targetClient']}
                label={t('enrolment.project.startup.fieldsLabel.targetClient')}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </CardSection>
        <div
          data-id="test"
          ref={element => {
            setAssociationRef(element);
          }}
        >
          <CardSection
            className={commonStyles.cardSection}
            label={t('enrolment.project.association.title')}
          >
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 6 })}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        name={['association', 'name']}
                        label={t(
                          'enrolment.project.association.fieldsLabel.name',
                        )}
                        rules={[
                          isSectionFilled(getFieldValue('association'))
                            ? requiredRule
                            : {},
                          limitedInputLength,
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col {...(isMobile ? fullWidth : { offset: 2, span: 6 })}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        name={['association', 'job']}
                        label={t(
                          'enrolment.project.association.fieldsLabel.job',
                        )}
                        rules={[
                          isSectionFilled(getFieldValue('association'))
                            ? requiredRule
                            : {},
                          limitedInputLength,
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 14 })}>
                <Form.Item
                  name={['association', 'url']}
                  label={t('enrolment.project.association.fieldsLabel.url')}
                  rules={[limitedInputLength, urlValidation]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...(isMobile ? fullWidth : { offset: 5, span: 14 })}>
                <Form.Item
                  name={['association', 'description']}
                  label={t(
                    'enrolment.project.association.fieldsLabel.description',
                  )}
                  rules={[limitedTextAreaLength]}
                >
                  <Input.TextArea maxLength={MAX_TEXT_AREA_CHAR} showCount />
                </Form.Item>
              </Col>
            </Row>
          </CardSection>
        </div>
        <Row justify="center">
          <Form.Item>
            {goToPreviousStep && (
              <Button
                onClick={goToPreviousStep}
                className={commonStyles.previousButton}
                type="ghost"
              >
                {t('enrolment.previous_button')}
              </Button>
            )}
            <Button
              className={commonStyles.submitButton}
              type="primary"
              htmlType="submit"
            >
              {nextButtonContent || t('enrolment.submit_button')}
            </Button>
          </Form.Item>
        </Row>
        <Row justify="center">
          {goToPreviousStep && (
            <Button
              className={styles.skipButton}
              onClick={goNextStep}
              type="link"
            >
              {t('enrolment.skip_button')}
            </Button>
          )}
        </Row>
      </Form>
    </Flex>
  );
}

export default ProjectPage;
