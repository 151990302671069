import { CheckCircleOutlined } from '@ant-design/icons';
import ConnectedPage from 'business/layout/connectedPage';
import SponsorInput from 'business/sponsor/sponsorInput';
import MainRoutes from 'config/routes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import history from 'technical/history';
import DisplayCard from 'ui/displayCard';
import Flex from 'ui/flex';
import styles from './index.module.scss';

export default function SponsorLinkPage() {
  const { t } = useTranslation();

  const redirectToHomePage = () => {
    history.push(MainRoutes.SearchMission);
  };

  return (
    <ConnectedPage>
      <Flex className={styles.container} justify="center" alignItems="center">
        <DisplayCard
          className={styles.displayCard}
          image={<CheckCircleOutlined />}
          title={t('sponsor.link.title')}
          subtitle={t('sponsor.link.description')}
          actionButtonProps={{
            children: t('sponsor.link.actionButton'),
            type: 'primary',
            onClick: redirectToHomePage,
          }}
        >
          <SponsorInput />
        </DisplayCard>
      </Flex>
    </ConnectedPage>
  );
}
