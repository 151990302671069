import { Typography } from 'antd';
import React from 'react';
import AppLogo from 'ui/appLogo';
import Flex from 'ui/flex';
import Separator from 'ui/separator';
import styles from './index.module.scss';

const { Title, Paragraph, Text } = Typography;

interface ContactOptions {
  type: string;
  value: string;
}

interface Props {
  title?: string;
  appLogo?: boolean;
  contacts: ContactOptions[];
  credits?: string;
}

function ContactBlock({ title, appLogo, contacts, credits }: Props) {
  return (
    <Flex
      className={styles.container}
      column
      justify="center"
      alignItems="center"
    >
      <Flex column alignItems="center">
        {title && (
          <>
            <Title level={2}>{title}</Title>
            <Separator />
          </>
        )}
        {appLogo && <AppLogo white />}
      </Flex>
      <div className={styles.contactInfoContainer}>
        {contacts.map(({ type, value }) => (
          <Paragraph className={styles.contactInfo} key={`${type}-${value}`}>
            <em>
              {type}
              {` : `}
            </em>
            {value}
          </Paragraph>
        ))}
      </div>
      <Text className={styles.credits}>{credits}</Text>
    </Flex>
  );
}

export default ContactBlock;
