import { useQuery } from '@apollo/react-hooks';
import { Empty, message } from 'antd';
import CompetencesPage from 'business/enrolment/competencesPage';
import ExperiencesPage from 'business/enrolment/experiencesPage';
import PreferencesPage from 'business/enrolment/preferencesPage';
import ProjectPage from 'business/enrolment/projectPage';
import BaseContent from 'business/layout/baseContent';
import ConnectedPage from 'business/layout/connectedPage';
import { useAppContext } from 'business/provider';
import { QUERY_FULL_USER } from 'business/user/services/query';
import { QueryResults } from 'business/user/services/type';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'ui/loader';
import Button from 'ui/button';
import styles from './index.module.scss';
import ProfileContent from './profileContent';
import EditProfile from './profileContent/editProfile';
import { UserViewStatus } from '../types';

function UserProfilePage() {
  const { t } = useTranslation();
  const { user } = useAppContext();
  const [viewStatus, setViewStatus] = useState(UserViewStatus.PROFILE);
  const userId = user?.id;

  const { data, loading, error: queryError, refetch } = useQuery<QueryResults>(
    QUERY_FULL_USER,
    { variables: { userId } },
  );

  const profileFunctions = {
    editProfile: () => {
      setViewStatus(UserViewStatus.EDIT_PROFILE);
    },
    editPreferences: () => {
      setViewStatus(UserViewStatus.PREFERENCES);
    },
    editCompetences: () => {
      setViewStatus(UserViewStatus.COMPETENCES);
    },
    editStartup: () => {
      setViewStatus(UserViewStatus.PROJECT);
    },
    editAssociation: () => {
      setViewStatus(UserViewStatus.ASSOCIATION);
    },
    editExperiences: () => {
      setViewStatus(UserViewStatus.EXPERIENCES);
    },
    editDiplomasAndCertifications: () => {
      setViewStatus(UserViewStatus.DIPLOMAS_AND_CERTIF);
    },
    editLanguages: () => {
      setViewStatus(UserViewStatus.LANGUAGES);
    },
  };

  const nextStep = () => {
    setViewStatus(UserViewStatus.PROFILE);
    refetch();
  };

  if (loading || !userId) {
    return (
      <ConnectedPage>
        <BaseContent
          title={t('user.profile.title')}
          container="wide"
          transparent
        >
          <Loader />
        </BaseContent>
      </ConnectedPage>
    );
  }

  if (queryError) {
    message.error(t('errors.query'));
  }

  const handleReturn = () => {
    setViewStatus(UserViewStatus.PROFILE);
  };

  return (
    <ConnectedPage>
      <BaseContent title={t('user.profile.title')} container="wide">
        <div className={styles.profileContainer}>
          {viewStatus === UserViewStatus.PROFILE &&
            (data ? (
              <ProfileContent
                user={data.user_by_pk}
                {...profileFunctions}
                displaySponsor
              />
            ) : (
              <Empty />
            ))}

          {[
            UserViewStatus.EXPERIENCES,
            UserViewStatus.DIPLOMAS_AND_CERTIF,
            UserViewStatus.LANGUAGES,
          ].includes(viewStatus) && (
            <ExperiencesPage
              goNextStep={nextStep}
              nextButtonContent={t('user.profile.editValidate')}
              displayUpload={false}
              scrollToSection={viewStatus}
            />
          )}
          {[UserViewStatus.PROJECT, UserViewStatus.ASSOCIATION].includes(
            viewStatus,
          ) && (
            <ProjectPage
              goNextStep={nextStep}
              nextButtonContent={t('user.profile.editValidate')}
              scrollToAssociation={viewStatus === UserViewStatus.ASSOCIATION}
            />
          )}
          {viewStatus === UserViewStatus.COMPETENCES && (
            <CompetencesPage
              goNextStep={nextStep}
              nextButtonContent={t('user.profile.editValidate')}
            />
          )}
          {viewStatus === UserViewStatus.PREFERENCES && (
            <PreferencesPage
              goNextStep={nextStep}
              nextButtonContent={t('user.profile.editValidate')}
            />
          )}
          {viewStatus === UserViewStatus.EDIT_PROFILE &&
            (data ? (
              <EditProfile goNextStep={nextStep} user={data?.user_by_pk} />
            ) : (
              <Empty />
            ))}
          {viewStatus !== UserViewStatus.PROFILE && (
            <Button className={styles.returnButton} onClick={handleReturn}>
              {t('user.profile.returnButton')}
            </Button>
          )}
        </div>
      </BaseContent>
    </ConnectedPage>
  );
}

export default UserProfilePage;
