import classNames from 'classnames';
import React from 'react';
import Flex from 'ui/flex';
import styles from './index.module.scss';

interface Props {
  cover?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  watermark?: React.ReactNode;
}

export default function CardLeftImage({
  cover,
  children,
  className,
  watermark,
}: Props) {
  return (
    <Flex
      className={classNames(styles.elevation2, styles.container, className)}
    >
      <div className={styles.cover}>
        {watermark ? (
          <div className={styles.watermarkContainer}>
            <div className={styles.watermark}>{watermark}</div>
            {cover}
          </div>
        ) : (
          cover
        )}
      </div>
      <div className={styles.infoContainer}>{children}</div>
    </Flex>
  );
}
