import BaseContent from 'business/layout/baseContent';
import ConnectedPage from 'business/layout/connectedPage';
import { useAppContext } from 'business/provider';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logEvent } from 'technical/analytics';
import history from 'technical/history';
import styles from './index.module.scss';
import StepPage, { EnrolmentSteps } from './stepPage';
import Steps from './steps';

const getEnrolmentStepName = (step: EnrolmentSteps) => {
  switch (step) {
    case EnrolmentSteps.ASSOCIATION:
      return 'association';
    case EnrolmentSteps.COMPETENCES:
      return 'competences';
    case EnrolmentSteps.EXPERIENCES:
      return 'experiences';
    case EnrolmentSteps.PREFERENCES:
      return 'preferences';
    case EnrolmentSteps.PROJECT:
      return 'project';
    case EnrolmentSteps.SUMMARY:
      return 'summary';
    default:
      return 'unknown';
  }
};

export default function Enrolment() {
  const { t } = useTranslation();
  const { refreshUser } = useAppContext();
  const [currentStep, setCurrentStep] = useState(EnrolmentSteps.EXPERIENCES);
  const [isAtSummary, setIsAtSummary] = useState(false);
  const [scrollToAssociation, setScrollToAssociation] = useState(false);

  const goToStep = (step: EnrolmentSteps) => () => {
    if (step === EnrolmentSteps.ASSOCIATION) {
      setScrollToAssociation(true);
      setCurrentStep(EnrolmentSteps.PROJECT);
    } else {
      setScrollToAssociation(false);
      setCurrentStep(step);
      window.scrollTo(0, 0);
    }
    logEvent({
      category: 'enrolment',
      action: getEnrolmentStepName(step),
    });
  };

  const goNextStep = async () => {
    if (isAtSummary && currentStep !== EnrolmentSteps.SUMMARY) {
      goToStep(EnrolmentSteps.SUMMARY)();
      return;
    }

    switch (currentStep) {
      default:
      case EnrolmentSteps.EXPERIENCES:
        goToStep(EnrolmentSteps.PROJECT)();
        break;
      case EnrolmentSteps.PROJECT:
        goToStep(EnrolmentSteps.COMPETENCES)();
        break;
      case EnrolmentSteps.COMPETENCES:
        goToStep(EnrolmentSteps.PREFERENCES)();
        break;
      case EnrolmentSteps.PREFERENCES:
        setIsAtSummary(true);
        goToStep(EnrolmentSteps.SUMMARY)();
        break;
      case EnrolmentSteps.SUMMARY:
        await refreshUser();
        history.push('/');
        break;
    }
  };

  const goToPreviousStep = () => {
    switch (currentStep) {
      default:
      case EnrolmentSteps.EXPERIENCES:
        break;
      case EnrolmentSteps.PROJECT:
        goToStep(EnrolmentSteps.EXPERIENCES)();
        break;
      case EnrolmentSteps.COMPETENCES:
        goToStep(EnrolmentSteps.PROJECT)();
        break;
      case EnrolmentSteps.PREFERENCES:
        goToStep(EnrolmentSteps.COMPETENCES)();
        break;
      case EnrolmentSteps.SUMMARY:
        goToStep(EnrolmentSteps.PREFERENCES)();
    }
  };

  return (
    <ConnectedPage>
      <BaseContent
        className={styles.content}
        container="wide"
        title={
          currentStep === EnrolmentSteps.SUMMARY
            ? t('enrolment.summary.title')
            : t('enrolment.title')
        }
      >
        <Steps step={currentStep} />
        <StepPage
          step={currentStep}
          goNextStep={goNextStep}
          goToPreviousStep={goToPreviousStep}
          goToStep={{
            goToExperiences: goToStep(EnrolmentSteps.EXPERIENCES),
            goToProject: goToStep(EnrolmentSteps.PROJECT),
            goToAssociation: goToStep(EnrolmentSteps.ASSOCIATION),
            goToCompetences: goToStep(EnrolmentSteps.COMPETENCES),
            goToPreferences: goToStep(EnrolmentSteps.PREFERENCES),
          }}
          scrollToAssociation={scrollToAssociation}
        />
      </BaseContent>
    </ConnectedPage>
  );
}
