import { MissionPreview } from 'technical/graphql/fragments/mission';
import moment from 'moment';

export const getNumberOfCommonElements = (
  arrayA: (string | number)[],
  arrayB: (string | number)[],
) => arrayA.filter(elementA => arrayB.includes(elementA)).length;

export const sortMissions = (missions: MissionPreview[], tagIds: string[]) =>
  // Sort by number of common tags
  missions
    .sort((missionA, missionB) => {
      const commonTagsA = getNumberOfCommonElements(
        missionA.tags.map(tagItem => tagItem.tag.id),
        tagIds,
      );
      const commonTagsB = getNumberOfCommonElements(
        missionB.tags.map(tagItem => tagItem.tag.id),
        tagIds,
      );

      if (commonTagsA === commonTagsB) {
        return 0;
      }

      if (commonTagsA > commonTagsB) {
        return -1;
      }

      return 1;
    })
    // Sort by start date
    .sort((missionA, missionB) => {
      if (moment(missionA.startDate).isAfter(missionB.startDate)) {
        return 1;
      }

      if (moment(missionA.startDate).isBefore(missionB.startDate)) {
        return -1;
      }

      return 0;
    });
