import {
  LaptopOutlined,
  RocketOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React from 'react';
import { Association, PublicAssociation } from 'technical/graphql/fragments';
import { normalizeUrl } from 'technical/string/formatter';
import { i18n } from 'translations';
import styles from './index.module.scss';
import TextIcon from './textIcon';

const { Text } = Typography;

interface Props {
  association: Association | PublicAssociation;
}

const DisplayAssociation = ({
  association: { description, name, url, job },
}: Props) => (
  <Space direction="vertical" size="middle">
    {description && (
      <Text className="whitespaceBreak">{`“${description}“`}</Text>
    )}
    <div>
      <TextIcon
        icon={<RocketOutlined className={styles.icon} />}
        label={name}
      />
      {url && (
        <TextIcon
          icon={<LaptopOutlined className={styles.icon} />}
          label={
            <a
              className={styles.anchor}
              href={normalizeUrl(url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {normalizeUrl(url)}
            </a>
          }
        />
      )}
      <TextIcon
        icon={<ShoppingOutlined className={styles.icon} />}
        label={i18n.t('user.profile.project.role', { role: job })}
      />
    </div>
  </Space>
);

export default DisplayAssociation;
